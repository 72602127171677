<script lang="ts">
  import {config} from '$applib/configs/application';
  import {createEventDispatcher} from 'svelte';

  import {parameterizePath} from '$applib/utils/resources/urls';
  import {snakeCaseKeys} from '$applib/utils/objects';

  import {
    GridWrap,
    GridCol,
    GridColModifier,
    GridWrapModifier,
  } from '$applib/components/grid';
  import {Icon, IconId} from '$applib/components/icon';
  import {CircleButton, CircleButtonModifier} from '$applib/components/button';

  import type {Patient} from '$applib/types/resources/patients';
  import type {PatientSearchItem, PatientSelectedEventDetail} from './types';

  export let patientItem: PatientSearchItem;

  const {
    patient,
    doctor,
    block_online_appointment_booking,
    last_appointment_date,
    last_appointment_details,
  } = patientItem;
  const dispatch = createEventDispatcher();
  const {path: patientDetailPath} = config.urls.ssr.patients.detail;
  const warnings = [
    [
      block_online_appointment_booking,
      'Patient blocked from making online appointments',
    ],
  ]
    .map(([property, message]) => (property ? message : null))
    .filter(Boolean);
  const circleButtonModifiers = [
    CircleButtonModifier.CornerTopLeft,
    warnings.length > 0 ? CircleButtonModifier.ClrDanger : null,
  ].filter((x): x is CircleButtonModifier => Boolean(x));

  function getPatientUrl(patientId: Patient['id']) {
    const parameters = snakeCaseKeys({patientId});

    return parameterizePath(patientDetailPath, parameters);
  }

  function handleSelect(patient: Patient) {
    return () => {
      const detail: PatientSelectedEventDetail = {patientId: patient.id};

      dispatch('patientselected', detail);
    };
  }
</script>

<GridWrap modifiers={[GridWrapModifier.Small]}>
  <GridCol modifiers={[GridColModifier.ShrinkWrap]}>
    <CircleButton
      modifiers={circleButtonModifiers}
      on:click={handleSelect(patient)}
    >
      <Icon id={IconId.CalendarAdd} />
    </CircleButton>
  </GridCol>
  <GridCol modifiers={[GridColModifier.Auto]}>
    <div
      class="
        u-border-radius
        u-bgc--base-ltrst
        u-drop-shadow
        u-padding--smaller--block
        u-padding--small--inline
      "
    >
      <div class="u-margin--smaller--block-end">
        <a class="u-fs--h6" href={getPatientUrl(patient.id)}>
          <strong>
            {patient.firstnames}
            {patient.surname}

            ({#if doctor}{doctor.doctor_name}{:else}No doctor, yet{/if})
          </strong>
        </a>
      </div>

      <div class="u-fs--milli">
        <GridWrap modifiers={[GridWrapModifier.Small]}>
          <GridCol modifiers={[GridColModifier.Auto]}>
            <span class="u-fc--base-lt">Birth date:</span>
          </GridCol>

          <GridCol modifiers={[GridColModifier.ShrinkWrap]}>
            {patient.birthdate}
          </GridCol>
        </GridWrap>

        {#if patient.email}
          <GridWrap modifiers={[GridWrapModifier.Small]}>
            <GridCol modifiers={[GridColModifier.Auto]}>
              <span class="u-fc--base-lt">Email:</span>
            </GridCol>

            <GridCol modifiers={[GridColModifier.ShrinkWrap]}>
              {patient.email}
            </GridCol>
          </GridWrap>
        {/if}

        {#if last_appointment_date}
          <div>
            <GridWrap modifiers={[GridWrapModifier.Small]}>
              <GridCol modifiers={[GridColModifier.Auto]}>
                <span class="u-fc--base-lt">Last appointment:</span>
              </GridCol>

              <GridCol modifiers={[GridColModifier.ShrinkWrap]}>
                {last_appointment_date}
              </GridCol>
            </GridWrap>
          </div>
        {/if}

        {#if last_appointment_details}
          <div class:u-margin--small--block-end={warnings.length > 0}>
            <GridWrap modifiers={[GridWrapModifier.Small]}>
              <GridCol modifiers={[GridColModifier.Auto]}>
                <span class="u-fc--base-lt">Location:</span>
              </GridCol>

              <GridCol modifiers={[GridColModifier.ShrinkWrap]}>
                {last_appointment_details}
              </GridCol>
            </GridWrap>
          </div>
        {/if}
      </div>

      {#if warnings.length > 0}
        <GridWrap modifiers={[GridWrapModifier.Small]}>
          <GridCol modifiers={[GridColModifier.ShrinkWrap]}>
            <div class="u-fc--danger">
              <Icon class="u-display--block" id={IconId.ExclamationTriangle} />
            </div>
          </GridCol>
          <GridCol modifiers={[GridColModifier.Auto]}>
            <div class="u-fs--micro">
              {#each warnings as warning}
                <div>
                  {warning}
                </div>
              {/each}
            </div>
          </GridCol>
        </GridWrap>
      {/if}
    </div>
  </GridCol>
</GridWrap>
