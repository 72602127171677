<script lang="ts">
  import {getModifierClassName} from '$applib/utils/components';
  import type {CircleButtonModifier} from './enums';

  export let modifiers: CircleButtonModifier[] = [];
  export let baseClassName = 'btn-circle';
  export let disabled = false;
  export let href: string | undefined = undefined;
  let klass = '';
  export {klass as class};

  $: modifiersClassName = getModifierClassName(baseClassName, modifiers);
  $: className = [baseClassName, klass, modifiersClassName]
    .filter(Boolean)
    .join(' ');
  $: props = {...$$restProps, class: className};
</script>

{#if !href}
  {#if disabled}
    <button {disabled} {...props}>
      <i class="btn-circle__inner">
        <slot />
      </i>
    </button>
  {:else}
    <button {...props} on:click>
      <i class="btn-circle__inner">
        <slot />
      </i>
    </button>
  {/if}
{:else}
  <a {href} {...props} on:click>
    <i class="btn-circle__inner">
      <slot />
    </i>
  </a>
{/if}
