import * as Sentry from "@sentry/browser";
import { browserTracingIntegration, replayIntegration } from "@sentry/browser";

import { config } from "$applib/configs/env";

const { sentry } = config;

if (sentry.dsn.length > 0) {
	Sentry.init({
		dsn: sentry.dsn,
		environment: sentry.environment,

		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,

		integrations: [browserTracingIntegration(), replayIntegration()],
		tracesSampleRate: 1.0,
	});
}
