import { config } from "$applib/configs/application";
import { CustomDomEvent } from "$applib/enums/events";
import { buildHeaders, getCsrfTokenHeader } from "$applib/utils/headers";

import type { AppointmentTimeSlot } from "$applib/types/resources/appointments";

import {
	getTimeSlotId,
	renderTimeSlot,
} from "../shared/utils/appointment-grid";

const RESERVE_APPOINTMENT_CLASS = "js-appointment-grid-reserve-appointment";

const { urls } = config;
const { path: reservedAppointmentsEndpoint } =
	urls.api.appointments.reservedNew;

function createReservedAppointment(buttonEl: HTMLButtonElement) {
	const data = buttonEl.dataset;

	fetch(reservedAppointmentsEndpoint, {
		method: "POST",
		headers: buildHeaders({
			...getCsrfTokenHeader(),
			"Content-Type": "application/json",
			Accept: "application/json",
		}),
		body: JSON.stringify({
			date: data.date,
			end_time: data.endTime,
			staff_resource_id: data.staffResourceId,
			start_time: data.startTime,
		}),
	})
		.then((response) => {
			if (response.ok) {
				return response.json();
			} else {
				// TODO: LB - properly handle this error
				throw response;
			}
		})
		.then((response) => {
			const timeSlot: AppointmentTimeSlot = response;
			const timeSlotId = getTimeSlotId(timeSlot);
			const timeSlotEl = document.getElementById(timeSlotId);

			if (timeSlotEl) {
				timeSlotEl.outerHTML = renderTimeSlot(timeSlot);

				const event = new CustomEvent(
					CustomDomEvent.AppointmentGridAppointmentCreated,
				);
				document.dispatchEvent(event);
			}
		})
		.catch((response) => {
			console.error(response);
		});
}

function initCreateReservedAppointments() {
	document.body.addEventListener("click", (event: MouseEvent) => {
		const target = event.target as HTMLElement;
		const isElement = target.classList.contains(RESERVE_APPOINTMENT_CLASS);
		const parentEl = target.closest(`.${RESERVE_APPOINTMENT_CLASS}`);
		const buttonEl = isElement ? target : parentEl;

		if (buttonEl) {
			createReservedAppointment(buttonEl as HTMLButtonElement);
		}
	});
}

export { initCreateReservedAppointments };
