const getDomSelectors = ({
	lineItemsFormsetPrefix,
	formPrefix,
}: { lineItemsFormsetPrefix: string; formPrefix: string }) => {
	return {
		dateCardinalPeriodInput: `select[name=${formPrefix}-date_cardinal_period]`,
		dateCardinalValueInput: `input[name=${formPrefix}-date_cardinal_value]`,
		dateMinDurationInput: `input[name=${formPrefix}-ref_date_offset_value]`,
		dateMinDurationPeriodInput: `select[name=${formPrefix}-ref_date_offset_period]`,
		dateOrdinalPeriodInput: `select[name=${formPrefix}-date_ordinal_period]`,
		dateOrdinalValueMonthlyInput: `select[name=${formPrefix}-date_ordinal_value_monthly]`,
		dateOrdinalValueWeeklyInput: `select[name=${formPrefix}-date_ordinal_value_weekly]`,
		dateTypeInput: `select[name=${formPrefix}-date_type]`,
		deleteButton: "[data-invoice-set-delete-toggler]",
		deleteInput: `input[name=${formPrefix}-DELETE]`,
		dueDatesOutput: "[data-invoice-set-due-dates]",
		refDateOutput: "[data-invoice-set-reference-date]",
		lineItemsAdder: "[data-invoice-generator-line-items-adder]",
		numInvoicesInput: `input[name=${formPrefix}-num_invoices]`,
		orderInput: `input[name=${formPrefix}-ORDER]`,
		totalLineItemsInput: `input[name=${lineItemsFormsetPrefix}-TOTAL_FORMS]`,
		totalOutput: "[data-invoice-set-total]",
	};
};

function createDomSelector(element: HTMLElement) {
	const xs = getDomSelectors({
		formPrefix: element.dataset.formPrefix || "",
		lineItemsFormsetPrefix: element.dataset.lineItemsFormsetPrefix || "",
	});

	return {
		getDateCardinalPeriodInput: () =>
			element.querySelector(xs.dateCardinalPeriodInput) as HTMLInputElement,
		getDateCardinalValueInput: () =>
			element.querySelector(xs.dateCardinalValueInput) as HTMLInputElement,
		getDateMinDurationInput: () =>
			element.querySelector(xs.dateMinDurationInput) as HTMLInputElement,
		getDateMinDurationPeriodInput: () =>
			element.querySelector(xs.dateMinDurationPeriodInput) as HTMLInputElement,
		getDateOrdinalPeriodInput: () =>
			element.querySelector(xs.dateOrdinalPeriodInput) as HTMLInputElement,
		getDateOrdinalValueMonthlyInput: () =>
			element.querySelector(
				xs.dateOrdinalValueMonthlyInput,
			) as HTMLInputElement,
		getDateOrdinalValueWeeklyInput: () =>
			element.querySelector(xs.dateOrdinalValueWeeklyInput) as HTMLInputElement,
		getDateTypeInputs: () =>
			element.querySelectorAll<HTMLInputElement>(xs.dateTypeInput),

		getDeleteButton: () =>
			element.querySelector(xs.deleteButton) as HTMLButtonElement,
		getDeleteInput: () =>
			element.querySelector(xs.deleteInput) as HTMLInputElement,
		getDueDatesOutput: () =>
			element.querySelector(xs.dueDatesOutput) as HTMLInputElement,
		getLineItemsAdder: () =>
			element.querySelector(xs.lineItemsAdder) as HTMLInputElement,
		getNumInvoicesInput: () =>
			element.querySelector(xs.numInvoicesInput) as HTMLInputElement,
		getOrderInput: () =>
			element.querySelector(xs.orderInput) as HTMLInputElement,
		getRefDateOutput: () =>
			element.querySelector(xs.refDateOutput) as HTMLInputElement,
		getTotalLineItemsInput: () =>
			element.querySelector(xs.totalLineItemsInput) as HTMLInputElement,
		getTotalOutputEl: () =>
			element.querySelector<HTMLElement>(xs.totalOutput) as HTMLElement,
	};
}

export { createDomSelector };
