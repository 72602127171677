import { fromCallback } from "xstate";

import type { EventFrom } from "xstate";

import { LineItemEventName, SubmissionState } from "../../shared/enums";

import { createDomSelector } from "./utils";

import type {
	LineItemCallbackLogic,
	LineItemMachine,
} from "../../shared/machines/line-items";

function domUpdateFactory(lineItemEl: HTMLElement) {
	const selector = createDomSelector(lineItemEl);

	return {
		updateDeleteButtonAction(state: "delete" | "restore") {
			const { getDeleteButton } = selector;

			getDeleteButton().dataset.state = state;
		},

		updatePriceInteraction(state: "readonly" | "writable") {
			const { getPriceInput } = selector;
			const priceInput = getPriceInput();

			if (state === "readonly") {
				priceInput.setAttribute("readonly", "");
			} else {
				priceInput.removeAttribute("readonly");
			}
		},

		updateDeleteButtonInteraction(state: "enabled" | "disabled") {
			const { getDeleteButton } = selector;
			const deleteButton = getDeleteButton();

			if (state === "enabled") {
				deleteButton.removeAttribute("disabled");
			} else {
				deleteButton.setAttribute("disabled", "");
			}
		},

		updateDeleteInputValue(state: "checked" | "unchecked") {
			const { getDeleteInput } = selector;

			getDeleteInput().checked = state === "checked";
		},

		updateFormEditability(state: "enabled" | "disabled") {
			const xs = selector;
			const inputs = [
				xs.getQuantityInput(),
				...xs.getPriceTypeInputs(),
				xs.getPriceInput(),
			];
			const className = "u-fc--base-ltr";
			const classListFn = state === "enabled" ? "remove" : "add";

			for (const input of inputs) {
				if (state === "enabled") {
					input.removeAttribute("readonly");
				} else {
					input.setAttribute("readonly", "");
				}
			}

			lineItemEl.classList[classListFn](className);
		},

		renderForm: ({
			quantity,
			price,
			total,
		}: { quantity: number; price: number; total: number }) => {
			const { getQuantityInput, getPriceInput, getTotalOutputEl } = selector;
			const priceInput = getPriceInput();
			const quantityInput = getQuantityInput();

			getTotalOutputEl().innerText = total.toFixed(2);

			if (Number(priceInput.value) !== price) {
				priceInput.value = `${price}`;
			}

			if (Number(quantityInput.value) !== quantity) {
				quantityInput.value = `${quantity}`;
			}
		},
	};
}

function ioDispatchCreator(element: HTMLElement) {
	return fromCallback<EventFrom<LineItemMachine>>(({ sendBack, receive }) => {
		const handlersByEvent = {
			"invoicegen:lineitem:change:price": function handleChangePrice(
				event: Event,
			) {
				const { detail } = event as CustomEvent;

				sendBack({
					type: LineItemEventName.IoRxChangePrice,
					params: { price: Number.parseFloat(detail.price) || 0 },
				});
			},
			"invoicegen:lineitem:change:quantity": function handleChangeQuantity(
				event: Event,
			) {
				const { detail } = event as CustomEvent;

				sendBack({
					type: LineItemEventName.IoRxChangeQuantity,
					params: { quantity: Number.parseFloat(detail.quantity) || 0 },
				});
			},
			"invoicegen:lineitem:change:pricetype": function handleChangePriceType(
				event: Event,
			) {
				const { detail } = event as CustomEvent;

				sendBack({
					type: LineItemEventName.IoRxChangePriceType,
					params: { priceType: detail.priceType },
				});
			},
			"invoicegen:lineitem:deletetoggle": function handleDeleteToggle() {
				sendBack({ type: LineItemEventName.IoRxToggleDelete });
			},
		};

		receive((event) => {
			const domUpdater = domUpdateFactory(element);

			switch (event.type) {
				case LineItemEventName.IoTxDeletableStateChange: {
					const { state } = event.params;

					domUpdater.updateDeleteButtonInteraction(state);
					break;
				}
				case LineItemEventName.IoTxSubmissionStateChange: {
					const { state } = event.params;
					const inputState =
						state === SubmissionState.MarkedForDeletionBySelf
							? "checked"
							: "unchecked";
					const formState = [
						SubmissionState.MarkedForDeletionBySelf,
						SubmissionState.MarkedForDeletionByParent,
					].includes(state)
						? "disabled"
						: "enabled";
					const buttonState = [
						SubmissionState.MarkedForDeletionBySelf,
						SubmissionState.MarkedForDeletionByParent,
					].includes(state)
						? "restore"
						: "delete";

					domUpdater.updateDeleteInputValue(inputState);
					domUpdater.updateFormEditability(formState);
					domUpdater.updateDeleteButtonAction(buttonState);
					break;
				}
				case LineItemEventName.IoTxPriceStateChange: {
					const { state } = event.params;

					domUpdater.updatePriceInteraction(state);
					break;
				}
				case LineItemEventName.IoTxValues: {
					const { total, quantity, price } = event.params;

					domUpdater.renderForm({ total, quantity, price });
					break;
				}
			}
		});

		for (const [event, handler] of Object.entries(handlersByEvent)) {
			element.addEventListener(event, handler);
		}

		return function destroy() {
			for (const [event, handler] of Object.entries(handlersByEvent)) {
				element.removeEventListener(event, handler);
			}
		};
		// TODO: LB - determine why casting this is required to fix TS
		// errors in ../index.ts
	}) as LineItemCallbackLogic;
}

export { ioDispatchCreator };
