import type { AnyActorRef } from "xstate";

interface RefItem {
	ref: AnyActorRef;
}

function calculatorFactory<T extends AnyActorRef>(actor: T) {
	function getLineItemTotals(
		lineItems: Array<RefItem>,
		predicate: (lineItem: RefItem) => boolean = Boolean,
	) {
		return lineItems
			.filter(predicate)
			.map(({ ref }) => ref.getSnapshot().context.total)
			.reduce((acc, value) => acc + value, 0);
	}

	function getTotal(lineItemFilter: (lineItem: RefItem) => boolean = Boolean) {
		const { context } = actor.getSnapshot();
		const lineItemsTotal = getLineItemTotals(context.lineItems, lineItemFilter);
		const total = lineItemsTotal * context.numInvoices;

		return total;
	}

	return { getTotal };
}

export { calculatorFactory };
