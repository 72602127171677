const getDomSelectors = (invoiceSetFormsetPrefix: string) => {
	return {
		invoiceSetEl: "[data-invoice-generator-invoice-set]",
		refDateInput: "input[name=reference_date]",
		totalFormsInput: `input[name=${invoiceSetFormsetPrefix}-TOTAL_FORMS]`,
		totalInput: "input[name=total]",
		totalOutputEl: "[data-invoice-generator-form-total-output]",
	};
};

function createDomSelector(formEl: HTMLFormElement) {
	const selectors = getDomSelectors(
		formEl.dataset.invoiceSetFormsetPrefix || "",
	);

	return {
		getInvoiceSetEls: () =>
			formEl.querySelectorAll<HTMLElement>(selectors.invoiceSetEl),
		getRefDateInput: () =>
			formEl.querySelector(selectors.refDateInput) as HTMLInputElement,
		getNumFormsInput: () =>
			formEl.querySelector(selectors.totalFormsInput) as HTMLInputElement,
		getTotalInput: () =>
			formEl.querySelector(selectors.totalInput) as HTMLInputElement,
		getTotalOutputEl: () =>
			formEl.querySelector(selectors.totalOutputEl) as HTMLOutputElement,
	};
}

export { createDomSelector };
