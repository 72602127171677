<script lang="ts">
  import {fly} from 'svelte/transition';
  import {useMachine} from '@xstate/svelte';

  import {EventType} from '$applib/utils/resources/create-resource-machine';

  import {CustomCheckRadio} from '$applib/components/forms/fields';
  import {Loader} from '$applib/components/loader';

  import {emailSubscriptionsMachine} from './machines';

  export let checked = false;
  export let groupId: number | string;

  const {snapshot: state, actorRef: interpreter} = useMachine(
    emailSubscriptionsMachine,
  );

  $: if ($state.matches({delete: 'success'})) {
    checked = false;
  }
  $: if ($state.matches({patch: 'success'})) {
    checked = true;
  }

  function handleInput(event: Event) {
    const target = event.target as HTMLInputElement;
    const shouldSubscribe = target.checked;
    const type = shouldSubscribe ? EventType.Patch : EventType.Delete;

    interpreter.send({type, params: {groupId}});
  }
</script>

<div class="u-text--end">
  <span class="u-fc--primary">
    {#if $state.hasTag('requesting')}
      <Loader />
    {:else}
      <CustomCheckRadio
        let:inputClass
        let:inputAttributes
        id={`unsubscribe-group-${groupId}`}
      >
        <input
          class={inputClass}
          on:input={handleInput}
          {...inputAttributes}
          type="checkbox"
          {checked}
        />
      </CustomCheckRadio>
    {/if}
  </span>

  {#if $state.hasTag('error')}
    <div class="u-fs--milli u-fc--danger" transition:fly>
      There was a problem updating your subscription. Please try again, or
      contact support if the problem persists
    </div>
  {/if}
</div>
