(async () => {
	const _hyperscript = await import("hyperscript.org");
	globalThis._hyperscript = _hyperscript;

	_hyperscript.browserInit();
	_hyperscript.processNode(document.body);

	if (import.meta.env.DEV) {
		await import("hyperscript.org/dist/hdb");
	}
})();
