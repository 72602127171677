import { fromPromise } from "xstate";

import { config } from "$applib/configs/application";
import { createResource } from "$applib/utils/resources/create-resource";
import { resourceMachineFactory } from "$applib/utils/resources/create-resource-machine";

import type { ContextFrom, MachineImplementationsFrom } from "xstate";

import type { Metadata } from "$applib/types/resources/metadata";

const { path: metadataEndpoint } = config.urls.api.metadata.detail;
const { get: getMetadata } = createResource({ endpoint: metadataEndpoint });

type Implementations = MachineImplementationsFrom<MetadataStateMachine>;

const metadataMachine = resourceMachineFactory<Metadata>("metadata").provide({
	actors: {
		get: fromPromise(() => getMetadata()),
	} as Implementations["actors"],
});

export type MetadataStateMachine = typeof metadataMachine;
export type MetadataMachineContext = ContextFrom<MetadataStateMachine>;

export { metadataMachine };
