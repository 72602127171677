import type { AlertType } from "$applib/components/alert";
import type { PatientPhotos } from "$applib/types/resources/patient-photos";

export interface NotifyEvent extends CustomEvent {
	detail: {
		alertType: AlertType;
		errors: string[];
		message: string;
		patientPhotosId: PatientPhotos["id"];
		timeout?: number;
	};
}

const NOTIFY_EVENT_NAME = "notify";

export { NOTIFY_EVENT_NAME };
