import { assign, setup } from "xstate";

import type { useMachine } from "@xstate/svelte";

import { EventName } from "./enums";

const tabsMachine = setup({
	types: {} as {
		context: {
			activeTabId?: string;
			ids: string[];
		};
		events:
			| { type: EventName.ActivateTab; params: { id: string } }
			| { type: EventName.AddTab; params: { id: string } }
			| { type: EventName.RemoveTab; params: { id: string } };
	},
	actions: {
		addTab: assign(({ context: { activeTabId, ids }, event }) => {
			const { id } = event.params;
			const idExists = ids.includes(id);
			const newActiveTabId = !idExists && !activeTabId ? id : activeTabId;

			return {
				activeTabId: newActiveTabId,
				ids: !idExists ? ids.concat(id) : ids,
			};
		}),

		removeTab: assign(({ context: { ids, activeTabId }, event }) => {
			const { id } = event.params;
			const idIndex = ids.indexOf(id);
			const activeTabIdIndex = activeTabId ? ids.indexOf(activeTabId) : -1;
			const newActiveTabIdIndex =
				idIndex > -1 && idIndex === activeTabIdIndex
					? (activeTabIdIndex - 1) % (ids.length - 1)
					: activeTabIdIndex;
			const newIds = ids.filter((id) => id !== event.params.id);

			return {
				activeTabId: newIds[newActiveTabIdIndex],
				ids: newIds,
			};
		}),

		activateTab: assign({
			activeTabId: ({ context: { activeTabId, ids }, event }) => {
				const { id } = event.params;

				return ids.includes(id) ? id : activeTabId;
			},
		}),
	},
}).createMachine({
	id: "tabs",
	initial: "active",
	context: { ids: [] },

	on: {
		[EventName.ActivateTab]: { actions: "activateTab" },
		[EventName.AddTab]: { actions: "addTab" },
		[EventName.RemoveTab]: { actions: "removeTab" },
	},

	states: {
		active: {},
	},
});

export type TabsStore = ReturnType<typeof useMachine<typeof tabsMachine>>;

export { tabsMachine };
