// TODO: LB - use correct import syntax without disabling linter
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
type Html2Canvas = typeof import("html2canvas");

import { drawingCanvasFactory } from "$applib/components/legacy-drawing-canvas";

const signatureCanvasFormSubmitterEl = document.querySelector<HTMLElement>(
	".js-signature-canvas-form-submitter",
);

if (signatureCanvasFormSubmitterEl) {
	const signatureCanvas = drawingCanvasFactory();
	signatureCanvas.init();
}

// TODO: LB - refactor this function so that we have a consistent and reliable
// way of querying dom elements
function initializeSubmitter(
	containerEl: HTMLElement,
	html2canvas: Html2Canvas["default"],
) {
	const imageContextContainerEl = containerEl.querySelector<HTMLDivElement>(
		".js-signature-form-image-context",
	) as HTMLDivElement;
	const canvasSelector = imageContextContainerEl.dataset.target || "";
	const imageContextEl = imageContextContainerEl.querySelector(
		canvasSelector,
	) as HTMLCanvasElement;
	const formEl = containerEl.querySelector<HTMLFormElement>(
		"form",
	) as HTMLFormElement;
	const submitButtonEl = containerEl.querySelector<HTMLButtonElement>(
		".js-signature-form-submit",
	) as HTMLButtonElement;
	const inputEl = formEl.querySelector<HTMLInputElement>(
		"#id_image_data",
	) as HTMLInputElement;

	function handleSubmit() {
		html2canvas(imageContextEl)
			.then((canvas: HTMLCanvasElement) => {
				const imageData = canvas.toDataURL("image/png");

				inputEl.value = imageData;

				formEl.submit();
			})
			.catch((error) => {
				console.error(error);
			});
	}

	submitButtonEl.addEventListener("click", handleSubmit);
}

if (signatureCanvasFormSubmitterEl) {
	(async () => {
		/**
		 * TODO: LB - refactor this function so that we don't need html2canvas.
		 * html2canvas is useful for creating images from arbitrary DOM nodes,
		 * but if all the image information is contained inside HTMLCanvasElement,
		 * then there's no need to use html2canvas
		 */
		const html2canvas = await import("html2canvas");

		initializeSubmitter(signatureCanvasFormSubmitterEl, html2canvas.default);
	})();
}
