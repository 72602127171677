import { fromCallback, setup } from "xstate";
import { assign, log, sendParent, sendTo } from "xstate/actions";

import type { MachineContext } from "xstate";

import {
	LineItemEventName as EventName,
	InvoiceSetEventName,
	PriceType,
	SubmissionState,
} from "../enums";

interface LineItemMachineContext extends MachineContext {
	order: number;
	price: number;
	priceType: PriceType;
	quantity: number;
	total: number;
}

export type LineItemMachine = typeof lineItemMachine;
export type LineItemCallbackLogic = ReturnType<typeof fromCallback>;

const lineItemMachine = setup({
	types: {} as {
		context: LineItemMachineContext;
		events:
			| { type: EventName.IoRxChangePrice; params: { price: number } }
			| {
					type: EventName.IoRxChangePriceType;
					params: { priceType: PriceType };
			  }
			| { type: EventName.IoRxChangeQuantity; params: { quantity: number } }
			| { type: EventName.IoRxToggleDelete }
			| {
					type: EventName.IoTxDeletableStateChange;
					params: { state: "disabled" | "enabled" };
			  }
			| {
					type: EventName.IoTxSubmissionStateChange;
					params: { state: SubmissionState };
			  }
			| {
					type: EventName.IoTxPriceStateChange;
					params: { state: "writable" | "readonly" };
			  }
			| {
					type: EventName.IoTxValues;
					params: { quantity: number; price: number; total: number };
			  }
			| { type: EventName.RxParentDelete }
			| { type: EventName.RxParentRestore }
			| { type: EventName.RxSetTotal; params: { total: number } };
		input: Partial<LineItemMachineContext>;
	},
	actions: {
		log: log((x) => x.self, "line-item"),

		setValuesFromTotal: assign(
			({ context: { quantity } }, params: { total: number }) => {
				const { total } = params;
				const newQuantity = Math.max(quantity, 1);

				return { price: total / newQuantity, quantity: newQuantity, total };
			},
		),

		setPriceFromQuantity: assign(
			({ context: { total } }, { quantity }: { quantity: number }) => {
				const newQuantity = Math.max(quantity, 1);
				const price = total / newQuantity;

				return { price, quantity: newQuantity };
			},
		),

		setTotalFromQuantity: assign(
			({ context: { price } }, { quantity }: { quantity: number }) => {
				return { total: quantity * price, quantity };
			},
		),

		setTotalFromPrice: assign(
			({ context: { quantity } }, { price }: { price: number }) => {
				return { total: quantity * price, price };
			},
		),

		notifyParent: sendParent({ type: InvoiceSetEventName.RxLineItemUpdate }),

		setValue: assign(
			(
				{ context },
				params: {
					price?: number;
					priceType?: PriceType;
					quantity?: number;
				},
			) => {
				const { price, priceType, quantity } = params;

				return {
					price: price || context.price,
					priceType: priceType || context.priceType,
					quantity: quantity || context.quantity,
				};
			},
		),

		emitSubmissionState: sendTo(
			"ioEventDispatcher",
			(_, params: { state: SubmissionState }) => ({
				type: EventName.IoTxSubmissionStateChange,
				params,
			}),
		),

		emitPriceState: sendTo(
			"ioEventDispatcher",
			(_, params: { state: "writable" | "readonly" }) => ({
				type: EventName.IoTxPriceStateChange,
				params,
			}),
		),

		emitDeletability: sendTo(
			"ioEventDispatcher",
			(_, params: { state: "enabled" | "disabled" }) => ({
				type: EventName.IoTxDeletableStateChange,
				params,
			}),
		),

		emitValues: sendTo(
			"ioEventDispatcher",
			({
				context: { quantity, total, price },
			}: { context: LineItemMachineContext }) => ({
				type: EventName.IoTxValues,
				params: { quantity, total, price },
			}),
		),
	},

	guards: {
		contextValueIs: (
			{ context },
			params: {
				propName: keyof LineItemMachineContext;
				value: LineItemMachineContext[keyof LineItemMachineContext];
			},
		) => {
			const field = context[params.propName];

			return field === params.value;
		},

		totalHasChanged: ({ context }, params: { total: number }) => {
			const { total: currentTotal } = context;
			const { total: newTotal } = params;

			return currentTotal !== newTotal;
		},

		isMarkedForDeletion: () => false,
	},

	actors: {
		ioEventDispatcher: fromCallback(() => {
			throw new Error("Not implemented");
		}),
	},
}).createMachine({
	id: "invoice-generator-invoice-set-line-item",
	initial: "init",
	context: ({ input }) => ({
		order: 0,
		price: 0,
		priceType: PriceType.Fixed,
		quantity: 1,
		total: 0,
		...input,
	}),
	invoke: {
		src: "ioEventDispatcher",
		id: "ioEventDispatcher",
	},

	states: {
		init: {
			always: [
				{ target: "markedForDeletion", guard: "isMarkedForDeletion" },
				{ target: "active" },
			],
		},

		active: {
			id: "active",
			initial: "evaluating",
			entry: [
				{
					type: "emitSubmissionState",
					params: { state: SubmissionState.Default },
				},
			],

			on: {
				[EventName.IoRxToggleDelete]: { target: "markedForDeletion.bySelf" },
				[EventName.RxParentDelete]: { target: "markedForDeletion.byParent" },
				[EventName.IoRxChangePriceType]: {
					target: ".evaluating",
					actions: [
						{
							type: "setValue",
							params: ({ event }) => ({ priceType: event.params.priceType }),
						},
					],
				},
			},

			states: {
				evaluating: {
					exit: [{ type: "emitValues" }],
					always: [
						{
							target: "fixed",
							guard: {
								type: "contextValueIs",
								params: { propName: "priceType", value: "fixed" },
							},
						},
						{ target: "dynamic" },
					],
				},
				dynamic: {
					entry: [
						//"setPriceAsReadonly", // emitPriceState
						{ type: "emitPriceState", params: { state: "readonly" } },
						{ type: "notifyParent" },
					],
					on: {
						[EventName.RxSetTotal]: {
							actions: [
								{
									type: "setValuesFromTotal",
									params: ({ event }) => ({ total: event.params.total }),
								},
							],
							guard: {
								type: "totalHasChanged",
								params: ({ event }) => ({ total: event.params.total }),
							},
							target: "evaluating",
						},
						[EventName.IoRxChangePrice]: [
							{
								actions: [
									// NOTE: prevents user from changing the price of the field
									// by re-rendering with the value from context
									{ type: "emitValues" },
								],
							},
						],
						[EventName.IoRxChangeQuantity]: [
							{
								target: "evaluating",
								actions: [
									{
										type: "setPriceFromQuantity",
										params: ({ event }) => ({
											quantity: event.params.quantity,
										}),
									},
								],
							},
						],
					},
				},
				fixed: {
					entry: [
						{ type: "emitPriceState", params: { state: "writable" } },
						{ type: "notifyParent" },
					],
					on: {
						[EventName.IoRxChangePrice]: [
							{
								actions: [
									{
										type: "setTotalFromPrice",
										params: ({ event }) => ({ price: event.params.price }),
									},
								],
								target: "evaluating",
							},
						],
						[EventName.IoRxChangeQuantity]: [
							{
								actions: [
									{
										type: "setTotalFromQuantity",
										params: ({ event }) => ({
											quantity: event.params.quantity,
										}),
									},
								],
								target: "evaluating",
							},
						],
					},
				},
			},
		},

		markedForDeletion: {
			entry: [{ type: "notifyParent" }],
			initial: "bySelf",
			states: {
				bySelf: {
					initial: "enabled",
					entry: [
						{
							type: "emitSubmissionState",
							params: { state: SubmissionState.MarkedForDeletionBySelf },
						},
					],

					states: {
						enabled: {
							on: {
								[EventName.RxParentDelete]: { target: "disabled" },
								[EventName.IoRxToggleDelete]: { target: "#active" },
							},
						},
						disabled: {
							entry: [
								{ type: "emitDeletability", params: { state: "disabled" } },
							],
							exit: [
								{ type: "emitDeletability", params: { state: "enabled" } },
							],
							on: {
								[EventName.RxParentRestore]: { target: "enabled" },
							},
						},
					},
				},
				byParent: {
					entry: [
						{ type: "emitDeletability", params: { state: "disabled" } },
						{
							type: "emitSubmissionState",
							params: { state: SubmissionState.MarkedForDeletionByParent },
						},
					],
					exit: [{ type: "emitDeletability", params: { state: "enabled" } }],
					on: { [EventName.RxParentRestore]: { target: "#active" } },
				},
			},
		},
	},
});

export { lineItemMachine };
