<script lang="ts">
  import {getContext, onDestroy, onMount} from 'svelte';

  import {key} from './context';
  import {TabState, EventName} from './enums';

  import type {TabsStore} from './machine';

  export let id: string;
  const tabsStore = getContext<TabsStore>(key);
  const {send, snapshot: state} = tabsStore;

  $: tabState =
    id === $state.context.activeTabId ? TabState.Active : TabState.Inactive;

  function handleActivate() {
    send({type: EventName.ActivateTab, params: {id}});
  }

  onMount(() => {
    send({type: EventName.AddTab, params: {id}});
  });

  onDestroy(() => {
    send({type: EventName.RemoveTab, params: {id}});
  });
</script>

<slot activateTab={handleActivate} state={tabState} />
