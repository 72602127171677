import { CustomDomEvent } from "$applib/enums/events";
import {
	grabScroll,
	ScrollDirection as GrabScrollDirection,
} from "$applib/actions/grab-scroll";

import { renderAppointmentGrid } from "./render";
import { resetGetAppointmentDataHandlers } from "./get-appointment-data";
import { resetCreateAppointmentHandlers } from "./create-appointment";
import { appointmentFormFactory } from "./appointment-form";
import {
	initCreateReservedAppointments,
	initDeleteReservedAppointments,
	initRenameReservedAppointments,
} from "./reserved-appointments";

const renderSelector = ".js-appointment-grid-render";
const renderEl = document.querySelector<HTMLElement>(renderSelector);

if (renderEl) {
	const appointmentForm = appointmentFormFactory();
	const bindCreateAppointmentHandlers =
		resetCreateAppointmentHandlers(appointmentForm);
	const bindGetAppointmentHandlers =
		resetGetAppointmentDataHandlers(appointmentForm);

	renderAppointmentGrid(renderEl);
	initCreateReservedAppointments();
	initDeleteReservedAppointments();
	initRenameReservedAppointments();

	// TODO: LB - remove this once CREATE_APPOINTMENT_CLASS is
	// rendered via Svelte
	bindCreateAppointmentHandlers();

	const appointmentGridEvents = [
		CustomDomEvent.AppointmentGridRendered,
		CustomDomEvent.AppointmentGridAppointmentCreated,
		CustomDomEvent.AppointmentGridAppointmentUpdated,
	];

	appointmentGridEvents.map((event) => {
		// TODO: LB - remove this once GET_APPOINTMENT_DATA_CLASS is
		// rendered via Svelte
		document.addEventListener(event, bindGetAppointmentHandlers);
	});
}

const grabScrollSelector = ".js-appointment-grid-grab-scroll";
const grabScrollEl = document.querySelector<HTMLElement>(grabScrollSelector);

if (grabScrollEl) {
	// TODO: LB - use a Svelte component to wrap the grid once the grid is purely
	// rendered using Svelte
	grabScroll(grabScrollEl, { scrollDirection: GrabScrollDirection.XOnly });
}
