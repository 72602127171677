import type { Organisation } from "./organizations";
import type { PatientPhotos } from "./patient-photos";
import type { Patient } from "./patients";
import type { StaffMember } from "./staff-members";
import type { Model } from "./shared/model";

// TODO: LB - replace this enum in the db with a boolean field
export enum ReportNeeded {
	Yes = "yes",
	No = "no",
}

export interface PatientDiscussion extends Model {
	discussion_points: [number];
	newpatientphotos: PatientPhotos["id"];
	notes: string;
	organisation: Organisation["id"];
	patient: Patient["id"];
	report_completed_by: StaffMember["id"];
	report_needed: ReportNeeded;
	timestamp: string; // datetime
	treatment_type: [number];
}
