export enum TabState {
	Active = "active",
	Inactive = "inactive",
}

export enum EventName {
	RemoveTab = "remove-tab",
	AddTab = "add-tab",
	ActivateTab = "activate-tab",
}
