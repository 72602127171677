import type { Action } from "svelte/action";

type _MutationObserverAction = Action<Element, MutationObserverInit>;

const MUTATE_EVENT_NAME = "_mutate";

/**
 * mutationObserver
 *
 * A Svelte action which makes a component observable for mutations
 *
 * Accepts an HTML element and options for listening to DOM mutations, and emits
 * 'mutate' events from the element when mutations are observed
 *
 * Provides a noop fallback for browsers that don't support MutationObserver
 */
const mutationObserver: _MutationObserverAction = function mutationObserver(
	node,
	options?: MutationObserverInit,
) {
	let observer: MutationObserver = {
		observe: () => null,
		disconnect: () => null,
		takeRecords: () => [new MutationRecord()],
	};

	function dispatchMutation(mutations: MutationRecord[], x: MutationObserver) {
		const event = new CustomEvent(MUTATE_EVENT_NAME, {
			detail: { mutations, observer: x },
		});

		node.dispatchEvent(event);
	}

	if (typeof MutationObserver !== "undefined") {
		observer = new MutationObserver(dispatchMutation);

		observer.observe(node, options);
	} else {
		console.warn("browser does not support MutationObserver");
	}

	return {
		destroy() {
			observer.disconnect();
		},
	};
};

export { mutationObserver, MUTATE_EVENT_NAME };
