export enum AlertModifier {
	Small = "small",
}

export enum AlertType {
	Info = "info",
	Danger = "danger",
	Error = "error",
	Success = "success",
}
