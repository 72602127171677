import { drawingCanvasFactory } from "$applib/components/legacy-drawing-canvas";

const signatureFormEl = document.querySelector<HTMLElement>(
	".js-signed-quote-signature-form",
);

if (signatureFormEl) {
	const drawingCanvas = drawingCanvasFactory();
	drawingCanvas.init();
}

function initializeSignatureForm(formEl: HTMLElement) {
	const canvasEl = formEl.querySelector<HTMLCanvasElement>(
		".js-signed-quote-signature-image-context canvas",
	) as HTMLCanvasElement;
	const inputEl = formEl.querySelector<HTMLInputElement>(
		".js-signed-quote-signature-image-data",
	) as HTMLInputElement;

	function writeSignatureData() {
		const imageData = canvasEl.toDataURL("image/webp");

		inputEl.value = imageData;
	}

	function disableSubmit(event: Event) {
		const form = event.target as HTMLFormElement;

		[form.querySelector<HTMLButtonElement>("button[type=submit]")].map(
			(element) => {
				if (element) {
					element.setAttribute("disabled", "");
					element.innerText = "Signing...";
				}
			},
		);
	}

	canvasEl.addEventListener("mouseup", writeSignatureData);
	formEl.addEventListener("submit", disableSubmit);
}

if (signatureFormEl) {
	initializeSignatureForm(signatureFormEl);
}
