import { assertEvent, fromPromise } from "xstate";

import type { ActionArgs, ContextFrom, EventFrom } from "xstate";

import { config } from "$applib/configs/application";
import { snakeCaseKeys } from "$applib/utils/objects";
import { createResource } from "$applib/utils/resources/create-resource";
import {
	EventType,
	resourceMachineFactory,
} from "$applib/utils/resources/create-resource-machine";

export type EmailSubscriptionsStateMachine = typeof emailSubscriptionsMachine;
export type EmailSubscriptionsMachineMachineContext =
	ContextFrom<EmailSubscriptionsStateMachine>;
type LocalContext = ContextFrom<EmailSubscriptionsStateMachine>;
type LocalEvent = EventFrom<EmailSubscriptionsStateMachine>;
type LocalActionArgs = ActionArgs<LocalContext, LocalEvent, LocalEvent>;

const { path: suppressionsEndpoint } =
	config.urls.api.staffMembers.profiles.emailSubscriptions;
const { patch: addSuppression, delete: removeSuppression } = createResource({
	endpoint: suppressionsEndpoint,
});

const emailSubscriptionsMachine = resourceMachineFactory<
	Record<string, unknown>,
	{ groupId: number | string }
>("email-subscriptions").provide({
	actors: {
		delete: fromPromise(({ input }) => {
			const { event } = input as LocalActionArgs;

			assertEvent(event, EventType.Delete);

			const { params } = event;
			const parameters = snakeCaseKeys({ groupId: params.groupId });

			return removeSuppression({ parameters });
		}),

		patch: fromPromise(({ input }) => {
			const { event } = input as LocalActionArgs;

			assertEvent(event, EventType.Patch);

			const { params } = event;
			const parameters = snakeCaseKeys({ groupId: params.groupId });

			return addSuppression({ parameters });
		}),
	},
});

export { emailSubscriptionsMachine };
