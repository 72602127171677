<script lang="ts">
  import {getModifierClassName} from '$applib/utils/components';
  import {Icon, IconId} from '$applib/components/icon';

  import type {LoaderModifier} from './enums';

  export let modifiers: LoaderModifier[] = [];
  let klass = '';
  export {klass as class};

  let baseClassName = 'loader';
  let modifiersClassName = getModifierClassName(baseClassName, modifiers);

  const className = [klass, modifiersClassName].filter(Boolean).join(' ');
</script>

<span class="loader {className}" {...$$restProps}>
  <Icon class="icon loader__icon" id={IconId.CircularArrow} />

  {#if $$slots.default}
    <span class="loader__content">
      <slot />
    </span>
  {/if}
</span>
