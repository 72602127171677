import { EmailSubscriber } from "./components/email-subscriber";

const NOTIFICATION_SUPPRESSOR_SELECTOR = ".js-notification-suppressor";

const suppressors = document.querySelectorAll<HTMLSlotElement>(
	NOTIFICATION_SUPPRESSOR_SELECTOR,
);

for (const el of suppressors) {
	const state = el.dataset.suppressionState;
	const groupId = el.dataset.suppressionGroupId || "";
	const checked = state !== "suppressed";

	el.innerHTML = "";

	new EmailSubscriber({ target: el, props: { checked, groupId } });
}
