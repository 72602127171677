/**
 * resetEventHandlersFactor.
 *
 * removes any existing event handlers for the createAppointment function,
 * and then rebinds them
 *
 * Useful for when the DOM changes, and new elements are added
 *
 * @param {string} className
 */
function resetEventHandlersFactory({
	className,
	eventType,
	handler,
}: {
	className: string;
	eventType: Event["type"];
	handler: (event: Event) => void;
}) {
	return () => {
		const elements = Array.from(document.getElementsByClassName(className));

		elements.map((element) => {
			element.removeEventListener(eventType, handler);
			element.addEventListener(eventType, handler);
		});
	};
}

/**
 * bindOnce.
 *
 * Binds an event handler to the target element only once, ensuring that if the
 * script is initialised again, say by importing dynamically, that the previously
 * bound handler will first be removed before adding the new handler
 */
function bindOnce({
	target,
	eventName,
	handler,
}: {
	target: Element | Document;
	eventName: string;
	handler: (event: Event) => void;
}) {
	target.removeEventListener(eventName, handler); // remove existing handler if present
	target.addEventListener(eventName, handler); // add new handler
}

export { resetEventHandlersFactory, bindOnce };
