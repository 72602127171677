<script lang="ts">
  import {fly} from 'svelte/transition';

  import type {PatientSearchItem} from './types';
  import {PatientListState} from './enum';
  import PatientItem from './patient-item.svelte';

  export let patients: PatientSearchItem[] = [];
  export let state: PatientListState = PatientListState.Hidden;
</script>

{#if state === PatientListState.Visible}
  <div
    class="patient-list u-drop-shadow u-padding--small u-border-radius u-margin--block-end"
    in:fly
  >
    {#each patients as patientItem, i}
      <div class={`${i < patients.length ? 'u-margin--block-end' : ''}`}>
        <PatientItem on:patientselected {patientItem} />
      </div>
    {:else}
      <div class="u-padding--small">
        <small> No matching patients found </small>
      </div>
    {/each}
  </div>
{/if}

<style>
  .patient-list {
    max-height: 60vh;
    overflow: auto;
  }
</style>
