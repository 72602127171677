import {
	_,
	addMessages,
	getLocaleFromNavigator,
	init,
	locale,
} from "svelte-i18n";
import { get } from "svelte/store";

import { dict as enZaDict } from "./dictionaries/en-za";

addMessages("en", enZaDict);

init({
	fallbackLocale: "en-ZA",
	initialLocale: getLocaleFromNavigator(),
});

/**
 * Used outside of Svelte components, and is replaced once locale.subscribe
 * subscribes to store
 *
 * From inside Svelte components import _ and use the reactive $_ store
 */
let format = (
	id: string,
	options?: { values: Record<string, string | number | boolean> },
) => {
	return `${id}, ${JSON.stringify(options)}`;
};

locale.subscribe((localeValue?: string | null) => {
	if (localeValue) {
		format = get(_);
	}
});

export { _, format };
