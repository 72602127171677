import { bindOnce } from "$applib/utils/event-handlers";
import { createActor, fromCallback } from "xstate";

import type { ActorRefFrom, MachineImplementationsFrom } from "xstate";

import {
	MUTATE_EVENT_NAME,
	mutationObserver,
} from "$applib/actions/mutation-observer";

import { dropdownMachine } from "./machine";
import { EventName } from "./machine/events";

import type { DropdownMachine } from "./machine";

type DropdownActor = ActorRefFrom<DropdownMachine>;

const MENU_SELECTOR = "[data-hs-dropdown-menu]";

const options: Partial<MachineImplementationsFrom<DropdownMachine>> = {
	actions: {
		expandMenu({ context: { toggle, menuEl } }) {
			for (const el of [toggle, menuEl]) {
				el.setAttribute("aria-expanded", "true");
			}

			if (!menuEl.contains(document.activeElement)) {
				const child = menuEl.querySelector<HTMLElement>("[role=menuitem]");

				if (child) {
					child.focus();
				}
			}
		},

		collapseMenu({ context: { menuEl, toggle } }) {
			for (const el of [toggle, menuEl]) {
				el.setAttribute("aria-expanded", "false");
			}
		},
	},
	actors: {
		ioExpanded: fromCallback(({ sendBack, input: { toggle, menuEl } }) => {
			const handlers = [
				{
					target: toggle,
					event: "focusout",
					handler: function handleFocusToggle(event: Event) {
						sendBack({
							type: EventName.MenuFocusOut,
							params: { event: event as FocusEvent },
						});
					},
				},
				{
					target: menuEl,
					event: "focusout",
					handler: function handleFocusMenu(event: Event) {
						sendBack({
							type: EventName.MenuFocusOut,
							params: { event: event as FocusEvent },
						});
					},
				},
				{
					target: menuEl,
					event: "dropdownmenu:close",
					handler: function handleCloseEvent() {
						// NOTE: waits for event loop to complete before firing the callback,
						// which ensures that the close event is only sent after all
						// the events have bubbled
						if (typeof requestIdleCallback === "function") {
							requestIdleCallback(() => sendBack({ type: EventName.RxClose }), {
								timeout: 0,
							});
						} else {
							sendBack({ type: EventName.RxClose });
						}
					},
				},
				{
					target: document,
					event: "mousedown",
					handler: function handleMousedown(event: Event) {
						const target = event.target as HTMLElement;
						const isWithinDropdown =
							toggle.contains(target) || menuEl.contains(target);

						if (!isWithinDropdown) {
							sendBack({ type: EventName.DocumentClick });
						}
					},
				},
				{
					target: document,
					event: "keydown",
					handler: function handleKeydown(event: Event) {
						const { key } = event as KeyboardEvent;

						if (key === "Escape") {
							sendBack({ type: EventName.DocumentPressEscape });
						}
					},
				},
			];

			for (const { target, event, handler } of handlers) {
				target.addEventListener(event, handler);
			}

			return function destroy() {
				for (const { target, event, handler } of handlers) {
					target.removeEventListener(event, handler);
				}
			};
		}),
		ioGlobal: fromCallback(({ sendBack, input: { toggle, menuEl } }) => {
			const handlers = [
				{
					target: toggle,
					event: "mousedown",
					handler: function handleToggleClick() {
						sendBack({ type: EventName.ToggleClick });
					},
				},
				{
					target: toggle,
					event: "keydown",
					handler: function handleTogleKeyDown(event: Event) {
						const { key } = event as KeyboardEvent;

						if (key === "Enter") {
							sendBack({ type: EventName.TogglePressEnter });
						}
					},
				},
				{
					target: menuEl,
					event: "focusin",
					handler: function handleFocus(event: Event) {
						sendBack({
							type: EventName.MenuFocusIn,
							params: { event: event as FocusEvent },
						});
					},
				},
			];

			for (const { target, event, handler } of handlers) {
				target.addEventListener(event, handler);
			}

			return function destroy() {
				for (const { target, event, handler } of handlers) {
					target.removeEventListener(event, handler);
				}
			};
		}),
	},
	guards: {
		isOutsideFocus: ({ context: { menuEl, toggle } }, params) => {
			const { event: domEvent } = params;
			const relatedTarget = domEvent.relatedTarget as HTMLElement;
			const isOutside = Boolean(
				relatedTarget &&
					!(menuEl.contains(relatedTarget) || toggle.contains(relatedTarget)),
			);

			return isOutside;
		},
	},
};

function dropdownsManagerFactory() {
	// HACK: LB - the script containing this module is somehow loaded a second time
	// when the annotated image widget is rendered.
	// This additional loading results in dropdowns in the annotated widget initting
	// themselves again when they are moved
	// A moved element seems to be evaluated by the DOM as a new element, so evaluating
	// against that element doesn't prevent events from being added to it. Furthermore,
	// To work around this, we need to store the services against the window
	// so that dropdowns already initted are evaluated before being initted again,
	// regardless of how many times the script is loaded
	// @ts-ignore
	window._dropdownsManagerServices =
		// @ts-ignore
		window._dropdownsManagerServices ||
		new Map<string, { service: DropdownActor; destroy(): void }>();
	const services: Map<string, { service: DropdownActor; destroy(): void }> =
		// @ts-ignore
		window._dropdownsManagerServices;

	function init() {
		const { body } = document;

		mutationObserver(body, { childList: true, subtree: true });
		body.addEventListener(MUTATE_EVENT_NAME, cleanDropdowns);
	}

	function cleanDropdowns(event: Event) {
		const customEvent = event as CustomEvent<{ mutations: MutationRecord[] }>;
		const { mutations } = customEvent.detail;
		const elementIds = Array.from(services.keys());

		mutations
			.flatMap((xs) => Array.from(xs.removedNodes))
			.filter((x) => x instanceof HTMLElement)
			.flatMap((x) => {
				return elementIds
					.map((id) => (x as HTMLElement).querySelector(`[id="${id}"]`))
					.filter(Boolean);
			})
			.filter((x) => elementIds.includes((x as HTMLElement).id))
			.map((x) => removeDropdown(x as HTMLElement));
	}

	function addDropdown(element: HTMLElement) {
		const menuEl = element.querySelector<HTMLElement>(MENU_SELECTOR);
		const toggleId = menuEl ? menuEl.getAttribute("aria-labelledby") : null;
		const toggle = toggleId
			? (document.querySelector(`#${toggleId}`) as HTMLButtonElement)
			: null;

		if (services.has(element.id) || !toggle || !menuEl) {
			return;
		}

		const service = createActor(dropdownMachine.provide(options), {
			input: { toggle, menuEl },
		});

		service.start();

		element.setAttribute("data-hs-dropdown-state", "initialized");

		services.set(element.id, {
			service,
			destroy() {
				service.stop();
				services.delete(element.id);
			},
		});
	}

	function removeDropdown(element: HTMLElement) {
		const service = services.get(element.id);

		if (service) {
			service.destroy();
		}
	}

	init();

	return {
		addDropdown,
	};
}

const dropdownManager = dropdownsManagerFactory();

function handleDropdownInit(event: Event) {
	const target = event.target as HTMLElement;

	dropdownManager.addDropdown(target);
}

bindOnce({
	target: document,
	eventName: "hs:dropdown:init",
	handler: handleDropdownInit,
});
