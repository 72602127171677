<script lang="ts">
  import {createEventDispatcher} from 'svelte';

  import type {PaginatedApiResponse} from '$applib/types/request-response';

  import {
    GridWrap,
    GridWrapModifier,
    GridCol,
    GridColModifier,
  } from '$applib/components/grid';
  import {Button, ButtonModifier} from '$applib/components/button';
  export let response: PaginatedApiResponse<unknown>;

  import {noop} from '$applib/utils/functions';

  const dispatch = createEventDispatcher();
  const minTerminal = 3;
  const minAdjacent = 1;

  $: total = response.total;
  $: itemsPerPage = response.itemsPerPage;
  $: currentPage = response.page;
  $: totalPages = itemsPerPage !== 0 ? Math.abs(total / itemsPerPage) : 1;
  $: pageNums = Array.from({length: total / itemsPerPage})
    .map((_, i) => i + 1)
    .map((pageNum) => {
      const isTerminal =
        pageNum <= minTerminal || totalPages - pageNum <= minTerminal;
      const isNearCurrent = Math.abs(currentPage - pageNum) <= minAdjacent;

      return isTerminal || isNearCurrent ? pageNum : null;
    })
    .reduce((acc, x, index, xs) => {
      const previous = xs.slice(Math.max(index - 1, 0))[0];
      const isFirstNull = typeof x !== 'number' && typeof previous === 'number';
      const isNum = Boolean(x);

      if ((isFirstNull || isNum) && x) {
        acc = acc.concat(x);
      }

      return acc;
    }, [] as number[]);

  function handleClick(page: number) {
    return () => {
      dispatch('paginationselectpage', {page});
    };
  }
</script>

{#if pageNums.length > 1}
  <div class="u-display--inline-block">
    <GridWrap modifiers={[GridWrapModifier.Small, GridWrapModifier.BlockEnd]}>
      {#each pageNums as pageNum}
        <GridCol modifiers={[GridColModifier.ShrinkWrap]}>
          <div class="u-margin--smallest--block-end">
            {#if pageNum}
              <Button
                disabled={pageNum === currentPage}
                on:click={pageNum !== currentPage ? handleClick(pageNum) : noop}
                modifiers={[
                  ButtonModifier.Small,
                  pageNum === currentPage
                    ? ButtonModifier.ClrBaseLt
                    : ButtonModifier.ClrOutlinedBaseLt,
                ]}
              >
                {pageNum}
              </Button>
            {:else}
              &hellip;
            {/if}
          </div>
        </GridCol>
      {/each}
    </GridWrap>
  </div>
{/if}
