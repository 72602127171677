<script lang="ts">
  import CustomCheckboxRadioLabel from './label.svelte';

  export let label: string = '';
  export let id: string = '';
  export let type = 'checkbox';
</script>

<!--
  Make attributes available to input so that they only need to be defined once

  Attribute usage is optional, as they can be set on the input directly, too

  This slot expects a checkbox or radio input
-->
<slot inputClass="u-accessibly-hidden" inputAttributes={{id, type}} />

<CustomCheckboxRadioLabel {label} {id} {type} />
