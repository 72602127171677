import { format } from "date-fns";
import { fromCallback } from "xstate";

import type { EventFrom } from "xstate";

import { InvoiceSetEventName, SubmissionState } from "../../shared/enums";

import { createDomSelector } from "./utils";

import type {
	InvoiceSetCallbackLogic,
	InvoiceSetStateMachine,
} from "../../shared/machines/invoice-sets";

function formatDate(date: Date) {
	return format(date, "EEEE, MMMM dd, yyyy");
}

function domUpdateFactory(invoiceSetEl: HTMLElement) {
	const selector = createDomSelector(invoiceSetEl);

	return {
		setDeleteInputValue(value: "checked" | "unchecked") {
			const { getDeleteInput } = selector;

			getDeleteInput().checked = value === "checked";
		},

		setDeleteButtonState(toggleState: "delete" | "restore") {
			const { getDeleteButton } = selector;

			getDeleteButton().dataset.state = toggleState;
		},

		emitDueDatesUpdate(dueDates: Date[]) {
			const { getDueDatesOutput } = selector;
			const elEvent = new CustomEvent("invoicegen:invoiceset:duedateschange", {
				detail: { dueDates: dueDates.map(formatDate) },
			});

			getDueDatesOutput().dispatchEvent(elEvent);
		},

		emitRefDateUpdate(refDate: Date) {
			const { getRefDateOutput } = selector;
			const elEvent = new CustomEvent("invoicegen:invoiceset:refdatechange", {
				detail: { refDate: formatDate(refDate) },
			});

			getRefDateOutput().dispatchEvent(elEvent);
		},

		emitOrderUpdate(order: number) {
			const elEvent = new CustomEvent("invoicegen:invoiceset:orderchange", {
				detail: { order },
			});

			invoiceSetEl.dispatchEvent(elEvent);
		},

		updateTotalLineItemsInputValue(numLineItems: number) {
			const { getTotalLineItemsInput } = selector;

			getTotalLineItemsInput().value = `${numLineItems}`;
		},

		updateLineItemsInserter(numLineItems: number) {
			const { getLineItemsAdder } = selector;

			getLineItemsAdder().dataset.nextItemIndex = `${numLineItems}`;
		},

		updateFormEditability(state: "enabled" | "disabled") {
			const xs = selector;
			const lineItemsAdder = xs.getLineItemsAdder();
			const buttons = [lineItemsAdder.querySelector("button")].filter(
				(x): x is HTMLButtonElement => Boolean(x),
			);
			const inputs = [
				...xs.getDateTypeInputs(),
				xs.getDateMinDurationInput(),
				xs.getDateMinDurationPeriodInput(),
				xs.getDateOrdinalPeriodInput(),
				xs.getDateOrdinalValueMonthlyInput(),
				xs.getDateOrdinalValueWeeklyInput(),
				xs.getNumInvoicesInput(),
				xs.getDateCardinalPeriodInput(),
				xs.getDateCardinalValueInput(),
			];

			if (state === "enabled") {
				lineItemsAdder.classList.remove("u-display--none");
			} else {
				lineItemsAdder.classList.add("u-display--none");
			}

			for (const input of inputs) {
				if (state === "enabled") {
					input.removeAttribute("readonly");
				} else {
					input.setAttribute("readonly", "");
				}
			}

			for (const button of buttons) {
				if (state === "enabled") {
					button.removeAttribute("disabled");
				} else {
					button.setAttribute("disabled", "");
				}
			}

			const className = "u-fc--base-ltr";
			const classListFn = state === "enabled" ? "remove" : "add";

			invoiceSetEl.classList[classListFn](className);
		},

		renderInvoiceSetTotal(params: {
			numInvoices: number;
			totalPerInvoice: number;
		}) {
			const { getTotalOutputEl } = selector;
			const [totalPerInvoice, invoiceSetTotal] = [
				params.totalPerInvoice,
				params.numInvoices !== 0
					? params.totalPerInvoice * params.numInvoices
					: 0,
			].map((x) => {
				const [_, decimal] = `${x}`.split(".");

				return (decimal || "").length > 1 ? `${x}` : x.toFixed(2);
			});
			const event = new CustomEvent("invoicegen:invoiceset:total:change", {
				detail: { totalPerInvoice, invoiceSetTotal },
			});

			getTotalOutputEl().dispatchEvent(event);
		},
	};
}

function ioDispatchCreator(element: HTMLElement) {
	return fromCallback<EventFrom<InvoiceSetStateMachine>>(
		({ sendBack, receive }) => {
			const handlersByEvent = {
				"invoicegen:lineitem:init": function handleLineItemInit(event: Event) {
					sendBack({
						type: InvoiceSetEventName.IoRxLineItemInit,
						params: { element: event.target },
					});
				},
				"invoicegen:invoiceset:delete:click": function handleClickDelete() {
					sendBack({ type: InvoiceSetEventName.IoRxInvoiceSetDeleteClick });
				},
				"invoicegen:invoiceset:change:numinvoices":
					function handleChangeNumInvoices(event: Event) {
						const { detail } = event as CustomEvent;
						const value = Number.parseInt(detail.numInvoices);

						sendBack({
							type: InvoiceSetEventName.IoRxChangeNumInvoices,
							params: { numInvoices: !Number.isNaN(value) ? value : 1 },
						});
					},

				/* date events */
				"invoicegen:invoiceset:change:datecardinalperiod":
					function handleChangeDateCardinalPeriod(event: Event) {
						const { detail } = event as CustomEvent;

						sendBack({
							type: InvoiceSetEventName.IoRxDateFieldChange,
							params: { cardinalPeriod: detail.value },
						});
					},
				"invoicegen:invoiceset:change:datecardinalvalue":
					function handleChangeDateCardinalValue(event: Event) {
						const { detail } = event as CustomEvent;
						const value = Number.parseInt(detail.value);

						sendBack({
							type: InvoiceSetEventName.IoRxDateFieldChange,
							params: { cardinalValue: !Number.isNaN(value) ? value : 4 },
						});
					},
				"invoicegen:invoiceset:change:refdateoffsetperiod":
					function handleChangerefdateoffsetperiod(event: Event) {
						const { detail } = event as CustomEvent;

						sendBack({
							type: InvoiceSetEventName.IoRxDateFieldChange,
							params: { minDurationPeriod: detail.value },
						});
					},
				"invoicegen:invoiceset:change:refdateoffsetvalue":
					function handleChangeDateMinDuration(event: Event) {
						const { detail } = event as CustomEvent;
						const value = Number.parseInt(detail.value);

						sendBack({
							type: InvoiceSetEventName.IoRxDateFieldChange,
							params: { minDuration: !Number.isNaN(value) ? value : 0 },
						});
					},
				"invoicegen:invoiceset:change:dateordinalperiod":
					function handleChangeDateOrdinalPeriod(event: Event) {
						const { detail } = event as CustomEvent;

						sendBack({
							type: InvoiceSetEventName.IoRxDateFieldChange,
							params: { ordinalPeriod: detail.value },
						});
					},
				"invoicegen:invoiceset:change:dateordinalvaluemonthly":
					function handleChangeDateOrdinalValueMonthly(event: Event) {
						const { detail } = event as CustomEvent;
						const value = Number.parseInt(detail.value);

						sendBack({
							type: InvoiceSetEventName.IoRxDateFieldChange,
							params: {
								ordinalValueMonthly: !Number.isNaN(value) ? value : 1,
							},
						});
					},
				"invoicegen:invoiceset:change:dateordinalvalueweekly":
					function handleChangeDateOrdinalValueWeekly(event: Event) {
						const { detail } = event as CustomEvent;
						const value = Number.parseInt(detail.value);

						sendBack({
							type: InvoiceSetEventName.IoRxDateFieldChange,
							params: {
								ordinalValueWeekly: !Number.isNaN(value) ? value : 0,
							},
						});
					},
				"invoicegen:invoiceset:change:datetype": function handleChangeDateType(
					event: Event,
				) {
					const { detail } = event as CustomEvent;

					sendBack({
						type: InvoiceSetEventName.IoRxDateFieldChange,
						params: { dateType: detail.value },
					});
				},
			};

			receive((event) => {
				const domUpdater = domUpdateFactory(element);

				switch (event.type) {
					case InvoiceSetEventName.IoTxOrderChange: {
						const {
							params: { order },
						} = event;

						domUpdater.emitOrderUpdate(order);
						break;
					}
					case InvoiceSetEventName.IoTxSubmissionStateChange: {
						const {
							params: { state },
						} = event;
						const editability =
							state === SubmissionState.MarkedForDeletionBySelf
								? "disabled"
								: "enabled";
						const buttonState =
							state === SubmissionState.MarkedForDeletionBySelf
								? "restore"
								: "delete";
						const deleteInputValue =
							state === SubmissionState.MarkedForDeletionBySelf
								? "checked"
								: "unchecked";

						domUpdater.updateFormEditability(editability);
						domUpdater.setDeleteButtonState(buttonState);
						domUpdater.setDeleteInputValue(deleteInputValue);
						break;
					}
					case InvoiceSetEventName.IoTxNumLineItemsChange: {
						const {
							params: { numLineItems },
						} = event;

						domUpdater.updateLineItemsInserter(numLineItems);
						domUpdater.updateTotalLineItemsInputValue(numLineItems);

						break;
					}
					case InvoiceSetEventName.IoTxTotalChange: {
						const { numInvoices, totalPerInvoice } = event.params;

						domUpdater.renderInvoiceSetTotal({
							totalPerInvoice,
							numInvoices,
						});

						break;
					}
					case InvoiceSetEventName.IoTxDueDatesChange: {
						const { dueDates, refDate } = event.params;

						domUpdater.emitDueDatesUpdate(dueDates);
						domUpdater.emitRefDateUpdate(refDate);
						break;
					}
				}
			});

			for (const [event, handler] of Object.entries(handlersByEvent)) {
				element.addEventListener(event, handler);
			}

			return function destroy() {
				for (const [event, handler] of Object.entries(handlersByEvent)) {
					element.removeEventListener(event, handler);
				}
			};
		},
		// TODO: LB - determine why casting this is required to fix TS
		// errors in ../index.ts
	) as InvoiceSetCallbackLogic;
}

export { ioDispatchCreator };
