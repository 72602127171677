import { config } from "$applib/configs/application";
import { CustomDomEvent } from "$applib/enums/events";
import { buildHeaders, getCsrfTokenHeader } from "$applib/utils/headers";
import { snakeCaseKeys } from "$applib/utils/objects";
import { parameterizePath } from "$applib/utils/resources/urls";

import type { AppointmentTimeSlot } from "$applib/types/resources/appointments";

import {
	getTimeSlotId,
	renderTimeSlot,
} from "../shared/utils/appointment-grid";

const DELETE_RESERVED_APPOINTMENT_CLASS = "js-appointment-grid-delete-reserved";

const { urls } = config;
const { path: reservedAppointmentsDetailEndpoint } =
	urls.api.appointments.reservedDetail;

function createReservedAppointment(buttonEl: HTMLButtonElement) {
	const data = buttonEl.dataset;
	const url = parameterizePath(
		reservedAppointmentsDetailEndpoint,
		snakeCaseKeys({ appointmentId: data.appointmentId }),
	);

	fetch(url, {
		method: "DELETE",
		headers: buildHeaders({
			...getCsrfTokenHeader(),
			"Content-Type": "application/json",
			Accept: "application/json",
		}),
	})
		.then((response) => {
			if (response.ok) {
				return response.json();
			} else {
				// TODO: LB - properly handle this error
				throw response;
			}
		})
		.then((response) => {
			const timeSlot: AppointmentTimeSlot | undefined = response;

			if (!timeSlot) {
				return;
			}

			const timeSlotId = getTimeSlotId(timeSlot);
			const timeSlotEl = document.getElementById(timeSlotId);

			if (timeSlotEl) {
				timeSlotEl.outerHTML = renderTimeSlot(timeSlot);

				const event = new CustomEvent(
					CustomDomEvent.AppointmentGridAppointmentUpdated,
				);
				document.dispatchEvent(event);
			}
		})
		.catch((response) => {
			console.error(response);
		});
}

function initDeleteReservedAppointments() {
	document.body.addEventListener("click", (event: MouseEvent) => {
		const target = event.target as HTMLElement;
		const isElement = target.classList.contains(
			DELETE_RESERVED_APPOINTMENT_CLASS,
		);
		const parentEl = target.closest(`.${DELETE_RESERVED_APPOINTMENT_CLASS}`);
		const buttonEl = isElement ? target : parentEl;

		if (buttonEl) {
			createReservedAppointment(buttonEl as HTMLButtonElement);
		}
	});
}

export { initDeleteReservedAppointments };
