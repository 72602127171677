import { useMachine } from "@xstate/svelte";

import { metadataMachine } from "$applib/machines/metadata";

import type {
	MetadataMachineContext,
	MetadataStateMachine,
} from "$applib/machines/metadata";

import type { Metadata, MetadataType } from "$applib/types/resources/metadata";
import { EventType } from "$applib/utils/resources/create-resource-machine";

interface MetadataStore
	extends ReturnType<typeof useMachine<MetadataStateMachine>> {
	getMetadataResource: (
		metadataType: MetadataType,
		context: MetadataMachineContext,
	) => Metadata[MetadataType];
	fetchMetadata: () => void;
}

let store: MetadataStore;

function initMetadata() {
	if (store) {
		return store;
	}

	const interpreter = useMachine(metadataMachine);
	const fetchMetadata = () => interpreter.send({ type: EventType.Get });
	const getMetadataResource: MetadataStore["getMetadataResource"] =
		function getMetadataResource(key, context) {
			return context && context.item && context.item[key]
				? context.item[key]
				: [];
		};

	store = {
		fetchMetadata,
		getMetadataResource,
		...interpreter,
	};

	return store;
}

function useMetadata() {
	return store ? store : initMetadata();
}

export { useMetadata };
