export enum IconId {
	CalendarAdd = "calendar-add",
	CheckboxFalse = "checkbox-false",
	CheckboxTrue = "checkbox-true",
	CircleOutlined = "circle-outlined",
	CircularArrow = "circular-arrow",
	Cross = "cross",
	DrawFreehand = "draw-freehand",
	DrawLine = "draw-line",
	DrawText = "draw-text",
	Dropper = "dropper",
	ExclamationTriangle = "exclamation-triangle",
	EyeVisible = "eye-visible",
	FontSize = "font-size",
	LineWeight = "line-weight",
	RadioFalse = "radio-false",
	RadioTrue = "radio-true",
	Redo = "redo",
	SquareOutlined = "square-outlined",
	Tick = "tick",
	TrashCasn = "trash-can",
	Undo = "undo",
}
