import type { LocaleDictionary } from "$applib/types/i18n";

interface AppointmentGridDictionary extends LocaleDictionary {
	createPatient: {
		form: {
			fields: {
				dob: { label: string; validations: { required: string } };
				email: { label: string; validations: { required: string } };
				firstNames: { validations: { required: string }; label: string };
				gender: { validations: { required: string }; label: string };
				lastName: { validations: { required: string }; label: string };
				telHome: { label: string; placeholder: string };
				telMobile: {
					validations: { required: string };
					label: string;
					placeholder: string;
				};
			};
		};
	};
}

interface ViewsDictionary extends LocaleDictionary {
	appointmentGrid: AppointmentGridDictionary;
}

const views: ViewsDictionary = {
	appointmentGrid: {
		createPatient: {
			form: {
				fields: {
					dob: {
						label: "Birthdate",
						validations: { required: "Please select a birthdate" },
					},
					email: {
						label: "Email address",
						validations: { required: "Please add an email address" },
					},
					firstNames: {
						validations: { required: "Please add the patient's first names" },
						label: "First names",
					},
					gender: {
						validations: { required: "Please select a gender" },
						label: "Gender",
					},
					lastName: {
						validations: { required: "Please add a last name" },
						label: "Last name",
					},
					telHome: { label: "Home phone", placeholder: "+27 82 111 2222" },
					telMobile: {
						validations: {
							required: "Please add a mobile phone number",
						},
						label: "Mobile phone",
						placeholder: "+27 82 111 2222",
					},
				},
			},
		},
	},
};

export { views };
