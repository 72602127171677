<script lang="ts">
  import {setContext} from 'svelte';
  import {useMachine} from '@xstate/svelte';

  import {tabsMachine} from './machine';
  import {key} from './context';
  import {EventName} from './enums';

  const tabsStore = useMachine(tabsMachine);
  const {send} = tabsStore;

  setContext(key, tabsStore);

  function goToTab(id: string) {
    send({type: EventName.ActivateTab, params: {id}});
  }
</script>

<slot {goToTab} />
