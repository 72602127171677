import { enhanceMultiSelect } from "$applib/components/enhanced-multi-select";

import type { Settings } from "$applib/components/enhanced-multi-select";

interface Config {
	selector: string;
	settings: Partial<Settings>;
	element?: HTMLSelectElement;
}

const settings: Partial<Settings> = {
	selectedOptionsHeaderText: "Your selections",
	availableOptionsHeaderText: "Available options",
};
const configs: Config[] = [
	".js-patient-information-medical-history-multi-select",
	".js-patient-information-referring-doctors-multi-select",
].map((selector) => ({ selector, settings }));

configs
	.map((config) => {
		const element = document.querySelector(
			config.selector,
		) as HTMLSelectElement;

		return { ...config, element };
	})
	.filter(({ element }) => Boolean(element))
	.map(({ element, settings }) => enhanceMultiSelect().init(element, settings));
