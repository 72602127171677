import { ReportNeeded } from "$applib/types/resources/patient-discussions";
import { DiscussionType } from "./enums";

function getDiscussionType(reportNeeded?: ReportNeeded): DiscussionType {
	let discussionType = DiscussionType.NoDiscussion;

	switch (reportNeeded) {
		case ReportNeeded.Yes:
			discussionType = DiscussionType.ReportRequired;
			break;
		case ReportNeeded.No:
			discussionType = DiscussionType.ReportNotRequired;
			break;
	}

	return discussionType;
}

export { getDiscussionType };
