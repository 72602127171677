interface FilterDomNodesFactoryArgument {
	inputSelector: string;
	itemsSelector: string;
	getItemText?: (element: HTMLElement) => string | null;
}

type FilterDomNodesFactory = (options: FilterDomNodesFactoryArgument) => void;

const getItemTextDefault: FilterDomNodesFactoryArgument["getItemText"] =
	function getItemTextDefault(element) {
		return element.innerText;
	};

const filterDomNodesFactory: FilterDomNodesFactory =
	function filterDomNodesFactory({
		inputSelector,
		itemsSelector,
		getItemText,
	}) {
		const inputEl = document.querySelector<HTMLInputElement>(inputSelector);
		const itemEls = Array.from(
			document.querySelectorAll<HTMLElement>(itemsSelector) || [],
		);
		const getItemTextFn = getItemText ? getItemText : getItemTextDefault;

		function filterNodes(event: Event) {
			const target = event.target as HTMLInputElement;
			const value = target.value.toLowerCase();

			itemEls.map((element) => {
				const elementText = getItemTextFn(element);
				const shouldHide = elementText
					? elementText.toLowerCase().indexOf(value) === -1
					: false;
				const displayProperty = shouldHide ? "none" : "";

				element.style.display = displayProperty;
			});
		}

		if (inputEl) {
			inputEl.addEventListener("keyup", filterNodes);
		}
	};

export { filterDomNodesFactory };
