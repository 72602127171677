<script lang="ts">
  import {TabsContext} from '$applib/components/tabbed-content';
</script>

<TabsContext let:goToTab>
  <div class="button-tabs">
    <div class="button-tabs__navigation">
      <slot class="something" name="navigation" />
    </div>

    <div class="button-tabs__content">
      <slot {goToTab} />
    </div>
  </div>
</TabsContext>
