import { object, string } from "yup";
import type { SchemaOf } from "yup";

import { createForm } from "$applib/components/forms";
import { format } from "$applib/i18n";
import { telephone } from "$applib/validations";

import type { CreatePatientPayload } from "$applib/types/resources/patients";

const getTranslation = (suffix: string) =>
	format(`views.appointmentGrid.createPatient.form.fields.${suffix}`);

// TODO: LB - replace this form with a server-rendered form and async requests
// handled by htmx
// @ts-ignore
const validationSchema: SchemaOf<CreatePatientPayload> = object({
	dob: string().required(getTranslation("dob.validations.required")),
	email: string()
		.email()
		.required(getTranslation("email.validations.required")),
	firstNames: string().required(
		getTranslation("firstNames.validations.required"),
	),
	gender: string().required(getTranslation("gender.validations.required")),
	lastName: string().required(getTranslation("lastName.validations.required")),
	telHome: telephone(),
	telMobile: telephone().required(
		getTranslation("telMobile.validations.required"),
	),
});

type OnSubmit = (values: CreatePatientPayload) => void;

const createPatientForm = ({ onSubmit }: { onSubmit: OnSubmit }) => {
	const result = createForm({
		initialValues: {
			dob: "",
			email: "",
			firstNames: "",
			gender: "",
			lastName: "",
			telHome: "",
			telMobile: "",
		},
		onSubmit,
		validationSchema,
	});

	return result;
};
export { createPatientForm };
