import { assertEvent, fromPromise } from "xstate";

import type { ActionArgs, ContextFrom, EventFrom } from "xstate";

import { config } from "$applib/configs/application";
import { snakeCaseKeys } from "$applib/utils/objects";
import { createResource } from "$applib/utils/resources";
import {
	EventType,
	resourceMachineFactory,
} from "$applib/utils/resources/create-resource-machine";

import type {
	CreatePatientPayload,
	Patient,
} from "$applib/types/resources/patients";

export type CreatePatientStateMachine = typeof patientMachine;
type LocalContext = ContextFrom<CreatePatientStateMachine>;
type LocalEvent = EventFrom<CreatePatientStateMachine>;
type LocalActionArgs = ActionArgs<LocalContext, LocalEvent, LocalEvent>;

const { urls } = config;
const { path: createPatientEndpoint } = urls.api.patients.list;
const { post: createPatient } = createResource<
	Partial<CreatePatientPayload>,
	Patient
>({ endpoint: createPatientEndpoint });

const patientMachine = resourceMachineFactory<
	Patient,
	CreatePatientPayload,
	{ organisationId: number }
>("patient").provide({
	actors: {
		post: fromPromise(({ input }) => {
			const { context, event } = input as LocalActionArgs;

			assertEvent(event, EventType.Post);

			const { organisationId } = context;

			return createPatient({
				body: snakeCaseKeys(event.params),
				parameters: snakeCaseKeys({ organisationId }),
			});
		}),
	},
});

export { patientMachine };
