<script lang="ts">
  import {getModifierClassName} from '$applib/utils/components';
  import {Icon, IconId} from '$applib/components/icon';

  import {AlertType} from './enums';

  import type {AlertModifier} from './enums';

  export let onClose: undefined | (() => void) = undefined;
  export let modifiers: AlertModifier[] = [];
  export let type: AlertType = AlertType.Info;
  let klass = '';
  export {klass as class};

  let baseClassName = 'alert';
  let modifiersClassName = getModifierClassName<AlertModifier | AlertType>(
    baseClassName,
    modifiers.concat(type as unknown as AlertModifier),
  );

  const className = [baseClassName, klass, modifiersClassName]
    .filter(Boolean)
    .join(' ');
</script>

<div class={className} data-testid="alert" {...$$restProps}>
  {#if onClose}
    <button class="alert__close" data-testid="alert-close" on:click={onClose}>
      <Icon id={IconId.Cross} />
    </button>
  {/if}

  <slot />
</div>
