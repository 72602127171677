const getDomSelectors = (formPrefix: string) => {
	return {
		deleteButton: "[data-delete-toggler]",
		deleteInput: `input[name=${formPrefix}-DELETE]`,
		orderInput: `input[name=${formPrefix}-ORDER]`,
		priceInput: `input[name=${formPrefix}-price]`,
		priceTypeInput: `input[name$=${formPrefix}-price_type]`,
		quantityInput: `input[name=${formPrefix}-quantity]`,
		totalOutputEl: "[data-invoice-set-line-item-output]",
	};
};

function createDomSelector(element: HTMLElement) {
	const selectors = getDomSelectors(element.dataset.formPrefix || "");

	return {
		getDeleteButton: () =>
			element.querySelector<HTMLButtonElement>(
				selectors.deleteButton,
			) as HTMLButtonElement,
		getDeleteInput: () =>
			element.querySelector<HTMLInputElement>(
				selectors.deleteInput,
			) as HTMLInputElement,
		getOrderInput: () =>
			element.querySelector<HTMLInputElement>(
				selectors.orderInput,
			) as HTMLInputElement,
		getPriceInput: () =>
			element.querySelector<HTMLInputElement>(
				selectors.priceInput,
			) as HTMLInputElement,
		getPriceTypeInputs: () =>
			element.querySelectorAll<HTMLInputElement>(selectors.priceTypeInput),
		getQuantityInput: () =>
			element.querySelector<HTMLInputElement>(
				selectors.quantityInput,
			) as HTMLInputElement,
		getTotalOutputEl: () =>
			element.querySelector<HTMLOutputElement>(
				selectors.totalOutputEl,
			) as HTMLOutputElement,
	};
}

export { createDomSelector };
