<script lang="ts">
  import {fly} from 'svelte/transition';
  import {createEventDispatcher} from 'svelte';

  import {Alert, AlertModifier} from '$applib/components/alert';

  import type {AlertType} from '$applib/components/alert';

  export let alertType: AlertType;
  export let errors: string[] = [];
  export let message: string;
  export let timeout = 0;

  const dispatch = createEventDispatcher();
  const timer = timeout > 0 ? setTimeout(handleClose, timeout) : undefined;

  function handleClose() {
    clearTimeout(timer);

    dispatch('close');
  }
</script>

<div class="u-padding--smallest--block" in:fly={{y: -10}}>
  <Alert
    class="u-margin--none--block-end"
    modifiers={[AlertModifier.Small]}
    onClose={handleClose}
    type={alertType}
  >
    {message}

    {#if errors.length > 0}
      <ul>
        {#each errors as x}
          <li>{x}</li>
        {/each}
      </ul>
    {/if}
  </Alert>
</div>
