function getCookie(key: string) {
	const cookies = document.cookie.split(";");
	const cookie = cookies
		.map((x) => x.trim())
		.map((x) => x.split("="))
		.filter(([name]) => name == key)
		.map(([, value]) => decodeURIComponent(value))
		.find(Boolean);

	return cookie;
}

export { getCookie };
