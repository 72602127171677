import { Cookie } from "$applib/enums/cookies";
import { Header } from "$applib/enums/headers";
import { getCookie } from "$applib/utils/cookies";

function getCsrfTokenHeader() {
	const csrfToken = getCookie(Cookie.CsrfToken);

	return csrfToken ? { [Header.CsrfToken]: csrfToken } : {};
}

function buildHeaders(obj: Record<string, unknown>): Headers {
	const headers = new Headers();

	for (const [key, value] of Object.entries(obj)) {
		headers.append(key, value ? `${value}` : "");
	}

	return headers;
}

export { getCsrfTokenHeader, buildHeaders };
