import { enhanceMultiSelect } from "$applib/components/enhanced-multi-select";

import type { Settings } from "$applib/components/enhanced-multi-select";

interface Config {
	selector: string;
	settings: Partial<Settings>;
	element?: HTMLSelectElement;
}

const baseSettings: Partial<Settings> = {
	enableSearch: true,
};
const configs: Config[] = [
	{
		selector: ".js-report-treatment-category-form select[name=treatment_code]",
		settings: {
			selectedOptionsHeaderText: "Selected codes",
			availableOptionsHeaderText: "All codes",
		},
	},
	{
		selector: ".js-report-xray-observations-multi-select",
		settings: {
			selectedOptionsHeaderText: "Selected observations",
			availableOptionsHeaderText: "All observations",
		},
	},
	{
		selector: ".js-report-photo-observations-multi-select",
		settings: {
			selectedOptionsHeaderText: "Selected observations",
			availableOptionsHeaderText: "All observations",
		},
	},
];
const noteConfigs: Config[] = [
	".js-report-treatment-notes-multi-select",
	".js-report-general-notes-multi-select",
	".js-report-clinical-notes-multi-select",
	".js-report-surgery-notes-multi-select",
	".js-report-patient-notes-multi-select",
].map((selector) => {
	return {
		selector,
		settings: {
			selectedOptionsHeaderText: "Selected notes",
			availableOptionsHeaderText: "All notes",
		},
	};
});

[...configs, ...noteConfigs]
	.map((config) => {
		const element = document.querySelector(
			config.selector,
		) as HTMLSelectElement;

		return { ...config, element };
	})
	.filter(({ element }) => Boolean(element))
	.map(({ element, settings }) =>
		enhanceMultiSelect().init(element, { ...baseSettings, ...settings }),
	);
