import type { LocaleDictionary } from "$applib/types/i18n";

interface ValidationDictionary extends LocaleDictionary {
	telephone: {
		format: string;
		minLength: string;
	};
	email: {
		format: string;
		required: string;
	};
}

const validations: ValidationDictionary = {
	telephone: {
		format: "Please provide a valid telephone number",
		minLength: "The number you entered appears to be too short",
	},

	email: {
		format: "Please enter a valid email address",
		required: "Please enter a valid email address",
	},
};

export { validations };
