import {
	ShapeName,
	circleFactory,
	lineFactory,
	penFactory,
	rectangleFactory,
	textFactory,
} from "./tools";
import type { ShapeTool } from "./tools";

interface MyDrawing {
	currentStartX: number;
	currentStartY: number;
	allShapes: ShapeTool[];
	tempShape?: ShapeTool;
	movingShape?: ShapeTool;
	nextObject: ShapeName;
	nextColor: string;
	nextWidth: number;
	nextFontSize: number;
	isDrawing: boolean;
	moveX: number;
	moveY: number;
	drawAllShapes(context: CanvasRenderingContext2D): void;
	clearAllShapes(): void;
}

enum DrawingCanvasState {
	NotInitialized = "not-initialized",
	Initialized = "initialized",
}

enum ButtonState {
	Initial = "initial",
	Active = "active",
}

function drawingCanvasFactory(canvasSelector = ".js-legacy-drawing-canvas") {
	const HTML_STATE_ATTRIBUTE = "data-legacy-drawing-canvas-state";
	const canvasEl = document.querySelector<HTMLCanvasElement>(
		canvasSelector,
	) as HTMLCanvasElement;
	let context = canvasEl.getContext("2d") as CanvasRenderingContext2D;
	let rafId: ReturnType<typeof requestAnimationFrame>;
	const boundingRectanglesCache: Record<
		string,
		ReturnType<Element["getBoundingClientRect"]>
	> = {};

	const colorInput = document.querySelector<HTMLInputElement>(
		".js-legacy-drawing-canvas-color-input",
	);
	const textInput = document.querySelector<HTMLInputElement>(
		".js-legacy-drawing-canvas-text-input",
	) as HTMLInputElement;
	const fontSizeInput = document.querySelector<HTMLSelectElement>(
		".js-legacy-drawing-canvas-font-size-select",
	);
	const colorButtons: HTMLButtonElement[] = [].slice.call(
		document.querySelectorAll(".js-canvas-color-button") || [],
	);
	const toolInputs: HTMLInputElement[] = [].slice.call(
		document.querySelectorAll(".js-legacy-drawing-canvas-tool-input") || [],
	);
	const undoButton = document.querySelector(".js-legacy-drawing-canvas-undo");
	const redoButton = document.querySelector(".js-legacy-drawing-canvas-redo");
	const clearButton = document.querySelector(".js-legacy-drawing-canvas-clear");

	textInput.style.position = "absolute";

	hideTextInput();

	const myDrawing: MyDrawing = {
		currentStartX: 0,
		currentStartY: 0,
		allShapes: [],
		tempShape: undefined,
		movingShape: undefined,
		nextObject: ShapeName.Line,
		nextColor: "#000000",
		nextWidth: 1,
		nextFontSize:
			fontSizeInput && fontSizeInput.value
				? Number.parseInt(fontSizeInput.value, 10)
				: 18,
		isDrawing: false,
		moveX: 0,
		moveY: 0,
		drawAllShapes,
		clearAllShapes,
	};

	function drawAllShapes(localContext: CanvasRenderingContext2D) {
		localContext.clearRect(0, 0, canvasEl.width, canvasEl.height);

		myDrawing.allShapes
			.filter(Boolean)
			.map((shape: ShapeTool) => shape.draw(localContext));
	}

	function clearAllShapes() {
		myDrawing.allShapes = [];
	}

	function handleTouchStart(event: TouchEvent) {
		const [touch] = event.touches;
		const mouseEvent = new MouseEvent("mousedown", {
			clientX: touch.clientX,
			clientY: touch.clientY,
		});
		canvasEl.dispatchEvent(mouseEvent);
	}

	function handleTouchEnd() {
		const mouseEvent = new MouseEvent("mouseup", {});
		canvasEl.dispatchEvent(mouseEvent);
	}

	function handleTouchMove(event: TouchEvent) {
		const [touch] = event.touches;

		const mouseEvent = new MouseEvent("mousemove", {
			clientX: touch.clientX,
			clientY: touch.clientY,
		});
		canvasEl.dispatchEvent(mouseEvent);
	}

	function handleUpdateFontSize(event: Event) {
		const target = event.target as HTMLSelectElement;

		myDrawing.nextFontSize = Number.parseInt(target.value, 10);
	}

	function preventCanvasScroll(event: Event) {
		if (event.target == canvasEl) {
			event.preventDefault();
		}
	}

	function getCoordinates(event: MouseEvent, bounds: DOMRect) {
		const x = event.clientX - bounds.left;
		const y = event.clientY - bounds.top;

		return { x, y };
	}

	function getCanvasBounds(event: MouseEvent) {
		const { documentElement } = document;
		const canvasEl = event.currentTarget as HTMLCanvasElement;
		const { scrollTop, scrollLeft } = documentElement;
		const { width, height } = canvasEl;
		const key = [scrollTop, scrollLeft, width, height].join("-");

		if (!boundingRectanglesCache[key]) {
			boundingRectanglesCache[key] = canvasEl.getBoundingClientRect();
		}

		return boundingRectanglesCache[key];
	}

	function handleMouseDown(event: MouseEvent) {
		const rectangleEl = document.getElementById(
			"idRadioRect",
		) as HTMLInputElement;
		const lineEl = document.getElementById("idRadioLine") as HTMLInputElement;
		const circleEl = document.getElementById(
			"idRadioCircle",
		) as HTMLInputElement;
		const penEl = document.getElementById("idRadioPen") as HTMLInputElement;
		const textEl = document.getElementById("idRadioText") as HTMLInputElement;
		const moveEl = document.getElementById("idRadioMove") as HTMLInputElement;
		const lineSizeEl = document.getElementById(
			"idLineSize",
		) as HTMLInputElement;
		const canvasBounds = getCanvasBounds(event);
		const coords = getCoordinates(event, canvasBounds);

		myDrawing.nextWidth = Number.parseInt(lineSizeEl.value, 10);
		myDrawing.currentStartX = coords.x;
		myDrawing.currentStartY = coords.y;

		switch (true) {
			case rectangleEl.checked: {
				myDrawing.nextObject = ShapeName.Rectangle;
				break;
			}

			case lineEl.checked: {
				myDrawing.nextObject = ShapeName.Line;
				break;
			}

			case circleEl.checked: {
				myDrawing.nextObject = ShapeName.Ellipse;
				break;
			}

			case penEl.checked: {
				myDrawing.nextObject = ShapeName.Pen;
				myDrawing.tempShape = penFactory({
					startX: 0,
					startY: 0,
					x: 0,
					y: 0,
					color: myDrawing.nextColor,
					width: myDrawing.nextWidth,
				});

				myDrawing.tempShape.addCoordinate({
					x: myDrawing.currentStartX,
					y: myDrawing.currentStartY,
				});
				break;
			}

			case textEl.checked: {
				showTextInput({
					x: myDrawing.currentStartX,
					y: myDrawing.currentStartY,
				});
				break;
			}

			case moveEl.checked: {
				myDrawing.nextObject = ShapeName.Move;
				myDrawing.moveX = myDrawing.currentStartX;
				myDrawing.moveY = myDrawing.currentStartY;

				//look for a shape at a given point. Start from the newest
				for (let a = myDrawing.allShapes.length - 1; a >= 0; a--) {
					if (
						myDrawing.allShapes[a].findMe(
							myDrawing.currentStartX,
							myDrawing.currentStartY,
						)
					) {
						myDrawing.movingShape = myDrawing.allShapes[a];
						myDrawing.tempShape = myDrawing.allShapes[a];
						// if a shape is found we remove it ad redraw it later
						myDrawing.allShapes.splice(a, 1);
						break;
					}
				}

				break;
			}
		}

		myDrawing.isDrawing = !textEl.checked;
	}

	function handleResize() {
		if (rafId) {
			cancelAnimationFrame(rafId);
		}

		rafId = requestAnimationFrame(() => {
			destroy();

			init();
		});
	}

	function setCanvasDimensions() {
		const { offsetHeight: height, offsetWidth: width } =
			canvasEl.parentNode as HTMLElement;

		canvasEl.setAttribute("width", `${width}`);
		canvasEl.setAttribute("height", `${height}`);
	}

	function handleMouseMove(event: MouseEvent) {
		if (myDrawing.isDrawing) {
			//keeps previous shapes on the canvas
			myDrawing.drawAllShapes(context);
			undoRedo.resetAll();

			const canvasBounds = getCanvasBounds(event);
			const coords = getCoordinates(event, canvasBounds);

			const { x, y } = coords;

			switch (myDrawing.nextObject) {
				case ShapeName.Line: {
					myDrawing.tempShape = lineFactory({
						startX: myDrawing.currentStartX,
						startY: myDrawing.currentStartY,
						x: x,
						y: y,
						color: myDrawing.nextColor,
						width: myDrawing.nextWidth,
					});

					myDrawing.tempShape.draw(context);
					break;
				}

				case ShapeName.Rectangle: {
					myDrawing.tempShape = rectangleFactory({
						startX: myDrawing.currentStartX,
						startY: myDrawing.currentStartY,
						x: x - myDrawing.currentStartX,
						y: y - myDrawing.currentStartY,
						color: myDrawing.nextColor,
						width: myDrawing.nextWidth,
					});

					myDrawing.tempShape.draw(context);
					break;
				}

				case ShapeName.Ellipse: {
					myDrawing.tempShape = circleFactory({
						startX: myDrawing.currentStartX,
						startY: myDrawing.currentStartY,
						x: x,
						y: y,
						color: myDrawing.nextColor,
						width: myDrawing.nextWidth,
					});

					myDrawing.tempShape.draw(context);
					break;
				}

				case ShapeName.Pen: {
					if (myDrawing.tempShape) {
						if (myDrawing.tempShape.name === ShapeName.Pen) {
							myDrawing.tempShape.addCoordinate({ x, y });
						}

						myDrawing.tempShape.draw(context);
					}
					break;
				}
			}
		}
	}

	function handleMouseUp() {
		myDrawing.isDrawing = false;

		if (myDrawing.tempShape !== undefined) {
			myDrawing.allShapes.push(myDrawing.tempShape);
		}

		myDrawing.movingShape = undefined;
	}

	function handleTextInput(event: KeyboardEvent) {
		const target = event.target as HTMLInputElement;

		if (event.key == "Enter") {
			const text = target.value;
			const textShape = textFactory({
				startX: myDrawing.currentStartX,
				startY: myDrawing.currentStartY + myDrawing.nextFontSize / 2,
				x: context.measureText(text).width,
				y: 0,
				color: myDrawing.nextColor,
				fontSize: myDrawing.nextFontSize,
				text: target.value,
				width: context.measureText(text).width,
			});

			textShape.draw(context);
			myDrawing.allShapes.push(textShape);

			target.value = "";
			hideTextInput();

			myDrawing.tempShape = undefined;
		}
	}

	function setActiveTool(toolName: ShapeName) {
		toolInputs.map((input) => {
			const state =
				input.value === toolName ? ButtonState.Active : ButtonState.Initial;

			(input.parentNode as HTMLLabelElement).setAttribute(
				"data-css-state",
				state,
			);
		});
	}

	function handleChooseTool(event: Event) {
		const toolEl = event.currentTarget as HTMLInputElement;

		toolInputs.map((input) => {
			const state = input === toolEl ? ButtonState.Active : ButtonState.Initial;

			input.checked = state === ButtonState.Active;
		});

		setActiveTool(toolEl.value as ShapeName);

		if (toolEl.value !== ShapeName.Text) {
			hideTextInput();
		}
	}

	function setActiveColorButton(color: string) {
		colorButtons.map((el) => {
			const state =
				el.value === color ? ButtonState.Active : ButtonState.Initial;

			el.setAttribute("data-css-state", state);
		});
	}

	function handleSelectColor(event: Event) {
		const buttonEl = event.target as HTMLButtonElement;
		const color = buttonEl.value;

		if (!colorInput) {
			console.warn("no color picker element found");

			return;
		}

		const changeEvent = new CustomEvent<{ keyCode: number }>("change", {
			detail: { keyCode: 13 },
		});

		colorInput.value = color;

		setActiveColorButton(color);

		colorInput.dispatchEvent(changeEvent);
	}

	function hideTextInput() {
		textInput.style.left = "";
		textInput.style.top = "";
		textInput.blur();
	}

	function showTextInput({ x, y }: { x: number; y: number }) {
		textInput.style.visibility = "visible";
		textInput.style.left = `${x}px`;
		textInput.style.top = `${y}px`;
	}

	const undoRedo = {
		undoneItems: [],
		popItem: () => {
			// @ts-ignore
			undoRedo.undoneItems.push(myDrawing.allShapes.pop());
			myDrawing.drawAllShapes(context);
		},
		undoItem: () => {
			myDrawing.allShapes.push(undoRedo.undoneItems[0]);
			undoRedo.undoneItems.splice(0, 1);
			myDrawing.drawAllShapes(context);
		},
		resetAll: () => {
			undoRedo.undoneItems = [];
		},
	};

	function clearDrawings() {
		undoRedo.resetAll();
		myDrawing.clearAllShapes();
		myDrawing.drawAllShapes(context);
	}

	function handleChangeColor(event: Event) {
		const target = event.target as HTMLInputElement;

		myDrawing.nextColor = target.value;
	}

	function init() {
		if (
			canvasEl.getAttribute(HTML_STATE_ATTRIBUTE) ===
			DrawingCanvasState.Initialized
		) {
			return;
		}

		context = canvasEl.getContext("2d") as CanvasRenderingContext2D;

		window.addEventListener("resize", handleResize);

		textInput.addEventListener("keyup", handleTextInput);

		colorButtons.map((button) => {
			button.addEventListener("click", handleSelectColor);
		});

		toolInputs.map((input) => {
			input.addEventListener("input", handleChooseTool);
		});

		canvasEl.addEventListener("touchstart", handleTouchStart, false);
		canvasEl.addEventListener("touchend", handleTouchEnd, false);
		canvasEl.addEventListener("touchmove", handleTouchMove, false);

		canvasEl.addEventListener("touchstart", preventCanvasScroll, false);
		canvasEl.addEventListener("touchend", preventCanvasScroll, false);
		canvasEl.addEventListener("touchmove", preventCanvasScroll, false);

		canvasEl.addEventListener("mousemove", handleMouseMove);
		canvasEl.addEventListener("mousedown", handleMouseDown);
		canvasEl.addEventListener("mouseup", handleMouseUp);

		if (fontSizeInput) {
			fontSizeInput.addEventListener("input", handleUpdateFontSize);
		}

		if (undoButton) {
			undoButton.addEventListener("click", undoRedo.popItem);
		}
		if (redoButton) {
			redoButton.addEventListener("click", undoRedo.undoItem);
		}
		if (clearButton) {
			clearButton.addEventListener("click", clearDrawings);
		}

		if (colorInput) {
			colorInput.addEventListener("change", handleChangeColor);
		}

		canvasEl.setAttribute(HTML_STATE_ATTRIBUTE, DrawingCanvasState.Initialized);

		setActiveColorButton(myDrawing.nextColor);
		setActiveTool(myDrawing.nextObject);
		setCanvasDimensions();

		myDrawing.drawAllShapes(context);

		canvasEl.setAttribute(HTML_STATE_ATTRIBUTE, DrawingCanvasState.Initialized);
	}

	function destroy() {
		textInput.removeEventListener("keyup", handleTextInput);

		window.addEventListener("resize", handleResize);

		colorButtons.map((button) => {
			button.removeEventListener("click", handleSelectColor);
		});

		toolInputs.map((input) => {
			input.removeEventListener("input", handleChooseTool);
		});

		canvasEl.removeEventListener("touchstart", handleTouchStart);
		canvasEl.removeEventListener("touchend", handleTouchEnd);
		canvasEl.removeEventListener("touchmove", handleTouchMove);

		canvasEl.removeEventListener("touchstart", preventCanvasScroll);
		canvasEl.removeEventListener("touchend", preventCanvasScroll);
		canvasEl.removeEventListener("touchmove", preventCanvasScroll);

		canvasEl.removeEventListener("mousemove", handleMouseMove);
		canvasEl.removeEventListener("mousedown", handleMouseDown);
		canvasEl.removeEventListener("mouseup", handleMouseUp);

		if (fontSizeInput) {
			fontSizeInput.removeEventListener("input", handleUpdateFontSize);
		}

		if (undoButton) {
			undoButton.removeEventListener("click", undoRedo.popItem);
		}
		if (redoButton) {
			redoButton.removeEventListener("click", undoRedo.undoItem);
		}
		if (clearButton) {
			clearButton.removeEventListener("click", clearDrawings);
		}

		if (colorInput) {
			colorInput.removeEventListener("change", handleChangeColor);
		}

		canvasEl.removeAttribute(HTML_STATE_ATTRIBUTE);
	}

	return {
		init,
		destroy,
	};
}

export { drawingCanvasFactory };
