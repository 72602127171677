export enum PriceType {
	Dynamic = "dynamic",
	Fixed = "fixed",
}

export enum SubmissionState {
	Default = "default",
	MarkedForDeletionByParent = "marked-for-deletion-by-parent",
	MarkedForDeletionBySelf = "marked-for-deletion-by-self",
}

export enum InvoiceSetDateType {
	Cardinal = "cardinal",
	Ordinal = "ordinal",
}

export enum InvoiceSetDatePeriod {
	Day = "day",
	Week = "week",
	Month = "month",
	Year = "year",
}

export enum InvoiceGeneratorEventName {
	CalculateDueDates = "invoice-generator-calculate-due-dates",
	CalculateDueDatesSuccess = "invoice-generator-calculate-due-dates-success",
	IoRxChangeRefDate = "io-invoice-generator-receive-field-change-ref-date",
	IoRxChangeTotal = "io-invoice-generator-receive-field-change-total",
	IoRxInvoiceSetInit = "io-invoice-generator-receive-invoice-set-init",
	IoRxInvoiceSetReorder = "io-invoice-generator-receive-invoice-set-reorder",
	IoTxNumInvoiceSetsChange = "io-invoice-generator-send-num-invoice-sets-change",
	IoTxStateChange = "io-invoice-generator-send-state-change",
	RxInvoiceSetUpdate = "invoice-generator-receive-invoice-set-update",
	TxNotifyInvoiceSets = "invoice-generator-send-notify-invoice-sets",
}

export enum InvoiceSetEventName {
	IoRxChangeNumInvoices = "io-invoice-set-receive-field-change-num-invoices",
	IoRxDateFieldChange = "io-invoice-set-receive-field-change-date",
	IoRxLineItemInit = "io-invoice-set-receive-add-line-item",
	IoRxInvoiceSetDeleteClick = "io-invoice-set-receive-delete-click",
	IoRxInvoiceSetMove = "io-invoice-set-receive-moved",
	IoTxDueDatesChange = "io-invoice-set-send-due-dates-change",
	IoTxNumLineItemsChange = "io-invoice-set-send-num-line-items-change",
	IoTxOrderChange = "io-invoice-set-send-order-change",
	IoTxSubmissionStateChange = "io-invoice-set-send-submission-state-change",
	IoTxTotalChange = "io-invoice-set-send-total-change",
	RxDueDatesChange = "invoice-set-receive-due-dates-change",
	RxLineItemUpdate = "invoice-set-receive-line-item-update",
	RxOrderChange = "invoice-set-receive-order-change",
	RxTotalChange = "invoice-set-receive-total-change",
	TxNotifyLineItems = "invoice-set-send-line-items-notification",
}

export enum LineItemEventName {
	IoRxChangePrice = "io-line-item-receive-field-change-price",
	IoRxChangePriceType = "io-line-item-receive-field-change-price-type",
	IoRxChangeQuantity = "io-line-item-receive-field-change-quantity",
	IoRxToggleDelete = "io-line-item-receive-toggle-delete",
	IoTxDeletableStateChange = "io-line-item-send-deletable",
	IoTxPriceStateChange = "io-line-item-send-price-state-change",
	IoTxSubmissionStateChange = "io-line-item-send-submission-state-change",
	IoTxValues = "io-line-item-send-value-change",
	RxParentDelete = "line-item-receive-parent-delete",
	RxParentRestore = "line-item-receive-parent-restore",
	RxSetTotal = "line-item-receive-total",
}
