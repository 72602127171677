<script lang="ts">
  import {TabItem} from '$applib/components/tabbed-content';

  export let id: string;
</script>

<TabItem {id} let:activateTab let:state>
  <button
    class="button-tabs__navigation__item"
    on:click={activateTab}
    on:click
    data-css-tab-state={state}
  >
    <slot />
  </button>
</TabItem>
