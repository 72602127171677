import { config } from "$applib/configs/application";
import { CustomDomEvent } from "$applib/enums/events";
import { buildHeaders, getCsrfTokenHeader } from "$applib/utils/headers";
import { snakeCaseKeys } from "$applib/utils/objects";
import { parameterizePath } from "$applib/utils/resources/urls";

import type { AppointmentTimeSlot } from "$applib/types/resources/appointments";

import {
	getTimeSlotId,
	renderTimeSlot,
} from "../shared/utils/appointment-grid";

const RENAME_RESERVED_APPOINTMENT_CLASS = "js-appointment-grid-rename-reserved";

const { urls } = config;
const { path: reservedAppointmentsDetailEndpoint } =
	urls.api.appointments.reservedDetail;

function renameReservedAppointment(buttonEl: HTMLButtonElement) {
	const buttonDataset = buttonEl.dataset;
	const inputEl = document.querySelector<HTMLInputElement>(
		buttonDataset.target || "",
	) as HTMLInputElement;

	// TODO: LB - updating_reserve_time_slot_name - to be migrated to reserve timeslot model
	const body = { reserve_time_slot_name: inputEl.value };
	const url = parameterizePath(
		reservedAppointmentsDetailEndpoint,
		snakeCaseKeys({ appointmentId: buttonDataset.appointmentId }),
	);
	fetch(url, {
		method: "PATCH",
		headers: buildHeaders({
			...getCsrfTokenHeader(),
			"Content-Type": "application/json",
			Accept: "application/json",
		}),
		body: JSON.stringify(body),
	})
		.then((response) => {
			if (response.ok) {
				return response.json();
			} else {
				// TODO: LB - properly handle this error
				throw response;
			}
		})
		.then((response) => {
			const timeSlot: AppointmentTimeSlot = response;
			const timeSlotId = getTimeSlotId(timeSlot);
			const timeSlotEl = document.getElementById(timeSlotId);

			if (timeSlotEl) {
				timeSlotEl.outerHTML = renderTimeSlot(timeSlot);

				const event = new CustomEvent(
					CustomDomEvent.AppointmentGridAppointmentUpdated,
				);
				document.dispatchEvent(event);
			}
		})
		.catch((response) => {
			console.error(response);
		});
}

function initRenameReservedAppointments() {
	document.body.addEventListener("click", (event: MouseEvent) => {
		const target = event.target as HTMLElement;
		const isElement = target.classList.contains(
			RENAME_RESERVED_APPOINTMENT_CLASS,
		);
		const parentEl = target.closest(`.${RENAME_RESERVED_APPOINTMENT_CLASS}`);
		const buttonEl = isElement ? target : parentEl;

		if (buttonEl) {
			event.preventDefault();

			renameReservedAppointment(buttonEl as HTMLButtonElement);
		}
	});
}

export { initRenameReservedAppointments };
