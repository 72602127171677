import { fromCallback, fromPromise, sendTo, setup } from "xstate";
import { assign, log, raise } from "xstate/actions";

import type { ActorRefFrom, MachineContext, StateValueFrom } from "xstate";

import {
	InvoiceGeneratorEventName as EventName,
	InvoiceSetEventName,
} from "../enums";

import { calculatorFactory } from "../utils";

import type { DueDatesResults } from "../types";

import type { InvoiceSetStateMachine } from "./invoice-sets";

interface InvoiceSetItem {
	ref: ActorRefFrom<InvoiceSetStateMachine>;
}

interface InvoiceGeneratorMachineContext extends MachineContext {
	invoiceSets: InvoiceSetItem[];
	refDate: Date;
	total: number;
}

export type InvoiceGeneratorMachine = typeof invoiceGeneratorMachine;
export type InvoiceGeneratorCallbackLogic = ReturnType<typeof fromCallback>;
export type InvoiceGeneratorPromiseLogic = ReturnType<typeof fromPromise>;

function lineItemFilterCreator(state: StateValueFrom<InvoiceSetStateMachine>) {
	return function filterLineItems({
		ref,
	}: { ref: ActorRefFrom<InvoiceSetStateMachine> }): boolean {
		return ref.getSnapshot().matches(state);
	};
}

function getInvoiceSetsTotal(invoiceSets: InvoiceSetItem[]): number {
	let total = 0;

	for (const { ref } of invoiceSets) {
		const invoiceSetTotal = calculatorFactory(ref).getTotal(
			lineItemFilterCreator("active"),
		);

		total += invoiceSetTotal;
	}

	return total;
}

const invoiceGeneratorMachine = setup({
	types: {} as {
		actors: {
			ioEventDispatcher: InvoiceGeneratorCallbackLogic;
			dueDatesFetcher: InvoiceGeneratorCallbackLogic;
		};
		context: InvoiceGeneratorMachineContext;
		events:
			| { type: EventName.CalculateDueDates }
			| {
					type: EventName.CalculateDueDatesSuccess;
					params: { dueDatesResults: DueDatesResults };
			  }
			| { type: EventName.IoRxChangeRefDate; params: { date: Date } }
			| { type: EventName.IoRxChangeTotal; params: { total: number } }
			| { type: EventName.IoRxInvoiceSetInit; params: { element: HTMLElement } }
			| {
					type: EventName.IoRxInvoiceSetReorder;
					params: {
						ordersByRefId: Record<InvoiceSetItem["ref"]["id"], number>;
					};
			  }
			| {
					type: EventName.IoTxNumInvoiceSetsChange;
					params: { numInvoiceSets: number };
			  }
			| { type: EventName.IoTxStateChange; params: { state: string } }
			| { type: EventName.RxInvoiceSetUpdate }
			| { type: EventName.TxNotifyInvoiceSets };
		input: Partial<Pick<InvoiceGeneratorMachineContext, "refDate" | "total">>;
	},
	actions: {
		log: log((x) => x.self, "invoice-generator"),

		setTotal: assign({ total: (_, params: { total: number }) => params.total }),
		setRefDate: assign({
			refDate: (_, params: { date: Date }) => params.date,
		}),

		spawnInvoiceSet: (_, _params: { element: HTMLElement }) => {
			throw new Error("Not implemented");
		},

		scheduleNotifyInvoiceSets: raise({ type: EventName.TxNotifyInvoiceSets }),

		sendTotalsToInvoiceSets: ({
			context: { invoiceSets, total: invoiceGenTotal },
		}) => {
			const dynamicItems = invoiceSets.filter(({ ref }) =>
				ref.getSnapshot().matches({ active: "dynamic" }),
			);
			const inactiveItems = invoiceSets.filter(({ ref }) =>
				ref.getSnapshot().matches("markedForDeletion"),
			);
			const fixedItems = invoiceSets.filter(({ ref }) =>
				ref.getSnapshot().matches({ active: "fixed" }),
			);
			const fixedItemsTotal = getInvoiceSetsTotal(fixedItems);
			let unallocated = invoiceGenTotal - fixedItemsTotal;

			for (let i = 0; i < dynamicItems.length; i++) {
				const { ref } = dynamicItems[i];
				const invoiceSetTotal = unallocated / (dynamicItems.length - i);
				unallocated = unallocated - invoiceSetTotal;

				ref.send({
					type: InvoiceSetEventName.RxTotalChange,
					params: { invoiceSetTotal },
				});
			}

			for (const { ref } of inactiveItems) {
				ref.send({
					type: InvoiceSetEventName.RxTotalChange,
					params: { invoiceSetTotal: 0 },
				});
			}
		},

		sendDueDatesToInvoiceSets: (
			{ context: { invoiceSets } },
			params: { dueDatesResults: DueDatesResults },
		) => {
			const invoiceSetsById = Object.fromEntries(
				invoiceSets.map(({ ref }) => [ref.id, ref]),
			);

			for (const [refId, dueDatesResults] of Object.entries(
				params.dueDatesResults,
			)) {
				const ref = invoiceSetsById[refId];

				if (ref) {
					ref.send({
						type: InvoiceSetEventName.RxDueDatesChange,
						params: dueDatesResults,
					});
				}
			}
		},

		sendOrdersToInvoiceSets: (
			{ context },
			params: { ordersByRefId: Record<string, number> },
		) => {
			const { ordersByRefId } = params;
			const { invoiceSets } = context;

			for (const { ref } of invoiceSets) {
				const order = ordersByRefId[ref.id];

				ref.send({
					type: InvoiceSetEventName.RxOrderChange,
					params: { order },
				});
			}
		},

		fetchDueDates: sendTo("dueDatesFetcher", {
			type: EventName.CalculateDueDates,
		}),

		emitNumInvoiceSets: sendTo(
			"ioEventDispatcher",
			({
				context: { invoiceSets },
			}: { context: InvoiceGeneratorMachineContext }) => ({
				type: EventName.IoTxNumInvoiceSetsChange,
				params: { numInvoiceSets: invoiceSets.length },
			}),
		),

		emitStateChange: sendTo(
			"ioEventDispatcher",
			(
				_,
				params: { state: "allocated" | "overallocated" | "underallocated" },
			) => ({
				type: EventName.IoTxStateChange,
				params: { state: params.state },
			}),
		),
	},

	guards: {
		isOverallocated: ({ context: { invoiceSets, total } }) => {
			const numDynamic = invoiceSets.filter(({ ref }) =>
				ref.getSnapshot().matches({ active: "dynamic" }),
			).length;
			const fixedItems = invoiceSets.filter(({ ref }) =>
				ref.getSnapshot().matches({ active: "fixed" }),
			);
			const fixedItemsTotal = getInvoiceSetsTotal(fixedItems);
			const failureConditions = [
				numDynamic === 0,
				fixedItems.length > 0,
				fixedItemsTotal > total,
			];

			return failureConditions.every(Boolean);
		},
		isUnderallocated: ({ context: { invoiceSets, total } }) => {
			const numDynamic = invoiceSets.filter(({ ref }) =>
				ref.getSnapshot().matches({ active: "dynamic" }),
			).length;
			const fixedItems = invoiceSets.filter(({ ref }) =>
				ref.getSnapshot().matches({ active: "fixed" }),
			);
			const fixedItemsTotal = getInvoiceSetsTotal(fixedItems);
			const failureConditions = [
				numDynamic === 0,
				fixedItems.length > 0,
				fixedItemsTotal < total,
			];

			return failureConditions.every(Boolean);
		},
	},

	actors: {
		ioEventDispatcher: fromCallback(() => {
			throw new Error("Not implemented");
		}),
		dueDatesFetcher: fromPromise(() => {
			throw new Error("Not implemented");
		}),
	},
}).createMachine({
	id: "invoice-generator",
	initial: "evaluating",
	context: ({ input }) => ({
		invoiceSets: [],
		total: 0,
		refDate: new Date(),
		...input,
	}),
	invoke: [
		{ id: "ioEventDispatcher", src: "ioEventDispatcher" },
		{ id: "dueDatesFetcher", src: "dueDatesFetcher" },
	],

	on: {
		[EventName.CalculateDueDates]: { actions: [{ type: "fetchDueDates" }] },
		[EventName.CalculateDueDatesSuccess]: {
			actions: [
				{
					type: "sendDueDatesToInvoiceSets",
					params: ({ event: { params } }) => ({
						dueDatesResults: params.dueDatesResults,
					}),
				},
			],
		},
		[EventName.TxNotifyInvoiceSets]: {
			actions: [{ type: "sendTotalsToInvoiceSets" }],
			target: ".evaluating",
		},
		[EventName.IoRxChangeRefDate]: {
			actions: [
				{
					type: "setRefDate",
					params: ({ event }) => ({ date: event.params.date }),
				},
				{ type: "scheduleNotifyInvoiceSets" },
				{ type: "fetchDueDates" },
			],
		},
		[EventName.IoRxChangeTotal]: {
			actions: [
				{
					type: "setTotal",
					params: ({ event }) => ({ total: event.params.total }),
				},
				{ type: "scheduleNotifyInvoiceSets" },
			],
			target: ".evaluating",
		},
		[EventName.IoRxInvoiceSetInit]: {
			actions: [
				{
					type: "spawnInvoiceSet",
					params: ({ event: { params } }) => ({ element: params.element }),
				},
				{ type: "emitNumInvoiceSets" },
				{ type: "fetchDueDates" },
			],
			target: ".evaluating",
		},
		[EventName.IoRxInvoiceSetReorder]: {
			actions: [
				{
					type: "sendOrdersToInvoiceSets",
					params: ({ event: { params } }) => ({
						ordersByRefId: params.ordersByRefId,
					}),
				},
				{ type: "fetchDueDates" },
			],
		},
		[EventName.RxInvoiceSetUpdate]: {
			actions: [{ type: "scheduleNotifyInvoiceSets" }],
			target: ".evaluating",
		},
	},

	states: {
		evaluating: {
			always: [
				{ target: "overallocated", guard: "isOverallocated" },
				{ target: "underallocated", guard: "isUnderallocated" },
				{ target: "allocated" },
			],
		},

		allocated: {
			entry: [{ type: "emitStateChange", params: { state: "allocated" } }],
		},
		overallocated: {
			entry: [{ type: "emitStateChange", params: { state: "overallocated" } }],
		},
		underallocated: {
			entry: [{ type: "emitStateChange", params: { state: "underallocated" } }],
		},
	},
});

export { invoiceGeneratorMachine };
