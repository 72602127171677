import type {
	MakeRequestFn,
	RequestHelpers,
	RequestOptions,
	ResourceConfig,
} from "$applib/types/request-response";

import { buildHeaders, getCsrfTokenHeader } from "$applib/utils/headers";
import { jsonFetchProvider } from "./providers";
import { parameterizePath } from "./urls";

function requestFactory<
	TPayload extends object = object,
	TEntity extends object = object,
>({
	baseUrl,
	endpoint,
	provider,
}: ResourceConfig<TEntity>): MakeRequestFn<TPayload, TEntity> {
	function doRequest(requestOptions: RequestOptions<TPayload> = {}) {
		const { body, parameters, query, requestConfig = {} } = requestOptions;
		const url = [baseUrl, parameterizePath(endpoint, parameters, query)].join(
			"",
		);
		const requestInit = { ...requestConfig };

		if (body) {
			requestInit.body = typeof body === "string" ? body : JSON.stringify(body);
		}

		return provider({ requestInit, url });
	}

	return doRequest;
}

const defaultHeaders = buildHeaders({
	...getCsrfTokenHeader(),
	"Content-Type": "application/json",
});

function getRequestOptions<T extends object>(
	options: RequestOptions<T>,
	method: string,
) {
	const requestOptions = Object.assign(
		{},
		{ requestConfig: { headers: defaultHeaders } },
		options,
	);
	requestOptions.requestConfig.method = method;

	return requestOptions;
}

function createResource<
	TPayload extends object = object,
	TResponse extends object = object,
>(
	userResourceConfig: Partial<ResourceConfig<TResponse>>,
): RequestHelpers<TPayload, TResponse> {
	const resourceConfig = {
		baseUrl: "",
		endpoint: "/",
		provider: jsonFetchProvider,
		...userResourceConfig,
	};
	const doRequest = requestFactory<TPayload, TResponse>(resourceConfig);
	const requestHelpers = {
		request(requestOptions: RequestOptions<TPayload> = {}) {
			return doRequest(requestOptions);
		},

		delete(requestOptions: RequestOptions<TPayload> = {}) {
			const options = getRequestOptions(requestOptions, "DELETE");

			return doRequest(options);
		},

		get(requestOptions: RequestOptions<TPayload> = {}) {
			const options = getRequestOptions(requestOptions, "GET");

			return doRequest(options);
		},

		head(requestOptions: RequestOptions<TPayload> = {}) {
			const options = getRequestOptions(requestOptions, "HEAD");

			return doRequest(options);
		},

		patch(requestOptions: RequestOptions<TPayload> = {}) {
			const options = getRequestOptions(requestOptions, "PATCH");

			return doRequest(options);
		},

		post(requestOptions: RequestOptions<TPayload> = {}) {
			const options = getRequestOptions(requestOptions, "POST");

			return doRequest(options);
		},

		put(requestOptions: RequestOptions<TPayload> = {}) {
			const options = getRequestOptions(requestOptions, "PUT");

			return doRequest(options);
		},
	};

	return requestHelpers;
}

export { createResource };
