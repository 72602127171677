<script lang="ts">
  import {flip} from 'svelte/animate';
  import {fly} from 'svelte/transition';

  import {Alert, AlertType, AlertModifier} from '$applib/components/alert';

  import type {ApiError} from '$applib/types/errors';

  export let errorMap: ApiError['messages'] = {};
  export let errors: string[] = [];

  let SLOTS = $$props['$$slots'] || {};

  $: errorMessages =
    errors.length > 0
      ? errors.map((error, i) => ({id: i, message: error}))
      : Object.entries(errorMap)
          // TODO: LB - determine where it's possible that an object could come
          // through here and:
          //  - fix this statement to properly handle objects, or
          //  - normalise API errors server-side to ensure we only ever get
          //    a Record<string, string[]> object
          .map(([key, value]) =>
            typeof value === 'object' ? [key, [value]] : [key, value],
          )
          .map(([key, value]) => ({id: key, message: value}))
          .filter(({message}) => Boolean(message));
</script>

{#if errorMessages.length || SLOTS['default']}
  <div class="u-margin--largest--bottom" in:fly={{y: -10}}>
    <Alert type={AlertType.Error} modifiers={[AlertModifier.Small]}>
      <slot />

      {#each errorMessages as { id, message } (id)}
        <div transition:fly|local={{y: -10}} animate:flip>
          <label class="u-margin--none--block-end" for={`${id}`}
            >{message}</label
          >
        </div>
      {/each}
    </Alert>
  </div>
{/if}
