<script lang="ts">
  import {onMount} from 'svelte';

  import {useMetadata} from '$applib/stores/metadata';
  import type {Metadata, MetadataType} from '$applib/types/resources/metadata';
  import {_} from '$applib/i18n';

  import FieldMessage from './field-message.svelte';
  import {FieldMessageState} from './enums';

  type Entity = Metadata[typeof resourceType][number];

  // biome-ignore lint/style/useConst: 'value' can be passed as prop
  export let value = '';
  export let resourceType: MetadataType;
  // biome-ignore lint/style/useConst: 'required' can be passed as prop
  export let required = true;
  export let id: string;
  // biome-ignore lint/style/useConst: 'nameSingular' can be passed as prop
  export let nameSingular = '';
  // biome-ignore lint/style/useConst: 'namePlural' can be passed as prop
  export let namePlural = '';
  // biome-ignore lint/style/useConst: 'selectText' can be passed as prop
  export let selectText = '';
  // biome-ignore lint/style/useConst: 'renderKey' can be passed as prop
  export let renderKey: keyof Entity = 'name';

  const {fetchMetadata, getMetadataResource, snapshot: state} = useMetadata();

  $: context = $state.context;
  $: items = getMetadataResource(resourceType, context) || [];

  onMount(() => {
    if (!$state.matches({get: 'success'})) {
      fetchMetadata();
    }
  });
</script>

<select
  {id}
  name={nameSingular || id}
  on:change
  on:blur
  on:focus
  on:input
  bind:value
  {...$$restProps}
>
  {#if $state.hasTag('requesting')}
    <option value={undefined} disabled selected>
      {$_('components.forms.fields.selectMetadataResource.loadingText', {
        values: {name: namePlural},
      })}
    </option>
  {/if}

  {#if $state.hasTag('success')}
    <option value={undefined} disabled selected={!value}>
      {selectText ||
        $_('components.forms.fields.selectMetadataResource.selectText')}
    </option>

    {#if !required}
      <option value={undefined} selected={!value} />
    {/if}

    {#each items as item}
      <option value={item.id} selected={`${id}` === `${item.id}`}>
        {item[renderKey]}
      </option>
    {/each}
  {/if}
</select>

{#if $state.hasTag('error') || (items.length === 0 && !$state.matches( {get: 'requesting'}, ))}
  <FieldMessage state={FieldMessageState.Error}>
    {$_('components.forms.fields.selectMetadataResource.errorText', {
      values: {name: namePlural},
    })}
    <button
      type="button"
      on:click={fetchMetadata}
      class="u-btn--as-link u-fc--primary"
    >
      {$_('components.forms.fields.selectMetadataResource.errorLinkText')}
    </button>
  </FieldMessage>
{/if}
