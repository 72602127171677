<script lang="ts">
  import {
    DisplayLevel,
    GridCol,
    GridColModifier,
    GridWrap,
    GridWrapModifier,
  } from '$applib/components/grid';

  import CustomCheckRadioIcon from './icon.svelte';

  export let id: string | number;
  export let label: string;
  export let type = 'checkbox';
</script>

<label class="custom-check-radio-label" for={`${id}`}>
  {#if label}
    <GridWrap
      modifiers={[GridWrapModifier.NoGutter]}
      displayLevel={DisplayLevel.InlineBlock}
    >
      <GridCol
        modifiers={[GridColModifier.ShrinkWrap]}
        displayLevel={DisplayLevel.InlineBlock}
      >
        <CustomCheckRadioIcon {type} />
        {@html '&nbsp;'}
      </GridCol>

      <GridCol
        modifiers={[GridColModifier.Auto]}
        displayLevel={DisplayLevel.InlineBlock}
      >
        {label}
      </GridCol>
    </GridWrap>
  {:else}
    <CustomCheckRadioIcon {type} />
  {/if}
</label>
