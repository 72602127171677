export enum ShapeName {
	Rectangle = "rect",
	Line = "line",
	Ellipse = "circle",
	Pen = "pen",
	Text = "text",

	// TODO: LB - this is not a shape - use a separate enum for this value
	Move = "move",
}

interface Line {
	x1: number;
	y1: number;
	x2: number;
	y2: number;
}

interface Shape {
	startX: number;
	startY: number;
	x: number;
	y: number;
	color: string;
	width: number;
	name: ShapeName;
}

interface Ellipse extends Shape {
	radiusX: number;
	radiusY: number;
}

interface Text extends Shape {
	text: string;
	font: string;
	fontSize: number;
}

interface Coord {
	x: number;
	y: number;
}

interface Pen extends Shape {
	coords: Coord[];
}

type ShapeInit = Omit<Shape, "name">;
type EllipseInit = Omit<ShapeInit, "radiusX" | "radiusY">;
type PenInit = Omit<Pen, "name" | "coords">;
type TextInit = Omit<Text, "name">;

export interface ShapeTool {
	findMe: (x: number, y: number) => boolean;
	draw: (context: CanvasRenderingContext2D) => void;
	name: Shape["name"];
	addCoordinate(coordinate: Coord): void;
}

type ShapeToolFactory<TArg = ShapeInit, TReturn = ShapeTool> = (
	shape: Partial<TArg>,
) => TReturn;

const shapeDefaults: ShapeInit = {
	startX: 0,
	startY: 0,
	x: 0,
	y: 0,
	color: "#000000",
	width: 0,
};
const circleDefaults: Ellipse = {
	...shapeDefaults,
	name: ShapeName.Ellipse,
	radiusX: 0,
	radiusY: 0,
};
const penDefaults: Pen = {
	...shapeDefaults,
	name: ShapeName.Pen,
	coords: [],
};
const textDefaults: Text = {
	...shapeDefaults,
	name: ShapeName.Text,
	font: 'system-ui, "Segoe UI", Roboto, Helvetica, Arial',
	fontSize: 18,
	text: "",
};

const isInRectangle: (shape: Shape) => ShapeTool["findMe"] =
	function isInRectangle(shape) {
		return (x, y) => {
			const xIsGteStartX = x >= shape.startX;
			const xMax = shape.startX + shape.x;
			const yMax = shape.startY + shape.y;
			const yIsGteStartY = y >= shape.startY;
			const xFound = xIsGteStartX ? x <= xMax : x >= xMax;
			const yFound = yIsGteStartY ? y <= yMax : y >= yMax;

			return Boolean(xFound && yFound);
		};
	};

const rectangleFactory: ShapeToolFactory = function rectangleFactory(
	userShape,
) {
	const shape: Shape = {
		...shapeDefaults,
		...userShape,
		name: ShapeName.Rectangle,
	};

	const findMe: ShapeTool["findMe"] = isInRectangle(shape);

	const draw: ShapeTool["draw"] = function draw(context) {
		context.beginPath();
		context.lineWidth = shape.width;
		context.strokeStyle = shape.color;
		context.strokeRect(shape.startX, shape.startY, shape.x, shape.y);
		context.closePath();
		context.stroke();
	};

	const addCoordinate: ShapeTool["addCoordinate"] = function addCoordinate(_) {
		//
	};

	return {
		addCoordinate,
		findMe,
		draw,
		name: shape.name,
	};
};

const lineFactory: ShapeToolFactory = function lineFactory(userShape) {
	const shape: Shape = {
		...shapeDefaults,
		...userShape,
		name: ShapeName.Line,
	};

	const findMe: ShapeTool["findMe"] = function findMe(x, y) {
		const x1 = shape.startX <= shape.x ? shape.startX : shape.x;
		const x2 = shape.startX <= shape.x ? shape.x : shape.startX;
		const y1 = shape.startX <= shape.x ? shape.startY : shape.y;
		const y2 = shape.startX <= shape.x ? shape.y : shape.startY;

		const slope = _findSlope({ x1, y1, x2, y2 });
		const targetSlope = _findSlope({ x1, y1, x2: x, y2: y });

		if (x < x2 && x > x1) {
			if (Math.abs(slope - targetSlope) < 0.1) {
				return true;
			}
		}

		return false;
	};

	function _findSlope(line: Line) {
		const { x1, y1, x2, y2 } = line;
		const [numerator, denominator] = [y2 - y1, x2 - x1];

		return denominator === 0
			? Number.POSITIVE_INFINITY
			: numerator / denominator;
	}

	const draw: ShapeTool["draw"] = function draw(context) {
		context.beginPath();
		context.lineWidth = shape.width;
		context.strokeStyle = shape.color;
		context.moveTo(shape.startX, shape.startY);
		context.lineTo(shape.x, shape.y);
		context.closePath();
		context.stroke();
	};

	const addCoordinate: ShapeTool["addCoordinate"] = function addCoordinate(_) {
		//
	};

	return {
		addCoordinate,
		findMe,
		draw,
		name: shape.name,
	};
};

const circleFactory: ShapeToolFactory<EllipseInit> = function circleFactory(
	userShape,
) {
	const mergedShape = {
		...circleDefaults,
		...userShape,
	};
	const shape: Ellipse = {
		...mergedShape,
		radiusX: (mergedShape.x - mergedShape.startX) / 2,
		radiusY: (mergedShape.y - mergedShape.startY) / 2,
	};

	const findMe: ShapeTool["findMe"] = function findMe(x, y) {
		const { radiusX, radiusY } = shape;
		const { x: centerX, y: centerY } = _getCenter();
		const xs = [];
		const ys = [];
		const step = 0.01;
		const pi2 = Math.PI * 2 - step;
		let a = step;
		let count = 0;

		xs.push(centerX + radiusX * Math.cos(a));
		ys.push(centerY + radiusY * Math.sin(a));

		for (; a < pi2; a += step) {
			xs.push(centerX + radiusX * Math.cos(a)),
				ys.push(centerY + radiusY * Math.sin(a));
		}
		const qt = xs.length / 4;
		const half = xs.length / 2;

		for (let i = 0; i < half; i++) {
			if (xs[i + half] < x && xs[i] > x) {
				if (ys[i + qt] > y && ys[i + half + qt] < y) {
					count++;
				}
			}
		}

		if (count > 60) {
			return true;
		}

		return false;
	};

	const draw: ShapeTool["draw"] = function draw(context) {
		const { radiusX, radiusY } = shape;
		const { x: centerX, y: centerY } = _getCenter();
		const step = 0.01;
		const pi2 = Math.PI * 2 - step;
		let a = step;

		context.beginPath();
		context.lineWidth = shape.width;
		context.strokeStyle = shape.color;
		context.moveTo(
			centerX + radiusX * Math.cos(0),
			centerY + radiusY * Math.sin(0),
		);

		for (; a < pi2; a += step) {
			context.lineTo(
				centerX + radiusX * Math.cos(a),
				centerY + radiusY * Math.sin(a),
			);
		}

		context.closePath();
		context.stroke();
	};

	function _getCenter() {
		const { radiusX, radiusY, startX, startY } = shape;
		const centerX = startX + radiusX;
		const centerY = startY + radiusY;

		return { x: centerX, y: centerY };
	}

	const addCoordinate: ShapeTool["addCoordinate"] = function addCoordinate(_) {
		//
	};

	return {
		addCoordinate,
		findMe,
		draw,
		name: shape.name,
	};
};

const penFactory: ShapeToolFactory<PenInit> = function penFactory(userShape) {
	const shape = {
		...penDefaults,
		...userShape,
		name: ShapeName.Pen,
	};

	const findMe: ShapeTool["findMe"] = function findMe(x, y) {
		const isMatch = shape.coords.some((coord) => {
			const withinX = coord.x < x + 5 && coord.x > x - 5;
			const withinY = coord.y < y + 8 && coord.y > y - 8;

			return Boolean(withinX && withinY);
		});

		return isMatch;
	};

	const draw: ShapeTool["draw"] = function draw(context) {
		for (let j = 1; j < shape.coords.length; j++) {
			context.beginPath();
			context.lineWidth = shape.width;
			context.strokeStyle = shape.color;
			context.moveTo(shape.coords[j - 1].x, shape.coords[j - 1].y);
			context.lineTo(shape.coords[j].x, shape.coords[j].y);
			context.closePath();
			context.stroke();
		}
	};

	const addCoordinate: ShapeTool["addCoordinate"] = function addCoordinate(
		coord,
	) {
		const coordinates = [...shape.coords, coord];

		shape.coords = coordinates;
	};

	return {
		findMe,
		draw,
		addCoordinate,
		name: shape.name,
	};
};

const textFactory: ShapeToolFactory<TextInit> = function textFactory(
	userShape,
) {
	const shape = {
		...textDefaults,
		...userShape,
		name: ShapeName.Text,
	};

	const findMe: ShapeTool["findMe"] = isInRectangle(shape);

	const draw: ShapeTool["draw"] = function draw(context) {
		context.font = `${shape.fontSize}px ${shape.font}`;
		context.fillStyle = shape.color;
		context.fillText(shape.text, shape.startX, shape.startY);
	};

	const setText = function setText(text: string) {
		shape.text = text;
	};

	const addCoordinate: ShapeTool["addCoordinate"] = function addCoordinate(_) {
		//
	};

	return {
		addCoordinate,
		findMe,
		draw,
		setText,
		name: shape.name,
		_shape: shape,
	};
};

export {
	textFactory,
	penFactory,
	circleFactory,
	rectangleFactory,
	lineFactory,
};
