import htmx from "htmx.org";

(async () => {
	// Explicitly set htmx on the window to allow extensions to
	globalThis.htmx = htmx;

	/**
	 * htmx extension for handling 5xx errors and rendering a generic response
	 * to a target
	 *
	 * Usage:
	 *   <div hx-extend="_custom-5xx-target">
	 *      <div class="error-target"></div>
	 *
	 *      <button ... hx-custom-5xx-target=".error-target">
	 *       ...
	 *      </button>
	 *   </div>
	 */
	htmx.defineExtension("_custom-5xx-target", {
		onEvent: (name: string, evt: CustomEvent) => {
			const attributeName = "hx-custom-5xx-target";
			const events = ["htmx:beforeSend", "htmx:responseError"];
			const responseCode = evt.detail.xhr.status || 0;

			if (!events.includes(name)) {
				return;
			}

			const element = evt.detail.elt as HTMLElement;
			const target = evt.detail.target as HTMLElement;
			const errorTarget = htmx.find(
				target,
				element.getAttribute(attributeName) || "",
			);

			if (!errorTarget) {
				return;
			}

			switch (true) {
				case name === "htmx:beforeSend": {
					errorTarget.innerHTML = "";

					break;
				}
				case name === "htmx:responseError" && responseCode >= 500: {
					errorTarget.innerHTML = `
            <div class="alert alert--small alert--danger">
              Something went wrong. We've been alerted to the issue
              and are working on fixing it.
            </div>
          `;

					break;
				}
			}
		},
	});

	await import("htmx.org/dist/ext/response-targets");

	if (import.meta.env.DEV) {
		import("htmx.org/dist/ext/debug");
	}
})();
