import { Appointment } from "../components";

function renderAppointment(button: HTMLButtonElement) {
	const {
		appointmentDate,
		appointmentServiceId,
		appointmentTime,
		staffResourceId,
	} = button.dataset;

	new Appointment({
		target: button,
		props: {
			appointmentDate: appointmentDate || "",
			appointmentServiceId: appointmentServiceId || "0",
			appointmentTime: appointmentTime || "",
			staffResourceId: staffResourceId || "0",
		},
	});
}

export { renderAppointment };
