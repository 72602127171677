<script lang="ts">
  import {createEventDispatcher} from 'svelte';
  import {fly} from 'svelte/transition';

  import {config} from '$applib/configs/application';
  import {getCsrfTokenHeader, buildHeaders} from '$applib/utils/headers';
  import {snakeCaseKeys} from '$applib/utils/objects';
  import {parameterizePath} from '$applib/utils/resources/urls';

  import {Loader} from '$applib/components/loader';
  import {Icon, IconId} from '$applib/components/icon';
  import {CircleButton, CircleButtonModifier} from '$applib/components/button';
  import {
    GridWrap,
    GridCol,
    GridColModifier,
    GridWrapModifier,
  } from '$applib/components/grid';

  import {SelectedPatientState} from './enums';

  // biome-ignore lint/style/useConst: 'patientId' can be passed as prop
  export let patientId: string | number = '';

  const {path: getPatientEndpoint} = config.urls.api.appointments.patientGet;
  const dispatch = createEventDispatcher();

  enum RequestState {
    Idle = 'idle',
    Requesting = 'requesting',
  }

  interface ResponseData {
    block_online_appointment_booking: boolean;
    patient: Record<string, string>;
  }

  let requestState: RequestState = RequestState.Idle;
  let state: SelectedPatientState = SelectedPatientState.Hidden;
  let data: ResponseData;

  $: content = getContent(data);
  $: has_warnings =
    data && [data.block_online_appointment_booking].some(Boolean);
  $: circleButtonModifiers = [
    CircleButtonModifier.CornerTopLeft,
    has_warnings ? CircleButtonModifier.ClrDanger : null,
  ].filter((x): x is CircleButtonModifier => Boolean(x));

  $: if (patientId) {
    getPatient(patientId);
  } else {
    state = SelectedPatientState.Hidden;
  }

  function getContent(data?: ResponseData) {
    return data
      ? `${data.patient.firstnames} ${data.patient.surname} | ${data.patient.birthdate}`
      : '';
  }

  function getPatient(id: string | number) {
    const parameters = snakeCaseKeys({patientId: id});
    const url = parameterizePath(getPatientEndpoint, parameters);

    requestState = RequestState.Requesting;
    state = SelectedPatientState.Visible;

    fetch(url, {
      method: 'GET',
      headers: buildHeaders(getCsrfTokenHeader()),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response) {
          data = response;
          requestState = RequestState.Idle;
        }
      })
      .catch((response) => {
        // TODO: LB - render error
        console.error(response);
        requestState = RequestState.Idle;
      });
  }

  function handleClick() {
    state = SelectedPatientState.Hidden;

    dispatch('patientunselected');
  }
</script>

{#if state === SelectedPatientState.Visible}
  <div class="u-margin--block-end" transition:fly>
    <div>
      <label for="">Patient</label>
    </div>

    {#if requestState === RequestState.Requesting}
      <small>
        <Loader>Getting patient details...</Loader>
      </small>
    {:else}
      <div>
        <GridWrap modifiers={[GridWrapModifier.Small, GridWrapModifier.Middle]}>
          <GridCol modifiers={[GridColModifier.ShrinkWrap]}>
            <CircleButton
              on:click={handleClick}
              modifiers={circleButtonModifiers}
            >
              <span class="u-fs--milli">
                <Icon id={IconId.Cross} />
              </span>
            </CircleButton>
          </GridCol>

          <GridCol modifiers={[GridColModifier.Auto]}>
            <div
              class="
                u-border-radius
                u-bgc--base-ltrst
                u-drop-shadow
                u-padding--smaller--block
                u-padding--small--inline
              "
            >
              {content}
            </div>
          </GridCol>
        </GridWrap>
      </div>
    {/if}
  </div>
{/if}
