import { config } from "$applib/configs/application";
import { buildHeaders, getCsrfTokenHeader } from "$applib/utils/headers";
import { snakeCaseKeys } from "$applib/utils/objects";
import { parameterizePath } from "$applib/utils/resources/urls";

const selector = ".js-charged-state-toggler";
const buttonEls = document.querySelectorAll<HTMLButtonElement>(selector);

const { urls } = config;
const { path: editToggleChargedEndpoint } =
	urls.api.charges.legacyEditToggleCharged;

function toggleChargedState(event: MouseEvent) {
	const currentTarget = event.currentTarget as HTMLButtonElement;
	const chargeEntityid = currentTarget.dataset.chargeEntityId;
	const url = parameterizePath(
		editToggleChargedEndpoint,
		snakeCaseKeys({ chargeId: chargeEntityid }),
	);

	fetch(url, {
		method: "POST",
		headers: buildHeaders({
			...getCsrfTokenHeader(),
			Accept: "application/json",
			"Content-Type": "application/json",
		}),
		body: JSON.stringify({}),
	})
		.then((response) => response.json())
		.then((response) => {
			const chargedEl = currentTarget.querySelector(
				".js-charged-state-toggler-charged",
			) as HTMLElement;
			const notChargedEl = currentTarget.querySelector(
				".js-charged-state-toggler-not-charged",
			) as HTMLElement;
			const displayClass = "u-display--none";

			if (response.instance == "Tick") {
				chargedEl.classList.remove(displayClass);
				notChargedEl.classList.add(displayClass);
			} else {
				chargedEl.classList.add(displayClass);
				notChargedEl.classList.remove(displayClass);
			}
		})
		.catch((response) => {
			// TODO: LB - render message for user
			console.error(response);
		});
}

Array.from(buttonEls).map((buttonEl) => {
	buttonEl.addEventListener("click", toggleChargedState);
});
