import { assertEvent, fromPromise } from "xstate";
import { assign, cancel, enqueueActions, raise } from "xstate/actions";

import type {
	ActionArgs,
	ContextFrom,
	EventFrom,
	MachineImplementationsFrom,
} from "xstate";

import { config } from "$applib/configs/application";
import { snakeCaseKeys } from "$applib/utils/objects";
import { createResource } from "$applib/utils/resources/create-resource";
import { resourceMachineFactory } from "$applib/utils/resources/create-resource-machine";

import type { PatientDiscussion } from "$applib/types/resources/patient-discussions";
import type { PatientPhotos } from "$applib/types/resources/patient-photos";

import {
	type DoneEvent,
	EventType,
} from "$applib/utils/resources/create-resource-machine";

const DELAY_ID = "update-delay";
const { new: newParamPath, edit: editParamPath } = config.urls.api.discussions;
const { post: newDiscussion } = createResource({ endpoint: newParamPath.path });
const { patch: editDiscussion } = createResource({
	endpoint: editParamPath.path,
});

type Implementations = MachineImplementationsFrom<DiscussionStateMachine>;
type DiscussionMachineDoneEvent = DoneEvent<PatientDiscussion>;
type Payload = Partial<{
	patientPhotosId: PatientPhotos["id"];
	discussionId: PatientDiscussion["id"];
	reportNeeded: PatientDiscussion["report_needed"];
}>;

export type DiscussionStateMachine = typeof patientDiscussionMachine;
export type DiscussionMachineContext = ContextFrom<DiscussionStateMachine>;
type DiscussionMachineEvent = EventFrom<DiscussionStateMachine>;
type LocalActionArgs = ActionArgs<
	DiscussionMachineContext,
	DiscussionMachineEvent,
	DiscussionMachineEvent
>;

// TODO: LB - rewrite this using htmx
const patientDiscussionMachine = resourceMachineFactory<
	PatientDiscussion,
	Payload,
	{
		patientPhotosId: PatientPhotos["id"];
		discussionId: PatientDiscussion["id"];
	}
>("patient-discussion").provide({
	actors: {
		post: fromPromise(({ input }) => {
			const { event } = input as LocalActionArgs;
			assertEvent(event, EventType.Post);

			const { params } = event;
			const body = snakeCaseKeys(params);
			const parameters = snakeCaseKeys({
				patientPhotosId: params.patientPhotosId,
			});

			return newDiscussion({ body, parameters });
		}),

		patch: fromPromise(({ input }) => {
			const { context, event } = input as LocalActionArgs;
			assertEvent(event, EventType.Patch);

			const { discussionId } = context;
			const { params } = event;
			const body = snakeCaseKeys(params);
			const parameters = snakeCaseKeys({ discussionId });

			return editDiscussion({ body, parameters });
		}),
	} as Implementations["actors"],

	actions: {
		onSuccess: enqueueActions(({ enqueue, ...rest }) => {
			const event = rest.event as DiscussionMachineDoneEvent;
			const output = event.output as PatientDiscussion;
			const successEventNames = [EventType.PostDone, EventType.PatchDone];

			if (successEventNames.indexOf(event.type as EventType) === -1) {
				return;
			}

			if (event.type === EventType.PostDone) {
				enqueue(assign({ discussionId: output.id }));
			}

			enqueue(cancel(DELAY_ID));
			enqueue(raise({ type: EventType.Reset }, { id: DELAY_ID, delay: 2000 }));
		}),
	},
});

export { patientDiscussionMachine };
