import type { LocaleDictionary } from "$applib/types/i18n";

interface ComponentsDictionary extends LocaleDictionary {
	forms: {
		fields: {
			selectMetadataResource: {
				errorLinkText: string;
				errorText: string;
				loadingText: string;
				selectText: string;
			};
		};
	};
}

const components: ComponentsDictionary = {
	forms: {
		fields: {
			selectMetadataResource: {
				errorLinkText: "Try again",
				errorText: "There was a problem loading {name}.",
				loadingText: "Loading...",
				selectText: "--------",
			},
		},
	},
};

export { components };
