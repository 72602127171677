<script lang="ts">
  import {createEventDispatcher} from 'svelte';
  import {useMachine} from '@xstate/svelte';

  import {GridWrap, GridCol} from '$applib/components/grid';
  import {
    FormField,
    SelectMetadataResource,
  } from '$applib/components/forms/fields';
  import {FormErrorsAlert} from '$applib/components/forms/meta';
  import {Button} from '$applib/components/button';
  import {Loader} from '$applib/components/loader';
  import {_} from '$applib/i18n';

  import {config} from '$applib/configs/application';
  const {staffMember} = config;
  const {organisationId} = staffMember;

  import {createPatientForm} from './config';
  import {patientMachine} from './machines';

  import type {CreatePatientStateMachine} from './machines';

  import {EventType} from '$applib/utils/resources/create-resource-machine';

  import type {CreatePatientPayload} from '$applib/types/resources/patients';

  const dispatch = createEventDispatcher();
  const {
    form,
    handleChange,
    handleSubmit,
    errors: fieldErrors,
  } = createPatientForm({onSubmit});
  const {snapshot: state, send: createPatient} =
    useMachine<CreatePatientStateMachine>(patientMachine, {
      input: {organisationId},
    });
  const baseTranslationPath = 'views.appointmentGrid.createPatient.form';

  function _tField(id: string) {
    return $_(`${baseTranslationPath}.fields.${id}`);
  }

  $: error = $state.context.error;
  $: if ($state.matches({post: 'success'})) {
    handlePatientCreated();
  }

  function handlePatientCreated() {
    dispatch('patientcreated', {patientId: $state.context.item?.id});
  }

  async function onSubmit(values: CreatePatientPayload) {
    createPatient({type: EventType.Post, params: values});
  }
</script>

<form on:submit|preventDefault={handleSubmit} data-testid="create-patient-form">
  <GridWrap>
    <GridCol baseWidth="1-of-2">
      <FormField
        label={_tField('firstNames.label')}
        id="firstNames"
        error={$fieldErrors.firstNames}
      >
        <input
          id="firstNames"
          name="firstNames"
          on:change={handleChange}
          bind:value={$form.firstNames}
        />
      </FormField>
    </GridCol>
    <GridCol baseWidth="1-of-2">
      <FormField
        label={_tField('lastName.label')}
        id="lastName"
        error={$fieldErrors.lastName}
      >
        <input
          id="lastName"
          name="lastName"
          on:change={handleChange}
          bind:value={$form.lastName}
        />
      </FormField>
    </GridCol>
  </GridWrap>

  <FormField
    label={_tField('email.label')}
    id="email"
    error={$fieldErrors.email}
  >
    <input
      id="email"
      name="email"
      type="email"
      on:change={handleChange}
      bind:value={$form.email}
    />
  </FormField>

  <GridWrap>
    <GridCol baseWidth="1-of-2">
      <FormField label={_tField('dob.label')} id="dob" error={$fieldErrors.dob}>
        <input
          id="dob"
          name="dob"
          type="date"
          on:change={handleChange}
          bind:value={$form.dob}
        />
      </FormField>
    </GridCol>

    <GridCol baseWidth="1-of-2">
      <FormField
        label={_tField('gender.label')}
        id="gender"
        error={$fieldErrors.gender}
      >
        <SelectMetadataResource
          namePlural="genders"
          id="gender"
          required={true}
          resourceType="genders"
          on:input={handleChange}
        />
      </FormField>
    </GridCol>

    <GridCol baseWidth="1-of-2">
      <FormField
        label={_tField('telMobile.label')}
        id="telMobile"
        error={$fieldErrors.telMobile}
      >
        <input
          bind:value={$form.telMobile}
          id="telMobile"
          name="telMobile"
          on:change={handleChange}
          placeholder={_tField('telMobile.placeholder')}
        />
      </FormField>
    </GridCol>

    <GridCol baseWidth="1-of-2">
      <FormField
        label={_tField('telHome.label')}
        id="telHome"
        error={$fieldErrors.telHome}
        required={false}
      >
        <input
          bind:value={$form.telHome}
          id="telHome"
          name="telHome"
          on:change={handleChange}
          placeholder={_tField('telHome.placeholder')}
        />
      </FormField>
    </GridCol>
  </GridWrap>

  {#if $state.hasTag('error')}
    <FormErrorsAlert errorMap={error.messages} />
  {/if}

  <Button
    type="submit"
    class="btn btn-primary"
    disabled={$state.hasTag('requesting')}
  >
    {#if $state.hasTag('requesting')}
      <Loader>Creating patient...</Loader>
    {:else}Create patient{/if}
  </Button>
</form>
