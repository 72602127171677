import deepClone from "clonedeep";
import { camelCase, snakeCase } from "./strings";

const deepCopy = deepClone;

type TransformFn = (x: string) => string;
interface TransformableRecord {
	[key: string]: string | number | undefined;
}

function transformKeys(obj: TransformableRecord, transformFn: TransformFn) {
	const keys = Object.keys(obj);
	const newDict: TransformableRecord = {};

	for (const key of keys) {
		const value = obj[key as keyof TransformableRecord];
		const newKey = transformFn(key);
		newDict[newKey] = value;
	}

	return newDict;
}

/**
 * camelCaseKeys.
 *
 * Converts keys for a provided object to camelCase
 *
 * Useful when getting data from an API where keys are defined in a different
 * case.
 *
 * @param {Record} dict
 */
function camelCaseKeys<T extends TransformableRecord>(dict: T) {
	return transformKeys(dict, camelCase);
}

/**
 * snakeCaseKeys.
 *
 * Converts keys for a provided object to snake_case
 *
 * Useful for sending data to endpoints that expect snake_cased keys, while
 * allowing for use of camelCase keys within Javascript
 *
 * @param {Record} dict
 */
function snakeCaseKeys<T extends TransformableRecord>(dict: T) {
	return transformKeys(dict, snakeCase);
}

export { camelCaseKeys, snakeCaseKeys, deepCopy };
