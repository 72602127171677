import type { RequestOptions } from "$applib/types/request-response";

type ParameterizePathFn = (
	path: string,
	parameters?: RequestOptions["parameters"],
	query?: RequestOptions["query"],
) => string;

function replaceUrlParameter(parameters: RequestOptions["parameters"]) {
	return function (match: string | number) {
		const key = `${match}`.replace(":", "");
		const value = parameters ? `${parameters[key]}` : null;

		return value ? value : `${match}`;
	};
}

/**
 * parameterizePath
 *
 * - path: a URL with : delimited parameters
 * - parameters: an object with keys matching the parameters in the path
 * - query: an object with keys already cased as per the parameters expected by
 *    the path
 */
const parameterizePath: ParameterizePathFn = (path, parameters, query) => {
	const parameterisedUrl = path
		.split("/")
		.map((pathPart) => {
			const newPathPart = pathPart.replace(
				/(:\w+)/g,
				replaceUrlParameter(parameters),
			);

			return newPathPart;
		})
		.join("/");
	const queryString = query
		? Object.keys(query)
				.map((key) => `${key}=${query[key]}`)
				.join("&")
		: "";

	return [parameterisedUrl, queryString].filter(Boolean).join("?");
};

export { parameterizePath };
