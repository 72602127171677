import { DiscussionButton } from "./components/discussion-button";
import { getDiscussionType } from "./components/discussion-button/utils";
import { ReportWaitingNotification } from "./components/notification";
import { NOTIFY_EVENT_NAME } from "./shared/events";

import type { ReportNeeded } from "$applib/types/resources/patient-discussions";
import type { NotifyEvent } from "./shared/events";

const DISCUSSION_BUTTON_SELECTOR =
	"js-report-waiting-discussion-button-container";
const NOTIFICATION_SELECTOR = "js-report-waiting-notification-container";
const buttonContainerEls = document.getElementsByClassName(
	DISCUSSION_BUTTON_SELECTOR,
) as HTMLCollectionOf<HTMLElement>;
const notificationContainerEls = Array.from(
	document.getElementsByClassName(
		NOTIFICATION_SELECTOR,
	) as HTMLCollectionOf<HTMLElement>,
);
const notificationCompsCache: Record<string, ReportWaitingNotification> = {};

function handleShowNotification(event: NotifyEvent) {
	const { alertType, message, timeout, patientPhotosId, errors } = event.detail;
	const target = notificationContainerEls.find(
		(el) => el.dataset.patientPhotosId === `${patientPhotosId}`,
	);

	if (!target) {
		return;
	}

	const pairs = [patientPhotosId]
		.map((id) => ({ id, component: notificationCompsCache[id] }))
		.filter(({ component }) => Boolean(component));

	for (const { id, component } of pairs) {
		component.$destroy();
		delete notificationCompsCache[id];
	}

	const component = new ReportWaitingNotification({
		intro: true,
		props: { alertType, message, timeout, errors },
		target,
	});

	notificationCompsCache[patientPhotosId] = component;

	component.$on("close", component.$destroy);
}

for (const target of buttonContainerEls) {
	const {
		discussionId,
		patientPhotosId,
		// TODO: LB - refactor this field in the model to use enums
		reportNeeded,
	} = target.dataset;

	target.innerHTML = "";

	const button = new DiscussionButton({
		target,
		props: {
			discussionId: discussionId ? Number.parseInt(discussionId || "0", 10) : 0,
			discussionType: getDiscussionType(reportNeeded as ReportNeeded),
			patientPhotosId: Number.parseInt(patientPhotosId || "0", 10),
		},
	});

	button.$on(NOTIFY_EVENT_NAME, handleShowNotification);
}
