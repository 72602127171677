import { config } from "$applib/configs/application";
import { buildHeaders, getCsrfTokenHeader } from "$applib/utils/headers";
import { snakeCaseKeys } from "$applib/utils/objects";
import { parameterizePath } from "$applib/utils/resources/urls";

const { patient, urls } = config;
const { api } = urls;
const { otps } = api;
const { path: patientEmailPath } = otps.patientEmail;

const ELEMENT_SELECTOR = ".js-otp-patient-email";

function sendOtpEmail(event: Event) {
	const organizationId = patient.organisationIds.find(Boolean);
	const url = parameterizePath(
		patientEmailPath,
		snakeCaseKeys({ organisationId: organizationId }),
	);
	const currentTarget = event.currentTarget as HTMLButtonElement;
	const patientId =
		currentTarget.getAttribute("data-otp-patient-email-patient-id") || "";
	const personResponsibleId =
		currentTarget.getAttribute(
			"data-otp-patient-email-person-responsible-id",
		) || "";

	fetch(url, {
		method: "POST",
		headers: buildHeaders(getCsrfTokenHeader()),
		body: JSON.stringify(snakeCaseKeys({ patientId, personResponsibleId })),
	})
		.then((response) => response.json())
		.then((response) => {
			if (response.instance == "Success") {
				const email1 = document.getElementById("otp-patient-email-1");
				const email2 = document.getElementById("otp-patient-email-2");
				const resendemail1 = document.getElementById(
					"otp-patient-email-resend-1",
				);
				const resendemail2 = document.getElementById(
					"otp-patient-email-resend-2",
				);
				const formContainer = document.getElementById(
					"otp-patient-email-form-container",
				) as HTMLDivElement;

				if (email1) {
					email1.outerHTML = "";
				}

				if (email2) {
					email2.outerHTML = "";
				}

				formContainer.classList.add("list-group-item");
				formContainer.classList.add("d-flex");
				formContainer.classList.add("justify-content-between");
				formContainer.classList.add("align-items-center");

				if (resendemail1) {
					resendemail1.classList.add("list-group-item");
					resendemail1.classList.add("d-flex");
					resendemail1.classList.add("justify-content-between");
					resendemail1.classList.add("align-items-center");
				}

				if (resendemail2) {
					resendemail2.classList.add("list-group-item");
					resendemail2.classList.add("d-flex");
					resendemail2.classList.add("justify-content-between");
					resendemail2.classList.add("align-items-center");
				}
			}
		})
		.catch((response) => {
			// alert the error if any error occurred
			console.error(response);
		});
}

export { sendOtpEmail, ELEMENT_SELECTOR };
