<script lang="ts">
  import {fly} from 'svelte/transition';

  import {getCsrfTokenHeader, buildHeaders} from '$applib/utils/headers';
  import {snakeCaseKeys} from '$applib/utils/objects';
  import {parameterizePath} from '$applib/utils/resources/urls';
  import {humanizeTime} from '$applib/utils/date-times';
  import type {DjangoApiError} from '$applib/types/errors';

  import {Button, ButtonModifier} from '$applib/components/button';
  import {
    GridWrap,
    GridWrapModifier,
    GridCol,
    GridColModifier,
  } from '$applib/components/grid';
  import {Loader} from '$applib/components/loader';

  import {config} from '$applib/configs/application';

  export let staffResourceId: string | number;
  export let appointmentDate: string;
  export let appointmentTime: string;
  export let appointmentServiceId: string | number;

  enum State {
    Idle = 'idle',
    Requesting = 'requesting',
    Success = 'success',
    Failure = 'failure',
  }

  const {patient, urls} = config;
  const {patientPortal} = urls.api;
  const {new: newAppointmentEndpoint} = patientPortal.appointments;
  const url = parameterizePath(
    newAppointmentEndpoint.path,
    snakeCaseKeys({patientId: patient.id}),
  );

  let error: string | undefined;
  let state: State = State.Idle;

  // TODO: LB - use a resource state machine to make these requests
  async function createAppointment() {
    state = State.Requesting;
    error = undefined;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: buildHeaders({
          ...getCsrfTokenHeader(),
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }),
        body: JSON.stringify({
          appointment_date: appointmentDate,
          appointment_service_id: appointmentServiceId,
          appointment_time: appointmentTime,
          staff_resource_id: staffResourceId,
        }),
      });

      const json = await response.json();

      if (response.ok) {
        state = State.Success;
        // TODO: LB - remove this assignment once page no longer blocks reloads
        // when forms are dirty
        window.onbeforeunload = () => {};
        window.location.reload();
      } else {
        throw json.error;
      }
    } catch (err) {
      state = State.Failure;

      setError(err as DjangoApiError);
    }
  }

  function setError(errorObject: DjangoApiError) {
    error = `We were unable to book your appointment. Please contact
      the practice to make a booking`;

    // NOTE: svelte-check linting is failing when the following are represented
    // as a switch statement
    if (Array.isArray(errorObject.message)) {
      error = errorObject.message.join('. ');
    } else if (typeof errorObject.message == 'string') {
      error = errorObject.message;
    } else if (typeof errorObject.message == 'object') {
      error = Object.values(errorObject.message).join('. ');
    }
  }
</script>

<div
  class="u-padding--smaller--block u-padding--small--inline u-fc--base-lt u-fs--milli"
>
  <GridWrap modifiers={[GridWrapModifier.Small, GridWrapModifier.Middle]}>
    <GridCol modifiers={[GridColModifier.Auto]}>
      <strong>{humanizeTime(appointmentTime)}</strong>
    </GridCol>

    <GridCol modifiers={[GridColModifier.ShrinkWrap]}>
      <Button
        on:click={createAppointment}
        modifiers={[
          ButtonModifier.Small,
          ButtonModifier.Outlined,
          ButtonModifier.ClrOutlinedPrimary,
        ]}
        disabled={state === State.Requesting || state === State.Success}
      >
        {#if state === State.Idle}
          Book appointment
        {:else if state === State.Requesting}
          <Loader>Booking appointment...</Loader>
        {:else if state === State.Success}
          Appointment booked!
        {:else if state === State.Failure}
          Book appointment
        {/if}
      </Button>
    </GridCol>
  </GridWrap>

  {#if error}
    <div
      class="u-padding--smallest--block-start u-fc--danger"
      transition:fly={{y: -10}}
    >
      <small>
        {error}
      </small>
    </div>
  {/if}
</div>
