/*eslint no-useless-catch: "off" */
import type {
	RequestProviderFn,
	RequestProviderProps,
} from "$applib/types/request-response";

const safeGetJson = async <
	TEntity extends Record<string, unknown> = Record<string, unknown>,
>(
	response: Response,
): Promise<TEntity | TEntity[] | null> => {
	/**
	 * fetch spec indicates that 204 responses should throw if response.json() is called.
	 *
	 * 204 indicates no content returned, so don't bother getting the json response
	 */
	if (response.status === 204) {
		return null;
	}

	if (response.json) {
		try {
			const json = await response.json();

			return json;
		} catch (error) {
			throw error;
		}
	}

	return null;
};

const jsonFetchProvider = async ({
	requestInit: options,
	url,
}: RequestProviderProps) => {
	try {
		const response = await fetch(url, options);

		if (!response.ok) {
			if (response.json) {
				const jsonError = await response.json();
				throw jsonError;
			} else {
				throw response;
			}
		}

		const body = await safeGetJson(response);

		/*eslint-disable-next-line */
		return body as any;
	} catch (error) {
		throw error;
	}
};

const parseHeaders = (headers: Headers) => {
	const headersIterator = Object(headers).entries();
	const result = [...headersIterator].reduce(
		(headerMap, [key, value]) => ({
			...headerMap,
			[key]: value,
		}),
		{},
	);

	return result;
};

const headParserProvider: RequestProviderFn = async ({
	requestInit: options,
	url,
}) => {
	const response = await fetch(url, options);

	if (!response.ok) {
		if (response.json) {
			const jsonError = await response.json();
			throw jsonError;
		} else {
			throw response;
		}
	}

	const body = parseHeaders(response.headers);

	return body;
};

const blobProvider: RequestProviderFn = async ({
	requestInit: options,
	url,
}) => {
	const response = await fetch(url, options);

	if (!response.ok) {
		if (response.json) {
			const jsonError = await response.json();
			throw jsonError;
		} else {
			throw response;
		}
	}

	const blob = await response.blob();
	const headersMap = parseHeaders(response.headers);
	const fileUrl = window.URL.createObjectURL(blob);
	const result = { ...headersMap, url: fileUrl };

	return result;
};

export { blobProvider, headParserProvider, jsonFetchProvider };
