import type { CustomWindow } from "$applib/types/window";
import type { ApplicationConfig } from "$applib/types/config";

declare let window: CustomWindow;

const { urls, staffMember, patient } = window._applicationData;

const config: ApplicationConfig = {
	patient,
	staffMember,
	urls,
};

export { config };
