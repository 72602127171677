enum State {
	NotLoaded = "not-loaded",
	Loaded = "loaded",
}

let state = State.NotLoaded;
const loadedEvent = new CustomEvent("document:imageannotatorloader:success");

// TODO: LB - simplify this by removing the event listener as soon as we
// enter this function - this mitigates the need to track state
// TODO: LB - listen for all `imageannotator:init` events fired before
// the lib is loaded, and then trigger them again once loaded
async function loadImageAnnotator() {
	if (state === State.NotLoaded) {
		await import("./image-annotator");
		state = State.Loaded;
	}

	document.dispatchEvent(loadedEvent);
}

document.addEventListener("imageannotatorloader:fetch", loadImageAnnotator);
