import { fromCallback, or, setup } from "xstate";
import { assign, log, raise, sendParent, sendTo } from "xstate/actions";

import type { ActorRefFrom, MachineContext } from "xstate";

import {
	InvoiceSetEventName as EventName,
	InvoiceGeneratorEventName,
	InvoiceSetDatePeriod,
	InvoiceSetDateType,
	LineItemEventName,
	SubmissionState,
} from "../enums";

import type {
	CalculatedInvoiceSetDueDates,
	InvoiceSetDateConfig,
} from "../types";

import type { LineItemMachine } from "./line-items";

interface InvoiceSetMachineContext extends MachineContext {
	dateConfig: InvoiceSetDateConfig;
	lineItems: Array<{ ref: ActorRefFrom<LineItemMachine> }>;
	numInvoices: number;
	order: number;
	totalPerInvoice: number;
}

export type InvoiceSetCallbackLogic = ReturnType<typeof fromCallback>;
export type InvoiceSetStateMachine = typeof invoiceSetMachine;

const invoiceSetMachine = setup({
	types: {} as {
		actors: { ioEventDispatcher: InvoiceSetCallbackLogic };
		context: InvoiceSetMachineContext;
		events:
			| {
					type: EventName.IoRxChangeNumInvoices;
					params: { numInvoices: number };
			  }
			| { type: EventName.IoRxLineItemInit; params: { element: HTMLElement } }
			| { type: EventName.IoRxInvoiceSetDeleteClick }
			| {
					type: EventName.IoRxDateFieldChange;
					params: Partial<InvoiceSetMachineContext["dateConfig"]>;
			  }
			| {
					type: EventName.IoTxSubmissionStateChange;
					params: { state: SubmissionState };
			  }
			| {
					type: EventName.IoTxNumLineItemsChange;
					params: { numLineItems: number };
			  }
			| { type: EventName.IoTxOrderChange; params: { order: number } }
			| {
					type: EventName.IoTxTotalChange;
					params: { totalPerInvoice: number; numInvoices: number };
			  }
			| {
					type: EventName.IoTxDueDatesChange;
					params: CalculatedInvoiceSetDueDates;
			  }
			| {
					type: EventName.RxDueDatesChange;
					params: CalculatedInvoiceSetDueDates;
			  }
			| { type: EventName.RxLineItemUpdate }
			| { type: EventName.RxOrderChange; params: { order: number } }
			| { type: EventName.RxTotalChange; params: { invoiceSetTotal: number } }
			| { type: EventName.TxNotifyLineItems };
		input: Partial<Omit<InvoiceSetMachineContext, "lineItems">>;
	},
	actions: {
		log: log((x) => x.self, "invoice set"),

		spawnLineItem: (_, _params: { element: HTMLElement }) => {
			throw new Error("Not Implemented");
		},

		setNumInvoices: assign({
			numInvoices: (_, { numInvoices }: { numInvoices: number }) => numInvoices,
		}),

		setDateConfig: assign({
			dateConfig: (
				{ context: { dateConfig } },
				params: Partial<InvoiceSetMachineContext["dateConfig"]>,
			) => {
				const result = { ...dateConfig, ...params };

				return result;
			},
		}),

		setOrder: assign({ order: (_, { order }: { order: number }) => order }),

		setTotalPerInvoice: assign({
			totalPerInvoice: (
				{ context: { numInvoices } },
				{ invoiceSetTotal }: { invoiceSetTotal: number },
			) => {
				return numInvoices !== 0 ? invoiceSetTotal / numInvoices : 0;
			},
		}),

		setTotalViaLineItems: assign({
			totalPerInvoice: ({ context: { lineItems } }) => {
				const lineItemsTotal = lineItems
					.filter(({ ref }) => ref.getSnapshot().matches("active"))
					.map(({ ref }) => ref.getSnapshot().context.total)
					.reduce((acc, total) => acc + total, 0);

				return lineItemsTotal;
			},
		}),

		notifyParent: sendParent({
			type: InvoiceGeneratorEventName.RxInvoiceSetUpdate,
		}),
		requestDueDates: sendParent({
			type: InvoiceGeneratorEventName.CalculateDueDates,
		}),
		scheduleNotifyLineItems: raise({ type: EventName.TxNotifyLineItems }),

		sendTotalToLineItems: ({ context }) => {
			const { lineItems, totalPerInvoice } = context;
			const fixedLineItems = lineItems.filter(({ ref }) =>
				ref.getSnapshot().matches({ active: "fixed" }),
			);
			const dynamicLineItems = lineItems.filter(({ ref }) =>
				ref.getSnapshot().matches({ active: "dynamic" }),
			);
			const fixedTotal = fixedLineItems
				.map(({ ref }) => ref.getSnapshot().context.total)
				.reduce((acc, total) => acc + total, 0);
			let unallocated = totalPerInvoice - fixedTotal;

			for (let i = 0; i < dynamicLineItems.length; i++) {
				const { ref } = dynamicLineItems[i];
				const total = unallocated / (dynamicLineItems.length - i);
				unallocated = unallocated - total;

				ref.send({ type: LineItemEventName.RxSetTotal, params: { total } });
			}
		},

		forwardDeleteChangeToLineItems: (
			{ context: { lineItems } },
			params: {
				type:
					| LineItemEventName.RxParentDelete
					| LineItemEventName.RxParentRestore;
			},
		) => {
			lineItems.map(({ ref }) => ref.send({ type: params.type }));
		},

		emitSubmissionState: sendTo(
			"ioEventDispatcher",
			(_, params: { state: SubmissionState }) => ({
				type: EventName.IoTxSubmissionStateChange,
				params,
			}),
		),

		emitDueDates: sendTo(
			"ioEventDispatcher",
			(_, params: CalculatedInvoiceSetDueDates) => ({
				type: EventName.IoTxDueDatesChange,
				params,
			}),
		),

		emitNumLineItems: sendTo(
			"ioEventDispatcher",
			({ context: { lineItems } }: { context: InvoiceSetMachineContext }) => ({
				type: EventName.IoTxNumLineItemsChange,
				params: { numLineItems: lineItems.length },
			}),
		),

		emitOrder: sendTo(
			"ioEventDispatcher",
			(_, { order }: { order: number }) => ({
				type: EventName.IoTxOrderChange,
				params: { order },
			}),
		),

		emitTotal: sendTo(
			"ioEventDispatcher",
			({
				context: { totalPerInvoice, numInvoices },
			}: { context: InvoiceSetMachineContext }) => ({
				type: EventName.IoTxTotalChange,
				params: { totalPerInvoice, numInvoices },
			}),
		),
	},

	guards: {
		hasNoInvoices: ({ context: { numInvoices } }) => numInvoices <= 0,
		hasDynamicLineItems: ({ context: { lineItems } }) => {
			return lineItems.some(({ ref }) => {
				const result = ref.getSnapshot().matches({ active: "dynamic" });

				return result;
			});
		},
		hasNoActiveLineItems: ({ context: { lineItems } }) => {
			return lineItems.every(({ ref }) => {
				const result = ref.getSnapshot().matches("markedForDeletion");

				return result;
			});
		},
		totalIsDifferent: (
			{ context: { numInvoices, totalPerInvoice } },
			params: { invoiceSetTotal: number },
		) => {
			// NOTE: we only care about a few decimal places here, so no need to evaluate
			// the entire number. Evaluating the entire number also sets us up for
			// floating point errors, and a floating point error in this guard results
			// in a infinite loop, as totals are evaluated both before they are set
			// and after - if we get a floating point error then the total in context
			// will never match the calculated total
			return (
				(totalPerInvoice * numInvoices).toFixed(4) !==
				params.invoiceSetTotal.toFixed(4)
			);
		},
		isMarkedForDeletion: () => false,
	},

	actors: {
		ioEventDispatcher: fromCallback(() => {
			throw new Error("Not implemented");
		}),
	},
}).createMachine({
	id: "invoice-generator-invoice-set",
	initial: "init",
	context: ({ input }) => ({
		dateConfig: {
			cardinalPeriod: InvoiceSetDatePeriod.Week,
			cardinalValue: 4,
			dateType: InvoiceSetDateType.Ordinal,
			minDuration: 3,
			minDurationPeriod: InvoiceSetDatePeriod.Week,
			ordinalPeriod: InvoiceSetDatePeriod.Month,
			ordinalValueMonthly: 1,
			ordinalValueWeekly: 1,
		},
		lineItems: [],
		numInvoices: 1,
		order: 0,
		totalPerInvoice: 0,
		...input,
	}),
	invoke: { id: "ioEventDispatcher", src: "ioEventDispatcher" },

	on: {
		[EventName.RxOrderChange]: {
			actions: [
				{
					type: "setOrder",
					params: ({ event: { params } }) => ({ order: params.order }),
				},
				{
					type: "emitOrder",
					params: ({ event }) => ({ order: event.params.order }),
				},
			],
		},
	},

	states: {
		init: {
			always: [
				{ target: "markedForDeletion", guard: "isMarkedForDeletion" },
				{ target: "active" },
			],
		},

		active: {
			initial: "evaluating",
			entry: [
				{
					type: "emitSubmissionState",
					params: { state: SubmissionState.Default },
				},
				{ type: "requestDueDates" },
			],

			on: {
				[EventName.TxNotifyLineItems]: {
					actions: [{ type: "sendTotalToLineItems" }],
				},
				[EventName.IoRxChangeNumInvoices]: {
					actions: [
						{
							type: "setNumInvoices",
							params: ({ event }) => ({
								numInvoices: event.params.numInvoices,
							}),
						},
						{ type: "requestDueDates" },
					],
					target: ".evaluating",
				},
				[EventName.IoRxDateFieldChange]: [
					{
						actions: [
							{
								type: "setDateConfig",
								params: ({ event }) => ({ ...event.params }),
							},
							{ type: "requestDueDates" },
						],
						target: ".evaluating",
					},
				],
				[EventName.IoRxLineItemInit]: {
					actions: [
						{
							type: "spawnLineItem",
							params: ({ event }) => ({ element: event.params.element }),
						},
					],
					target: ".evaluating",
				},
				[EventName.IoRxInvoiceSetDeleteClick]: [
					{ target: "#markedForDeletion" },
				],
				[EventName.RxDueDatesChange]: [
					{
						actions: [
							{ type: "emitDueDates", params: ({ event }) => event.params },
						],
					},
				],
			},

			states: {
				evaluating: {
					entry: [
						{ type: "scheduleNotifyLineItems" },
						{ type: "emitTotal" },
						{ type: "emitNumLineItems" },
					],
					always: [
						{
							target: "empty",
							guard: or(["hasNoInvoices", "hasNoActiveLineItems"]),
						},
						{ target: "dynamic", guard: { type: "hasDynamicLineItems" } },
						{ target: "fixed" },
					],
				},
				dynamic: {
					entry: [{ type: "notifyParent" }],
					on: {
						[EventName.RxLineItemUpdate]: { target: "evaluating" },
						[EventName.RxTotalChange]: {
							actions: [
								{
									type: "setTotalPerInvoice",
									params: ({ event }) => ({
										invoiceSetTotal: event.params.invoiceSetTotal,
									}),
								},
							],
							target: "evaluating",
							guard: {
								type: "totalIsDifferent",
								params: ({ event }) => ({
									invoiceSetTotal: event.params.invoiceSetTotal,
								}),
							},
						},
					},
				},
				empty: {
					entry: [{ type: "notifyParent" }],
					on: {
						[EventName.RxLineItemUpdate]: {
							target: "evaluating",
							actions: ["setTotalViaLineItems"],
						},
					},
				},
				fixed: {
					entry: [{ type: "notifyParent" }],
					on: {
						[EventName.RxLineItemUpdate]: {
							target: "evaluating",
							actions: ["setTotalViaLineItems"],
						},
					},
				},
			},
		},

		markedForDeletion: {
			id: "markedForDeletion",
			entry: [
				{ type: "notifyParent" },
				{
					type: "forwardDeleteChangeToLineItems",
					params: { type: LineItemEventName.RxParentDelete },
				},
				{
					type: "emitSubmissionState",
					params: { state: SubmissionState.MarkedForDeletionBySelf },
				},
				{ type: "setTotalPerInvoice", params: { invoiceSetTotal: 0 } },
				{ type: "emitTotal" },
				{ type: "emitDueDates", params: { refDate: new Date(), dueDates: [] } },
				{ type: "requestDueDates" },
			],
			exit: [
				{
					type: "forwardDeleteChangeToLineItems",
					params: { type: LineItemEventName.RxParentRestore },
				},
			],
			on: {
				[EventName.IoRxInvoiceSetDeleteClick]: [{ target: "active" }],
				[EventName.IoRxLineItemInit]: {
					actions: [
						{
							type: "spawnLineItem",
							params: ({ event }) => ({ element: event.params.element }),
						},
						{
							type: "forwardDeleteChangeToLineItems",
							params: { type: LineItemEventName.RxParentDelete },
						},
					],
				},
			},
		},
	},
});

export { invoiceSetMachine };
