import { fromCallback } from "xstate";

import { debounce } from "$applib/utils/functions";
import type { EventFrom } from "xstate";

import { InvoiceGeneratorEventName } from "../../shared/enums";

import type {
	InvoiceGeneratorCallbackLogic,
	InvoiceGeneratorMachine,
	InvoiceGeneratorPromiseLogic,
} from "../../shared/machines/generator";
import { createDomSelector } from "./utils";

function ioEventDispatchCreator(formEl: HTMLFormElement) {
	return fromCallback<EventFrom<InvoiceGeneratorMachine>>(
		({ sendBack, receive }) => {
			const formElSelector = createDomSelector(formEl);
			const handlersByEvent = {
				"invoicegen:refdatechange": function handleRefDateChange(event: Event) {
					const { detail } = event as CustomEvent;
					const { value } = detail;
					const date = new Date(value).getDate() ? new Date(value) : new Date();

					sendBack({
						type: InvoiceGeneratorEventName.IoRxChangeRefDate,
						params: { date },
					});
				},
				"invoicegen:totalchange": function handleTotalChange(event: Event) {
					const { detail } = event as CustomEvent;

					sendBack({
						type: InvoiceGeneratorEventName.IoRxChangeTotal,
						params: { total: Number.parseFloat(detail.total) || 0 },
					});
				},
				"invoicegen:invoiceset:init": function handleInvoiceSetInit(
					event: Event,
				) {
					const { detail } = event as CustomEvent;

					sendBack({
						type: InvoiceGeneratorEventName.IoRxInvoiceSetInit,
						params: { element: detail.sender },
					});
				},
				"invoicegen:invoiceset:move": function handleInvoiceSetMove() {
					const ordersByRefId: Record<string, number> = {};
					const { getInvoiceSetEls } = formElSelector;
					const invoiceSetEls = getInvoiceSetEls();

					for (let i = 0; i < invoiceSetEls.length; i++) {
						const refId = invoiceSetEls[i].dataset.formPrefix || "";

						ordersByRefId[refId] = i;
					}

					sendBack({
						type: InvoiceGeneratorEventName.IoRxInvoiceSetReorder,
						params: { ordersByRefId },
					});
				},
			};

			for (const [event, handler] of Object.entries(handlersByEvent)) {
				formEl.addEventListener(event, handler);
			}

			receive((machineEvent) => {
				switch (machineEvent.type) {
					case InvoiceGeneratorEventName.IoTxNumInvoiceSetsChange: {
						const {
							params: { numInvoiceSets },
						} = machineEvent;
						const { getNumFormsInput } = formElSelector;

						getNumFormsInput().value = `${numInvoiceSets}`;
						break;
					}
					case InvoiceGeneratorEventName.IoTxStateChange: {
						const {
							params: { state },
						} = machineEvent;
						const { getTotalOutputEl } = formElSelector;
						const event = new CustomEvent(
							"invoicegen:totaloutput:statechange",
							{ detail: { state } },
						);

						getTotalOutputEl().dispatchEvent(event);
						break;
					}
				}
			});

			return function destroy() {
				for (const [event, handler] of Object.entries(handlersByEvent)) {
					formEl.removeEventListener(event, handler);
				}
			};
		},
		// TODO: LB - determine why casting this is required to fix TS
		// errors in ../index.ts
	) as InvoiceGeneratorCallbackLogic;
}

function dueDatesFetchCreator(formEl: HTMLFormElement) {
	return fromCallback<EventFrom<InvoiceGeneratorMachine>>(
		({ sendBack, receive }) => {
			const url = new URL(formEl.dataset.dueDatesUrl || "");
			let abortController: AbortController | undefined;

			function getDueDates() {
				if (abortController) {
					abortController.abort("New due dates requested");
					abortController = undefined;
				}

				const formData = new FormData(formEl);
				const searchParams = new URLSearchParams(
					formData as unknown as Record<string, string>,
				);
				abortController = new AbortController();
				url.search = searchParams.toString();

				fetch(url, {
					signal: abortController.signal,
					method: "GET",
					headers: { Accept: "application/json" },
				})
					.then((res) => {
						if (!res.ok) {
							throw res.json();
						}

						return res.json();
					})
					.then((res) => {
						sendBack({
							type: InvoiceGeneratorEventName.CalculateDueDatesSuccess,
							params: { dueDatesResults: res },
						});
					})
					// TODO: LB - indicate to user that there was an error
					.catch((res) => console.error(res));
			}
			const getDebouncedDueDates = debounce(getDueDates, 100);

			receive((event) => {
				if (event.type === InvoiceGeneratorEventName.CalculateDueDates) {
					getDebouncedDueDates();
				}
			});
		},
		// TODO: LB - determine why casting this is required to fix TS
		// errors in ../index.ts
	) as InvoiceGeneratorPromiseLogic;
}

export { ioEventDispatchCreator, dueDatesFetchCreator };
