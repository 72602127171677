<script lang="ts">
  import {getContext} from 'svelte';

  import {key} from './context';
  import type {TabsStore} from './machine';

  export let id: string;

  const tabsStore = getContext<TabsStore>(key);
  const {snapshot: state} = tabsStore;

  $: isActive = $state.context.activeTabId === id;
</script>

{#if isActive}
  <slot />
{/if}
