// TODO: LB - use correct import syntax without disabling linter
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
type Html2Canvas = typeof import("html2canvas");

import { drawingCanvasFactory } from "$applib/components/legacy-drawing-canvas";

const drawingCanvasSubmitterEl = document.querySelector<HTMLElement>(
	".js-legacy-drawing-canvas-form-submitter",
);

if (drawingCanvasSubmitterEl) {
	const drawingCanvas = drawingCanvasFactory();
	drawingCanvas.init();
}

function initializeSubmitter(
	containerEl: HTMLElement,
	html2canvas: Html2Canvas["default"],
) {
	const formEl = containerEl.querySelector("form") as HTMLFormElement;
	const submitButtonEl = containerEl.querySelector(
		".js-submit-legacy-drawing-canvas",
	) as HTMLButtonElement;
	const inputEl = formEl.querySelector("#id_image_data") as HTMLInputElement;
	const imageContextEl = containerEl.querySelector(
		".js-legacy-drawing-canvas-image-context",
	) as HTMLDivElement;

	function handleSubmit() {
		html2canvas(imageContextEl).then((canvas: HTMLCanvasElement) => {
			const imageData = canvas.toDataURL("image/webp");

			inputEl.value = imageData;

			formEl.submit();
		});
	}

	submitButtonEl.addEventListener("click", handleSubmit);
}

if (drawingCanvasSubmitterEl) {
	(async () => {
		/**
		 * TODO: LB - refactor this function so that we don't need html2canvas.
		 * html2canvas is useful for creating images from arbitrary DOM nodes,
		 * but if all the image information is contained inside HTMLCanvasElement,
		 * then there's no need to use html2canvas
		 */
		const html2canvas = await import("html2canvas");

		initializeSubmitter(drawingCanvasSubmitterEl, html2canvas.default);
	})();
}
