import { config } from "$applib/configs/application";
import { resetEventHandlersFactory } from "$applib/utils/event-handlers";
import { buildHeaders, getCsrfTokenHeader } from "$applib/utils/headers";
import type { AppointmentForm } from "./appointment-form";

interface FormData {
	date: string;
	staffResourceId: string;
	time: string;
}

const { urls } = config;
const { path: getServicesEndpoint } =
	urls.api.appointments.appointmentServicesGet;

const GET_APPOINTMENT_DATA_CLASS = "js-get-appointment-data";

// allows us to use .handleEvent as an unchanging function, required when
// removing event listeners - event listeners cannot remove dynamically created
// functions
function handlerFactory() {
	let form: AppointmentForm;

	function setForm(appointmentForm: AppointmentForm) {
		form = appointmentForm;
	}

	function handleEvent(event: Event) {
		return getAppointmentData(event, form);
	}

	return { handleEvent, setForm };
}
const handler = handlerFactory();

const resetGetAppointmentDataHandlers = (appointmentForm: AppointmentForm) => {
	handler.setForm(appointmentForm);

	return resetEventHandlersFactory({
		className: GET_APPOINTMENT_DATA_CLASS,
		handler: handler.handleEvent,
		eventType: "click",
	});
};

function getAppointmentData(event: Event, appointmentForm: AppointmentForm) {
	const currentTarget = event.currentTarget as HTMLElement;
	const data = [
		"staffResourceId",
		"appointmentDate",
		"appointmentTime",
		"columnIndex",
	].reduce((acc, key) => {
		return {
			...acc,
			[key]: currentTarget.getAttribute(`data-appointment-data-${key}`),
		};
	}, {});
	const { staffResourceId, appointmentDate, appointmentTime } = data as Record<
		string,
		string
	>;
	const date = new Date(Date.bind(appointmentDate)());

	setFormData(appointmentForm, {
		staffResourceId,
		date: appointmentDate,
		time: appointmentTime,
	});

	appointmentForm.components.patientActions.$set({
		appointmentTime,
		appointmentDate: date,
	});

	const queryParams = new URLSearchParams({
		appointment_date: appointmentDate,
		appointment_time: appointmentTime,
		staff_resource_id: staffResourceId,
	});
	const url = [getServicesEndpoint, queryParams.toString()].join("?");

	fetch(url, {
		method: "GET",
		headers: buildHeaders({
			...getCsrfTokenHeader(),
			Accept: "application/json",
			"Content-Type": "application/json",
		}),
	})
		.then((response) => response.json())
		.then((response) => {
			if (response) {
				const selectEl = appointmentForm.elements.inputs.serviceIdSelect;
				const disabledOption =
					'<option value="" disabled selected>-------</option>';
				const serviceOptions = response.instance.map(
					(service: Record<string, unknown>) => {
						return `<option value="${service.id}">${service.title}</option>`;
					},
				);

				const options = [disabledOption, ...serviceOptions];

				selectEl.innerHTML = options.join("");
			}
		})
		.catch((error) => {
			console.error(error);
		});
}

function setFormData(
	appointmentForm: AppointmentForm,
	{ time, date, staffResourceId }: FormData,
) {
	const { elements } = appointmentForm;
	const { inputs } = elements;
	const { serviceIdSelect, dateInput, staffResourceIdInput, timeInput } =
		inputs;
	const [hours, minutes] = time.split(":");
	const modalEl = serviceIdSelect
		? serviceIdSelect.closest(".js-book-appointment-modal")
		: null;
	const headingEl = modalEl ? modalEl.querySelector(".js-modal-header") : null;

	timeInput.value = time;
	dateInput.value = date;
	staffResourceIdInput.value = staffResourceId;

	if (headingEl) {
		const headingTime = [hours, minutes].join(":");

		headingEl.innerHTML = `Appointment Starting at ${headingTime}`;
	}

	const servicesHtml =
		'<option value="loading">Getting Appointments...</option>';

	serviceIdSelect.innerHTML = servicesHtml;
}

export { GET_APPOINTMENT_DATA_CLASS, resetGetAppointmentDataHandlers };
