<script lang="ts">
  import {fly, slide} from 'svelte/transition';
  import {FieldMessageState} from './enums';
  import FieldMessage from './field-message.svelte';
  import FieldLabel from './field-label.svelte';

  export let error = '';
  export let hidden = false;
  export let id = '';
  export let label = '';
  export let message = '';
  export let required = true;

  let klass = '';
  export {klass as class};

  $: normalizedError = error && typeof error === 'string' ? error : '';
</script>

<div
  class="field {klass}"
  class:field--error={normalizedError}
  class:u-accessibly-hidden={hidden}
  aria-hidden={hidden}
>
  {#if label}
    <div>
      <FieldLabel {id}>
        {label}
        {#if !required}
          <small>(optional)</small>
        {/if}
      </FieldLabel>
    </div>
  {/if}

  <!-- use the named label slot if you want custom output -->
  <slot name="label" />

  <slot />

  <!-- use message if you want the default output of a string -->
  {#if message}
    <div in:fly|local={{y: -10}} out:slide|local>
      <FieldMessage>{message}</FieldMessage>
    </div>
  {/if}

  <!-- use the named message slot if you want custom output -->
  <slot name="message" />

  <!-- use error if you want default output of a string -->
  {#if normalizedError}
    <div in:fly|local={{y: -10}} out:slide|local>
      <FieldMessage state={FieldMessageState.Error}
        >{normalizedError}</FieldMessage
      >
    </div>
  {/if}

  <!-- use the named error slot if you want custom output -->
  <slot name="error" />
</div>
