import { validations } from "./shared/validations";
import { components } from "./shared/components";
import { views } from "./views";

const dict = {
	validations,
	components,
	views,
};

export { dict };
