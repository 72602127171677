import { config } from "$applib/configs/application";

const reloaderDetailsEl = document.querySelector<HTMLDivElement>(
	".js-appointments-reloader",
);
const { path: appointmentsEndpoint } = config.urls.api.appointments;

function reloaderFactory() {
	const url = new URL(window.location.origin + appointmentsEndpoint);
	let timerId: number;

	function init(timestamp: number, interval = 60_000) {
		const queryParams = new URLSearchParams({
			"updated-at.gte": `${timestamp}`,
		});

		url.search = queryParams.toString();
		timerId = setInterval(evaluateReload, interval);
	}

	function evaluateReload() {
		fetch(url)
			.then((response) => response.json())
			.then((response) => {
				if (response.results.length > 0) {
					reload();
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}

	function reload() {
		clearInterval(timerId);

		window.location.reload();
	}

	return { init };
}

if (reloaderDetailsEl) {
	const rawTimestamp = reloaderDetailsEl.dataset.reloaderTimestamp;
	const timestampAtRender = rawTimestamp
		? Number.parseInt(rawTimestamp)
		: Date.now() / 1000;
	const reloader = reloaderFactory();

	reloader.init(timestampAtRender);
}
