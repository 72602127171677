import { TimeSlotState } from "$applib/types/resources/appointments";

import type {
	Appointment,
	AppointmentTimeSlot,
} from "$applib/types/resources/appointments";
import { GET_APPOINTMENT_DATA_CLASS } from "../../get-appointment-data";

enum GridTemplateRowName {
	ResourceHeadings = "column-headings",
	ResourcePrefix = "resource",
	TimeLabels = "appointment-times",
	TimeSlotPrefix = "time-slot",
	GroupTimeSlotPrefix = "group-time-slot",
}

type Times = Array<AppointmentTimeSlot["times"]["start" | "end"]>;

function renderTimeSlotGroups(
	groupedTimeSlots: AppointmentTimeSlot[],
	times: Times,
	shouldRenderHeader = false,
): string {
	const firstTimeSlot = groupedTimeSlots.find(Boolean);
	const headerHtml =
		shouldRenderHeader && firstTimeSlot ? renderHeader(firstTimeSlot) : "";
	const timeSlotHtmls = renderTimeSlotGroup(groupedTimeSlots, times);

	return [headerHtml, timeSlotHtmls].join("");
}

function renderHeader(timeSlot: AppointmentTimeSlot): string {
	const { staff_resource: staffResource } = timeSlot;
	const { title } = staffResource;

	const html = `
    <div
      class='appointment-grid__column-header'
      style='
        grid-column: ${getGridColumnName(staffResource)};
        grid-row: ${GridTemplateRowName.ResourceHeadings};
      '
    >
      ${title}
    </div>`;

	return html;
}

function renderTimeSlot(timeSlot: AppointmentTimeSlot): string {
	const { state } = timeSlot;
	let html = "";

	switch (state) {
		case TimeSlotState.Closed:
			html = renderClosed(timeSlot);
			break;
		case TimeSlotState.Occupied:
			html = renderOccupied(
				timeSlot as unknown as AppointmentTimeSlot<Appointment>,
			);
			break;
		case TimeSlotState.Reserved:
			html = renderReserved(
				timeSlot as unknown as AppointmentTimeSlot<Appointment>,
			);
			break;
		case TimeSlotState.Available:
			html = renderAvailable(timeSlot);
			break;
		default:
			html = renderUnknown(timeSlot);
	}

	const gridStyles = getTimeSlotStyles(
		timeSlot,
		GridTemplateRowName.GroupTimeSlotPrefix,
	);
	const id = getTimeSlotId(timeSlot);

	return `
    <div id="${id}" class="appointment-grid__timeslot" style='${gridStyles}'>
      ${html}
    </div>
  `;
}

function renderTimeSlotGroup(
	timeSlotGroup: AppointmentTimeSlot[],
	times: Times,
): string {
	const html = timeSlotGroup.map(renderTimeSlot).join("");

	const groupStyles = getTimeSlotGroupStyles(timeSlotGroup, times);

	return `
    <div class="appointment-grid__timeslot-group" style='${groupStyles}'>
      ${html}
    </div>
  `;
}

function renderOccupied(timeSlot: AppointmentTimeSlot<Appointment>): string {
	const { appointment, times, color, patient, service } = timeSlot;
	const {
		id: appointmentId,
		arrived,
		complete,
		extra_missed_appointment: missedAppointment,
	} = appointment;
	const { title: serviceTitle } = service || {};
	const { firstnames: firstName, surname: lastName } = patient || {};
	const startTime = dropSeconds(times.start);
	const occupiedType = [
		complete === "yes" ? "completed" : "",
		arrived === "yes" ? "arrived" : "",
		color,
	]
		.filter(Boolean)
		.find(Boolean);
	const missedAppointmentText = missedAppointment
		? `
    <span class="u-padding--smaller--block-start">
      <hr class="u-margin--smaller--block-end">
      Patient didn't arrive
    </span>
    `
		: "";

	const html = `
    <button
      class='
        appointment-grid__timeslot__item
        appointment-grid__timeslot__item--appointment
      '
      data-css-occupied-type="${occupiedType}"
      onclick='shamefullyInlinedRenderAppointmentDetails(${appointmentId})'
      data-toggle='modal'
      data-target='#appointment-details-modal'
    >
      <span class="u-display--block">
        ${startTime} -
        <strong>
          ${String(firstName)}
          ${String(lastName)}
        </strong>
      </span>

      ${serviceTitle}

      ${missedAppointmentText}
    </button>
  `;

	return html;
}

/**
 * TODO: LB - use HTML templates and HTMLElement.appendChild instead of
 * rendering strings with innerHTML - innerHTML should be considered
 * unsafe.
 *
 * See http://developer.mozilla.org/en-US/docs/Web/API/Element/innerHTML#security_considerations
 */
function renderReserved(timeSlot: AppointmentTimeSlot<Appointment>): string {
	const { appointment } = timeSlot;
	// TODO: LB - migrate 'first_name' to 'notes' for all appointments -
	// should use a more descriptive field than 'first_name' to render a title
	const { reserve_time_slot_name: reserveTimeSlotName, notes } = appointment;
	const updaterName = appointment.last_emp_id || "";
	const updatedBy = updaterName
		? `
      <span class="u-display--block u-padding--smallest--block-start">
        Updated by: ${updaterName}
      </span>
    `
		: "";
	const title = notes || reserveTimeSlotName || "(untitled)";
	const html = `
    <button
      class='
        appointment-grid__timeslot__item
        appointment-grid__timeslot__item--reserved
        js-appointment-grid-reserved-time-slot
      '
      data-toggle='modal'
      data-target='#appointment-grid-delete-reserved-time-slot-modal'
      data-appointment-id='${appointment.id}'
      data-appointment-name='${appointment.reserve_time_slot_name}'
    >
      <span class="gw gw--small">
        <span class="g g--shrink-wrap">
          <span class="u-fs--base u-va--middle">
            <svg width='1em' height='1em' viewBox='0 0 16 16' class='bi bi-calendar-x' fill='currentColor' xmlns='https://www.w3.org/2000/svg'>
              <path fill-rule='evenodd' d='M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z' />
              <path fill-rule='evenodd' d='M6.146 7.146a.5.5 0 0 1 .708 0L8 8.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 9l1.147 1.146a.5.5 0 0 1-.708.708L8 9.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 9 6.146 7.854a.5.5 0 0 1 0-.708z' />
            </svg>
          </span>
        </span>

        <span class="g g--auto">
          <span class="gw">
            <span class="g g--1-of-1">
              <span class="u-display--block u-padding--smallest--block-start">
                ${title}
              </span>
            </span>

            <span class="g g--1-of-1">
              ${updatedBy}
            </span>
          </span>
        </span>
      </span>
    </button>
  `;

	return html;
}

/**
 * TODO: LB - use HTML templates and HTMLElement.appendChild instead of
 * rendering strings with innerHTML - innerHTML should be considered
 * unsafe.
 *
 * See http://developer.mozilla.org/en-US/docs/Web/API/Element/innerHTML#security_considerations
 */
function renderAvailable(timeSlot: AppointmentTimeSlot): string {
	const { date, staff_resource: staffResource, times } = timeSlot;
	const { start: startTime, end: endTime } = times;
	const html = `
    <div
      class='
        appointment-grid__timeslot__item
        appointment-grid__timeslot__item--available
      '
    >
      <div class="gw gw--no-gutter gw--middle">
        <div class="g g--shrink-wrap">
          <button
            class='appointment-grid__timeslot__reserve-trigger js-appointment-grid-reserve-appointment'
            data-date="${date}"
            data-end-time="${endTime}"
            data-staff-resource-id="${staffResource.id}"
            data-start-time="${startTime}"
          >
            <span class="u-fs--base">
              <svg width='1em' height='1em' viewBox='0 0 16 16' class='bi bi-dash-circle u-display--block' fill='currentColor' xmlns='https://www.w3.org/2000/svg'>
                <path fill-rule='evenodd' d='M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z' />
                <path fill-rule='evenodd' d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z' />
              </svg>
            </span>


          </button>
        </div>

        <div class="g g--auto">
          <button
            class="
              appointment-grid__timeslot__modal-trigger
              ${GET_APPOINTMENT_DATA_CLASS}
            "
            data-toggle="modal"
            data-target="#appointmentModal"
            data-appointment-data-appointmentDate="${date}"
            data-appointment-data-appointmentTime="${startTime}"
            data-appointment-data-staffResourceId="${staffResource.id}"
            data-appointment-data-endTime="${endTime}"
          >
            <span class="gw gw--small gw--middle">
              <span class="g g--shrink-wrap">
                <span class="u-fs--base">
                  <svg width='1em' height='1em' viewBox='0 0 16 16' class='bi bi-calendar-plus u-display--block' fill='currentColor' xmlns='https://www.w3.org/2000/svg'>
                    <path fill-rule='evenodd' d='M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z' />
                    <path fill-rule='evenodd' d='M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7z' />
                  </svg>
                </span>
              </span>

              <span class="g g--auto">
                ${dropSeconds(startTime)}
              </span>
            </span>
          </button>
        </div>
      </div>
    </div>
  `;

	return html;
}

/**
 * TODO: LB - use HTML templates and HTMLElement.appendChild instead of
 * rendering strings with innerHTML - innerHTML should be considered
 * unsafe.
 *
 * See http://developer.mozilla.org/en-US/docs/Web/API/Element/innerHTML#security_considerations
 */
function renderClosed(_timeSlot: AppointmentTimeSlot): string {
	const html = `
    <div
      class='
        appointment-grid__timeslot__item
        appointment-grid__timeslot__item--blank
      '
    >
    </div>
  `;

	return html;
}

function renderUnknown(timeSlot: AppointmentTimeSlot): string {
	return renderClosed(timeSlot);
}

/**
 * TODO: LB - use HTML templates and HTMLElement.appendChild instead of
 * rendering strings with innerHTML - innerHTML should be considered
 * unsafe.
 *
 * See http://developer.mozilla.org/en-US/docs/Web/API/Element/innerHTML#security_considerations
 */
function renderTimeLabels(
	times: Array<AppointmentTimeSlot["times"]["start" | "end"]>,
): string {
	const html = times
		.map((time) => {
			const markup = `
          <strong class="appointment-grid__time-label"
              style="
                grid-row: ${getGridRowName(time)};
                grid-column: ${GridTemplateRowName.TimeLabels};
              ">
            ${dropSeconds(time)}
          </strong>
      `;

			return markup;
		})
		.join("");

	return html;
}

function getTimeSlotStyles(
	timeSlot: AppointmentTimeSlot<unknown>,
	rowNamePrefix = GridTemplateRowName.TimeSlotPrefix,
): string {
	const { times } = timeSlot;
	const { start, end } = times;
	const [rowStart, rowEnd] = [
		getGridRowName(start, rowNamePrefix),
		getGridRowName(end, rowNamePrefix),
	];
	const gridStyles = `
    grid-row: ${rowStart} / ${rowEnd};
  `;

	return gridStyles;
}

function getTimeSlotGroupStyles(
	timeSlots: AppointmentTimeSlot<unknown>[],
	times: Times,
): string {
	const firstTimeSlot = timeSlots.find(Boolean);

	if (!firstTimeSlot) {
		return "";
	}

	const minStartTime = timeSlots.map(({ times }) => times.start).find(Boolean);
	const maxEndTime = timeSlots
		.map(({ times }) => times.end)
		.slice()
		.reverse()
		.find(Boolean);
	const { staff_resource: staffResource } = firstTimeSlot;
	const groupTimes = times.filter((x) => {
		return minStartTime && maxEndTime && x >= minStartTime && x < maxEndTime;
	});
	const rowsTemplate = groupTimes
		.map((time) => {
			const value = `[${getGridRowName(
				time,
				GridTemplateRowName.GroupTimeSlotPrefix,
			)}] 1fr`;

			return value;
		})
		.join("\n");

	const gridStyles = `
    grid-template-rows: ${rowsTemplate};
    grid-column: ${getGridColumnName(staffResource)};
    grid-row: ${getGridRowName(minStartTime || "")} / ${getGridRowName(
			maxEndTime || "",
		)};
  `;

	return gridStyles;
}

/**
 * Removes the seconds from a time given in the format HH:MM:SS
 */
function dropSeconds(time: string): string {
	const [hours, minutes, _] = time.split(":");

	return [hours, minutes].join(":");
}

function getTimeSlotId(timeSlot: AppointmentTimeSlot<unknown>): string {
	const { date, times, staff_resource: staffResource } = timeSlot;
	const { start, end } = times;
	const id = [
		"grid-time-slot",
		staffResource.id,
		date,
		`${dropSeconds(start)}`,
		`${dropSeconds(end)}`,
	].join("-");

	return id;
}

function getGridRowsTemplate(
	timeSlots: AppointmentTimeSlot[],
	rowNamePrefix = GridTemplateRowName.TimeSlotPrefix,
) {
	const gridRows = [`[${GridTemplateRowName.ResourceHeadings}] auto`]
		.concat(getGridRowsTimeSlotsTemplate(timeSlots, rowNamePrefix))
		.join("\n");

	return gridRows;
}

function getGridRowsTimeSlotsTemplate(
	timeSlots: AppointmentTimeSlot[],
	rowNamePrefix = GridTemplateRowName.TimeSlotPrefix,
) {
	const orderedStartTimes = orderTimes(timeSlots, "start");
	const gridRows = orderedStartTimes
		.map((time) => {
			const value = `[${getGridRowName(time, rowNamePrefix)}] auto`;

			return value;
		})
		.join("\n");

	return gridRows;
}

function orderTimes(
	timeSlots: AppointmentTimeSlot[],
	key: keyof AppointmentTimeSlot["times"],
) {
	const times = new Set(timeSlots.map(({ times }) => times[key]));
	const orderedTimes = Array.from(times).sort();

	return orderedTimes;
}

function getGridRowName(
	time: AppointmentTimeSlot["times"]["start" | "end"],
	rowNamePrefix = GridTemplateRowName.TimeSlotPrefix,
) {
	const normalizedTime = dropSeconds(time).replaceAll(":", "");
	const row = `${rowNamePrefix}-${normalizedTime}`;

	return row;
}

type ColumnMap = Map<
	AppointmentTimeSlot["staff_resource"]["id"],
	{
		staffSchedule: AppointmentTimeSlot["staff_schedule"];
		staffResource: AppointmentTimeSlot["staff_resource"];
	}
>;

function getGridColumnsTemplate(timeSlots: AppointmentTimeSlot[]) {
	const staffResourceMap: ColumnMap = timeSlots.reduce((acc, timeSlot) => {
		const { staff_resource: staffResource, staff_schedule: staffSchedule } =
			timeSlot;

		acc.set(staffResource.id, { staffResource, staffSchedule });

		return acc;
	}, new Map());
	const staffResources = [...staffResourceMap.values()]
		.sort((a, b) => {
			const { staffSchedule: scheduleA } = a;
			const { staffSchedule: scheduleB } = b;
			const [orderingA, orderingB] = [
				scheduleA.ordering || Number.MAX_SAFE_INTEGER,
				scheduleB.ordering || Number.MAX_SAFE_INTEGER,
			];

			return orderingA > orderingB ? 1 : -1;
		})
		.map(({ staffResource }) => staffResource);
	const columnNames = staffResources.map((column, index, xs) => {
		const previousColumn = xs[index - 1];
		const columnA = previousColumn
			? `${getGridColumnName(previousColumn)}-end`
			: "";
		const columnB = `${getGridColumnName(column)}-start`;
		const columnName = [columnA, columnB].filter(Boolean).join(" ");
		const value = `[${columnName}] auto`;

		return value;
	});
	const template = [
		`[${GridTemplateRowName.TimeLabels}] 0fr`,
		...columnNames,
	].join("\n");

	return template;
}

function getGridColumnName(
	staffResource: AppointmentTimeSlot["staff_resource"],
) {
	const value = `${GridTemplateRowName.ResourcePrefix}-${staffResource.id}`;

	return value;
}

export {
	getGridColumnsTemplate,
	getGridRowsTemplate,
	getTimeSlotId,
	orderTimes,
	renderTimeLabels,
	renderTimeSlot,
	renderTimeSlotGroups,
};
