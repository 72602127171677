import { boolean, number, string } from "yup";
import type { MatchOptions } from "yup/es/string";
import type { StringLocale } from "yup/lib/locale";

import { URL_REGEX } from "$applib/constants/regex";
import { format } from "$applib/i18n";

const url = (
	options:
		| StringLocale["matches"]
		| { message?: StringLocale["matches"]; excludeEmptyString?: boolean },
) => string().matches(URL_REGEX, options);

const transformedBoolean = (message?: string) =>
	boolean()
		.oneOf([true, false], message)
		.transform((value) => {
			const result =
				typeof value === "string" ? /true/.test(value) : Boolean(value);

			return result;
		});

const telephone = (
	options: Partial<MatchOptions> = { excludeEmptyString: true },
) =>
	string()
		.matches(/^\+?[\d\s./]*\(?\d{1,4}\)?[\d\s./-]*$/g, {
			message: format("validations.telephone.format"),
			...options,
		})
		.matches(/(.*\d){8,}/g, {
			message: format("validations.telephone.minLength"),
			...options,
		});

const email = () => string().email(format("validations.email.format"));

const safeNumber = () =>
	number()
		.transform((value) => {
			return Number.isNaN(value) || typeof value !== "number" ? null : value;
		})
		.nullable();

export { email, safeNumber, telephone, transformedBoolean, url };
