import { config } from "$applib/configs/application";
import { CustomDomEvent } from "$applib/enums/events";
import { buildHeaders, getCsrfTokenHeader } from "$applib/utils/headers";

import type { AppointmentTimeSlot } from "$applib/types/resources/appointments";

import {
	getGridColumnsTemplate,
	getGridRowsTemplate,
	orderTimes,
	renderTimeLabels,
	renderTimeSlotGroups,
} from "./shared/utils/appointment-grid";

const { path: getAppointmentsEndpoint } =
	config.urls.api.appointments.listAsTimeslots;

function renderAppointmentGrid(containerEl: HTMLElement) {
	const dataAttributePrefix = "data-render-appointment-grid";
	const date = containerEl.getAttribute(`${dataAttributePrefix}-date`) || "";
	const locationId =
		containerEl.getAttribute(`${dataAttributePrefix}-location-id`) || "0";
	const queryParams = new URLSearchParams({ date, "location-id": locationId });
	const url = [getAppointmentsEndpoint, queryParams.toString()].join("?");

	fetch(url, {
		method: "GET",
		headers: buildHeaders({
			...getCsrfTokenHeader(),
			"Content-Type": "application/json",
			Accept: "application/json",
		}),
	})
		.then((response) => response.json())
		.then((response) => {
			if (response.instance == "Success") {
				const timeSlots: AppointmentTimeSlot[] = response.results;
				const times = orderTimes(timeSlots, "start");
				const timeLabelsMarkup = renderTimeLabels(times);
				const timeSlotsByResource = timeSlots.reduce((acc, timeSlot) => {
					const resourceTimeSlots = acc.get(timeSlot.staff_resource.id) || [];

					acc.set(
						timeSlot.staff_resource.id,
						resourceTimeSlots.concat(timeSlot),
					);

					return acc;
				}, new Map<number, AppointmentTimeSlot[]>());
				// TODO: LB - simplify and clarify this complexity
				const groupedTimeSlotsByResource = Array.from(
					timeSlotsByResource.entries(),
				).reduce((acc, [resourceId, resourceTimeSlots]) => {
					const evaluated: Record<string, boolean> = {};
					let timeSlotGroups: AppointmentTimeSlot[][] = [];

					// build groups of overlapping time slots
					resourceTimeSlots
						// ensure time slots are ordered by start time
						.sort((a, b) => (a.times.start <= b.times.start ? -1 : 1))
						.forEach((timeSlot: AppointmentTimeSlot, index: number) => {
							// if we've evaluated the time slot, move on
							if (evaluated[JSON.stringify(timeSlot)]) {
								return;
							}

							let overlappingTimeSlots = [timeSlot];
							let maxEndTime = timeSlot.times.end;

							// for all time slots that are later than the current...
							resourceTimeSlots.slice(index + 1).forEach((x) => {
								// if the start time of the current time slot is less than the
								// max end time of the group, then we have an overlap in the
								// resource
								if (x.times.start < maxEndTime) {
									const endTimes = [maxEndTime, x.times.end].sort().reverse();

									// add the time slot to the group of overlapping time slots
									overlappingTimeSlots = overlappingTimeSlots.concat(x);
									// set the new end time for the overlapping appointments
									maxEndTime = endTimes.find(Boolean) || maxEndTime;
									evaluated[JSON.stringify(x)] = true;
								}
							});

							timeSlotGroups = timeSlotGroups.concat([overlappingTimeSlots]);
						});

					acc.set(resourceId, timeSlotGroups);

					return acc;
				}, new Map());
				const timeSlotsHtml = [...groupedTimeSlotsByResource.values()].map(
					(groups: Array<AppointmentTimeSlot[]>) => {
						return groups.map((group, groupIndex) => {
							const shouldRenderHeader = groupIndex == 0;

							return renderTimeSlotGroups(group, times, shouldRenderHeader);
						});
					},
				);
				const gridRowsTemplate = getGridRowsTemplate(timeSlots);
				const gridColumnsTemplate = getGridColumnsTemplate(timeSlots);

				containerEl.innerHTML = [timeLabelsMarkup]
					.concat(...timeSlotsHtml)
					.join("");
				containerEl.style.setProperty("--grid-rows-template", gridRowsTemplate);
				containerEl.style.setProperty(
					"--grid-columns-template",
					gridColumnsTemplate,
				);

				const event = new CustomEvent(CustomDomEvent.AppointmentGridRendered);

				document.dispatchEvent(event);
			}
		})
		.catch((error) => {
			console.error(error);
		});
}

export { renderAppointmentGrid };
