import { fromPromise, setup } from "xstate";
import { assign } from "xstate/actions";

import type { DoneActorEvent, ErrorActorEvent, MachineContext } from "xstate";

import type { ApiError } from "$applib/types/errors";
import type { ApiResponse } from "$applib/types/request-response";

import { identity, noop } from "$applib/utils/functions";

export type DoneEvent<TEntity extends object> = DoneActorEvent<
	ApiResponse<TEntity>
>;
type ErrorEvent = ErrorActorEvent<ApiError>;

// NOTE: xstate.[done|error].actor.${string} events occur when invoked services
// succeed or fail
export enum EventType {
	Delete = "http-delete",
	DeleteDone = "xstate.done.actor.delete",
	DeleteError = "xstate.error.actor.delete",
	Get = "http-get",
	GetDone = "xstate.done.actor.get",
	GetError = "xstate.error.actor.get",
	Head = "http-head",
	HeadDone = "xstate.done.actor.head",
	HeadError = "xstate.error.actor.head",
	Patch = "http-patch",
	PatchDone = "xstate.done.actor.patch",
	PatchError = "xstate.error.actor.patch",
	Post = "http-post",
	PostDone = "xstate.done.actor.post",
	PostError = "xstate.error.actor.post",
	Put = "http-put",
	PutDone = "xstate.done.actor.put",
	PutError = "xstate.error.actor.put",
	Reset = "reset",
}

interface ResourceMachineContext<TEntity = Record<string, unknown>>
	extends MachineContext {
	error: ApiError;
	item?: TEntity;
	items: TEntity[];
}

const requestEvents = {
	[EventType.Delete]: { target: "#state-delete" },
	[EventType.Get]: { target: "#state-get" },
	[EventType.Head]: { target: "#state-head" },
	[EventType.Patch]: { target: "#state-patch" },
	[EventType.Post]: { target: "#state-post" },
	[EventType.Put]: { target: "#state-put" },
	[EventType.Reset]: { target: "#state-idle" },
};

function resourceMachineFactory<
	TResponse extends object = object,
	TRequestPayload extends object = object,
	TContextExtra extends object = object,
>(id = "resource-machine") {
	const inputFn = identity<ResourceMachineContext<TResponse> & TContextExtra>;

	const machine = setup({
		types: {} as {
			actions:
				| { type: "setError" }
				| { type: "setResponse" }
				| { type: "onError" }
				| { type: "onSuccess" };
			context: ResourceMachineContext<TResponse & TContextExtra>;
			events:
				| DoneEvent<TResponse & object>
				| ErrorEvent
				| { type: EventType.Delete; params: TRequestPayload }
				| { type: EventType.Get }
				| { type: EventType.Get; params: TRequestPayload }
				| { type: EventType.Head }
				| { type: EventType.Head; params: TRequestPayload }
				| { type: EventType.Patch; params: TRequestPayload }
				| { type: EventType.Post; params: TRequestPayload }
				| { type: EventType.Put; params: TRequestPayload }
				| { type: EventType.Reset };
			input: Partial<ResourceMachineContext<TResponse & TContextExtra>>;
		},
		actions: {
			setError: assign(({ event }) => {
				const { error: responseError } = event as ErrorEvent;

				const error =
					// normalise server errors to use the same shape as our custom errors
					responseError instanceof Error
						? {
								codes: [],
								messages: { [responseError.name]: [responseError.message] },
								status: responseError.status,
							}
						: responseError;

				// TODO: LB - determine if we need to explicitly log errors that are
				// instances of Error for Sentry / LogRocket etc.

				return { error };
			}),

			setResponse: assign(({ event }) => {
				const { output } = event as DoneEvent<TResponse & object>;
				const property = Array.isArray(output) ? "items" : "item";

				return { [property]: output };
			}),

			onError: noop,
			onSuccess: noop,
		},

		actors: {
			delete: fromPromise(Promise.resolve),
			get: fromPromise(Promise.resolve),
			head: fromPromise(Promise.resolve),
			patch: fromPromise(Promise.resolve),
			post: fromPromise(Promise.resolve),
			put: fromPromise(Promise.resolve),
		},
	}).createMachine({
		id,
		initial: "idle",
		context: ({ input }) => ({
			error: { codes: [], messages: {}, status: 0 },
			items: [],
			...input,
		}),

		states: {
			idle: { id: "state-idle", on: requestEvents },

			get: {
				initial: "requesting",
				id: "state-get",

				states: {
					requesting: {
						tags: ["requesting"],
						invoke: {
							id: "get",
							src: "get",

							input: inputFn,
							onDone: { target: "success" },
							onError: { target: "error" },
						},
					},
					error: {
						on: requestEvents,
						tags: ["error"],
						entry: ["setError", "onError"],
					},
					success: {
						on: requestEvents,
						tags: ["success"],
						entry: ["setResponse", "onSuccess"],
					},
				},
			},

			head: {
				initial: "requesting",
				id: "state-head",

				states: {
					requesting: {
						tags: ["requesting"],
						invoke: {
							id: "head",
							src: "head",

							input: inputFn,
							onDone: { target: "success" },
							onError: { target: "error" },
						},
					},
					error: {
						on: requestEvents,
						tags: ["error"],
						entry: ["setError", "onError"],
					},
					success: {
						on: requestEvents,
						tags: ["success"],
						entry: ["setResponse", "onSuccess"],
					},
				},
			},

			post: {
				initial: "requesting",
				id: "state-post",

				states: {
					requesting: {
						tags: ["requesting"],
						invoke: {
							id: "post",
							src: "post",

							input: inputFn,
							onDone: { target: "success" },
							onError: { target: "error" },
						},
					},
					error: {
						on: requestEvents,
						tags: ["error"],
						entry: ["setError", "onError"],
					},
					success: {
						on: requestEvents,
						tags: ["success"],
						entry: ["setResponse", "onSuccess"],
					},
				},
			},

			put: {
				initial: "requesting",
				id: "state-put",

				states: {
					requesting: {
						tags: ["requesting"],
						invoke: {
							id: "put",
							src: "put",

							input: inputFn,
							onDone: { target: "success" },
							onError: { target: "error" },
						},
					},
					error: {
						on: requestEvents,
						tags: ["error"],
						entry: ["setError", "onError"],
					},
					success: {
						on: requestEvents,
						tags: ["success"],
						entry: ["setResponse", "onSuccess"],
					},
				},
			},

			patch: {
				initial: "requesting",
				id: "state-patch",

				states: {
					requesting: {
						tags: ["requesting"],
						invoke: {
							id: "patch",
							src: "patch",

							input: inputFn,
							onDone: { target: "success" },
							onError: { target: "error" },
						},
					},
					error: {
						on: requestEvents,
						tags: ["error"],
						entry: ["setError", "onError"],
					},
					success: {
						on: requestEvents,
						tags: ["success"],
						entry: ["setResponse", "onSuccess"],
					},
				},
			},

			delete: {
				initial: "requesting",
				id: "state-delete",

				states: {
					requesting: {
						tags: ["requesting"],
						invoke: {
							id: "delete",
							src: "delete",

							input: inputFn,
							onDone: { target: "success" },
							onError: { target: "error" },
						},
					},
					error: {
						tags: ["error"],
						entry: ["setError", "onError"],
						on: requestEvents,
					},
					success: {
						tags: ["success"],
						entry: ["setResponse", "onSuccess"],
						on: requestEvents,
					},
				},
			},
		},
	});

	return machine;
}

export { resourceMachineFactory };
