const connectionStatusEl = document.querySelector<HTMLElement>(
	".js-connection-status",
);

/**
 * networkStatusFactory.
 *
 * Factory function which accepts an element, and then allows for
 * showing / hiding that element given network conditions
 *
 * @param {HTMLElement} el
 */
function networkStatusFactory(el: HTMLElement) {
	const element = el;
	const show = handleUpdate.bind(null, true);
	const hide = handleUpdate.bind(null, false);

	function handleUpdate(isOnline: boolean) {
		element.style.display = isOnline ? "none" : "";
		element.setAttribute("aria-hidden", `${isOnline}`);
	}

	return { show, hide, handleUpdate };
}

if (connectionStatusEl) {
	const networkStatus = networkStatusFactory(connectionStatusEl);

	window.addEventListener("load", () => {
		networkStatus.handleUpdate(navigator.onLine);

		window.addEventListener("online", networkStatus.show);
		window.addEventListener("offline", networkStatus.hide);
	});
}
