import type { AppointmentType } from "$applib/enums/resources/appointments";
import type { AppointmentService } from "./appointment-services";
import type { CompanyLocation } from "./company-locations";
import type { Organisation } from "./organizations";
import type { Patient } from "./patients";
import type { Model } from "./shared/model";

enum BooleanishAppointmentField {
	Yes = "yes",
	No = "no",
}

// see AppointmentPlusAppointments in backend
export interface Appointment extends Model {
	patient?: Patient["id"];
	date?: string; // from Date

	created_at: string; // from Date
	updated_at: string; // from Date
	deleted_at?: string; // from Date

	start_time?: string; // from Date
	end_time?: string; // from Date

	location: CompanyLocation["id"];
	appointment_type: AppointmentType;
	reserve_time_slot_name: string;

	appt_id: string;

	lead_description?: string;
	notes?: string;
	show_appointment?: BooleanishAppointmentField;

	employee_id?: string;
	staff_screen_name?: string;

	service: AppointmentService;

	status_id?: string;

	appt_status_description?: string;
	appt_status_type?: string;

	last_emp_id?: string;

	organisation: Organisation["id"];

	arr_id?: string;
	arrived?: BooleanishAppointmentField;

	complete?: BooleanishAppointmentField;
	complete_timestamp?: string; // from Date
	discussion?: BooleanishAppointmentField;
	overdue_account?: BooleanishAppointmentField;
	settled_account?: BooleanishAppointmentField;
	not_settled_account?: BooleanishAppointmentField;
	cancelled_appointment?: BooleanishAppointmentField;

	clear_queue?: BooleanishAppointmentField;
	clear_timestamp?: string; // from Date
	complete_clear_queue?: BooleanishAppointmentField;
	complete_clear_timestamp?: string; // from Date
	deband?: string;

	virtual_appointment_pending?: BooleanishAppointmentField;
	virtual_appointment_timestamp?: string; // from Date
	virtual_appointment_upload?: BooleanishAppointmentField;
	hide_virtual_appointment?: BooleanishAppointmentField;

	whatsapp_images?: BooleanishAppointmentField;

	// fields not native to model
	// TODO: LB - use annotation to include transgressions in transmitted data
	extra_missed_appointment?: boolean;
}

export type AppointmentDeep = Appointment & {
	patient?: Patient;
	location: CompanyLocation;
	organisation: Organisation;
};

enum StaffResourceBookingPermission {
	STAFF_ONLY = "staff-only",
	STAFF_AND_PATIENTS = "staff-and-patients",
}

// see AppointmentPlus_Staff_Members in backend
interface StaffResource {
	booking_permission: StaffResourceBookingPermission;
	id: number;
	order: number;
	organisation: string;
	title: string;

	address?: string;
	appt_advance?: string;
	bill_rate?: string;
	bio?: string;
	c_id?: string;
	carrier_id?: string;
	cell_phone?: string;
	city?: string;
	company?: string;
	display?: string;
	email?: string;
	employee_id: string;
	entity_type_id?: string;
	fax?: string;
	first_name?: string;
	hide_views?: string;
	home_phone?: string;
	last_name?: string;
	location_id?: string;
	login?: string;
	middle_name?: string;
	notes?: string;
	picture_link?: string;
	relay1_id?: string;
	relay2_id?: string;
	sales_person_id?: string;
	screen_name?: string;
	sort_order?: string;
	state?: string;
	status?: string;
	tax_number?: string;
	timezone_id?: string;
	type_id?: string;
	user_group?: string;
	work_phone?: string;
	zip?: string;
}

// see AppointmentPlus_Staff_Schedules in backend
interface StaffSchedule {
	location_id?: string;
	employee_id?: StaffResource["id"];
	first_appt_time_Monday?: string; // from datetime.time
	last_appt_time_Monday?: string; // from datetime.time
	off_Monday?: string;
	location_Monday?: number; // from Company_Locations.pk

	first_appt_time_Tuesday?: string; //from datetime.time
	last_appt_time_Tuesday?: string; // from datetime.time
	off_Tuesday?: string;
	location_Tuesday?: number; // from Company_Locations.pk

	first_appt_time_Wednesday?: string; //from datetime.time
	last_appt_time_Wednesday?: string; // from datetime.time
	off_Wednesday?: string;
	location_Wednesday?: number; // from Company_Locations.pk

	first_appt_time_Thursday?: string; //from datetime.time
	last_appt_time_Thursday?: string; // from datetime.time
	off_Thursday?: string;
	location_Thursday?: number; // from Company_Locations.pk

	first_appt_time_Friday?: string; //from datetime.time
	last_appt_time_Friday?: string; // from datetime.time
	off_Friday?: string;
	location_Friday?: number; // from Company_Locations.pk

	first_appt_time_Saturday?: string; //from datetime.time
	last_appt_time_Saturday?: string; // from datetime.time
	off_Saturday?: string;
	location_Saturday?: number; // from Company_Locations.pk

	first_appt_time_Sunday?: string; //from datetime.time
	last_appt_time_Sunday?: string; // from datetime.time
	off_Sunday?: string;
	location_Sunday?: number; // from Company_Locations.pk
	ordering?: number;
	organisation: number; // Organisation.pk
}

// _TimeSlotState in appointment_grid_load_js.py in backend
export enum TimeSlotState {
	Available = "available",
	Closed = "closed",
	Occupied = "occupied",
	Reserved = "reserved",
	Unknown = "unknown",
}

interface Times {
	start: string; // from Date
	end: string; // from Date
}

// see appointment_grid_load_js.py in backend
export interface AppointmentTimeSlot<T = null> {
	appointment: T;
	service: AppointmentService;
	color?: string;
	date: string; // from Date;
	patient?: Patient;
	staff_resource: StaffResource;
	// TODO: LB - remove either this property, or staff_resource - used
	// exclusively for ordering columns
	staff_schedule: StaffSchedule;
	state: TimeSlotState;
	times: Times;
}
