import { format } from "date-fns";

/**
 * humanizeTime.
 *
 * Accepts a time in 24 hour format (hh:mm::ss) and returns a more human-friendly
 * time with am or pm
 *
 * @param {string} time
 */
function humanizeTime(time: string) {
	const [hour, minute] = time.split(":");
	const amPm = Number.parseInt(hour) >= 12 ? "pm" : "am";

	return `${hour}:${minute} ${amPm}`;
}

/**
 * humanizeDate
 *
 * Accepts a date, formatting it in the same way as DATE_FORMAT
 *
 * @param {Date} date
 */
function humanizeDate(date: Date) {
	return format(date, "MMMM dd, yyyy");
}

export { humanizeTime, humanizeDate };
