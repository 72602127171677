import { filterDomNodesFactory } from "$applib/utils/dom-manipulation/filter-dom-nodes";

filterDomNodesFactory({
	inputSelector: ".js-retainer-waiting-name-input",
	itemsSelector: ".js-retainer-waiting-patient-item",
	getItemText: (element) => {
		const anchorEl = element.querySelector<HTMLAnchorElement>(
			".js-retainer-waiting-patient-name",
		);

		return anchorEl ? anchorEl.innerText : "";
	},
});
