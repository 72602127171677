import { createActor } from "xstate";
import { assign } from "xstate/actions";

import type {
	ActorRefFrom,
	ContextFrom,
	MachineImplementationsFrom,
} from "xstate";

import { safeParseFloat, safeParseInt } from "$applib/utils/numbers";
import {
	type InvoiceGeneratorMachine,
	invoiceGeneratorMachine,
} from "../shared/machines/generator";
import { invoiceSetMachine } from "../shared/machines/invoice-sets";
import { lineItemMachine } from "../shared/machines/line-items";
import {
	dueDatesFetchCreator,
	ioEventDispatchCreator as generatorIoDispatchCreator,
} from "./generator/actors";
import { createDomSelector as createGeneratorDomSelector } from "./generator/utils";
import { ioDispatchCreator as invoiceSetIoDispatchCreator } from "./invoice-sets/actors";
import { createDomSelector as createInvoiceSetDomSelector } from "./invoice-sets/utils";
import { ioDispatchCreator as lineItemIoDispatchCreator } from "./line-items/actors";
import { createDomSelector as createLineItemDomSelector } from "./line-items/utils";

import type { InvoiceSetDateConfig } from "../shared/types";

import type { InvoiceSetStateMachine } from "../shared/machines/invoice-sets";

import { InvoiceSetDateType, PriceType } from "../shared/enums";

const FORM_SELECTOR = "form[data-invoice-generator-form]";

type InvoiceSetMachineImplementations =
	MachineImplementationsFrom<InvoiceSetStateMachine>;

type InvoiceGeneratorMachineImplementations =
	MachineImplementationsFrom<InvoiceGeneratorMachine>;

const invoiceSetActions: InvoiceSetMachineImplementations["actions"] = {
	spawnLineItem: assign(({ context: { lineItems }, spawn }, params) => {
		const { element } = params;
		const xs = createLineItemDomSelector(element);
		const priceTypeInput = [...xs.getPriceTypeInputs()].find(
			(el) => el.checked,
		);
		const quantity = safeParseFloat(xs.getQuantityInput().value, 0.0);
		const price = safeParseFloat(xs.getPriceInput().value, 0.0);

		// @ts-ignore-next-line
		const service = spawn(
			lineItemMachine.provide({
				actors: { ioEventDispatcher: lineItemIoDispatchCreator(element) },
				guards: {
					isMarkedForDeletion: () => xs.getDeleteInput().checked,
				},
			}),
			{
				// TODO: LB - determine why the presence of this property raises
				// the TS errors that we're ignoring above
				id: element.dataset.formPrefix || "",
				input: {
					order: safeParseInt(xs.getOrderInput().value, 0),
					price,
					priceType: priceTypeInput
						? (priceTypeInput.value as PriceType)
						: PriceType.Fixed,
					quantity,
					total: quantity * price,
				},
			},
		);

		return {
			lineItems: lineItems.concat({ ref: service } as unknown as {
				ref: ActorRefFrom<typeof lineItemMachine>;
			}),
		};
	}),
};

function getInvoiceSetInput(
	xs: ReturnType<typeof createInvoiceSetDomSelector>,
): Partial<ContextFrom<typeof invoiceSetMachine>> {
	const dateType =
		(Array.from(xs.getDateTypeInputs())
			.filter((el) => el.checked)
			.map((el) => el.value)
			.find(Boolean) as InvoiceSetDateType) || InvoiceSetDateType.Ordinal;

	return {
		dateConfig: {
			cardinalPeriod: xs.getDateCardinalPeriodInput().value,
			cardinalValue: safeParseInt(xs.getDateCardinalValueInput().value, 0),
			minDuration: safeParseInt(xs.getDateMinDurationInput().value, 0),
			minDurationPeriod: xs.getDateMinDurationPeriodInput().value,
			ordinalPeriod: xs.getDateOrdinalPeriodInput().value,
			ordinalValueMonthly: safeParseInt(
				xs.getDateOrdinalValueMonthlyInput().value,
				1,
			),
			ordinalValueWeekly: safeParseInt(
				xs.getDateOrdinalValueWeeklyInput().value,
				1,
			),
			dateType,
		} as InvoiceSetDateConfig,
		numInvoices: safeParseInt(xs.getNumInvoicesInput().value, 1),
		order: safeParseInt(xs.getOrderInput().value, 0),
	};
}

const generatorActions: InvoiceGeneratorMachineImplementations["actions"] = {
	spawnInvoiceSet: assign(
		({ context: { invoiceSets }, spawn }, { element }) => {
			const xs = createInvoiceSetDomSelector(element);
			const input = getInvoiceSetInput(xs);
			// @ts-ignore-next-line
			const service = spawn(
				invoiceSetMachine.provide({
					actions: invoiceSetActions,
					actors: { ioEventDispatcher: invoiceSetIoDispatchCreator(element) },
					guards: { isMarkedForDeletion: () => xs.getDeleteInput().checked },
				}),
				{
					// TODO: LB - determine why the presence of this property raises
					// the TS errors that we're ignoring above
					id: element.dataset.formPrefix || "",
					input,
				},
			);

			return {
				invoiceSets: invoiceSets.concat({
					ref: service,
				} as unknown as { ref: ActorRefFrom<typeof invoiceSetMachine> }),
			};
		},
	),
};

function invoiceGeneratorForm(formEl: HTMLFormElement) {
	const { getRefDateInput, getTotalInput } = createGeneratorDomSelector(formEl);
	const refDateInput = getRefDateInput();
	const refDate = new Date(refDateInput.value).getDate()
		? new Date(refDateInput.value)
		: new Date();
	const service = createActor(
		invoiceGeneratorMachine.provide({
			actions: generatorActions,
			actors: {
				ioEventDispatcher: generatorIoDispatchCreator(formEl),
				dueDatesFetcher: dueDatesFetchCreator(formEl),
			},
		}),
		{
			input: {
				refDate,
				total: safeParseFloat(getTotalInput().value, 0.0),
			},
		},
	);

	service.start();

	return service;
}

for (const form of document.querySelectorAll<HTMLFormElement>(FORM_SELECTOR)) {
	invoiceGeneratorForm(form);
}
