/**
 * safeParseFloat
 *
 * Accepts a string and returns a float, or the fallback value if a float
 * cannot be parsed
 */
function safeParseFloat(value: string, fallback: number) {
	const x = Number.parseFloat(value);

	return Number.isNaN(x) ? fallback : x;
}

/**
 * safeParseInt
 *
 * Accepts a string and returns a integer, or the fallback value if an integer
 * cannot be parsed
 */
function safeParseInt(value: string, fallback: number, radix = 10) {
	const x = Number.parseInt(value, radix);

	return Number.isNaN(x) ? fallback : x;
}

export { safeParseFloat, safeParseInt };
