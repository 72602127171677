import type { Action } from "svelte/action";

enum MouseState {
	Up = "mouse-up",
	Down = "mouse-down",
	Moving = "mouse-moving",
}

export enum ScrollDirection {
	Both = "both",
	XOnly = "x-only",
	YOnly = "y-only",
}

interface Options {
	scrollDirection: ScrollDirection;
	mouseMoveTolerance?: number;
}

const defaultOptions = {
	mouseMoveTolerance: 5,
	scrollDirection: ScrollDirection.Both,
};

type GrabScrollAction = Action<HTMLElement, Partial<Options> | undefined>;

/**
 * grabScroll.
 *
 * A Svelte action which makes a component scrollable by grabbing and moving
 * the mouse
 *
 * An element that has grabScroll applied to it must have overflow: auto; applied
 * via CSS in order for it to be draggable. See .grab-scroller
 */
const grabScroll: GrabScrollAction = function grabScroll(
	node,
	userOptions = {},
) {
	const options = {
		...defaultOptions,
		...userOptions,
	};
	let state = MouseState.Up;
	let grabPosition = {
		top: 0,
		left: 0,
		x: 0,
		y: 0,
	};
	let nodeWidth = node.scrollWidth;
	let rafId: ReturnType<typeof requestAnimationFrame>;

	if (typeof window.MutationObserver !== "undefined") {
		const observer = new MutationObserver(handleNodeMutation);

		observer.observe(node, { subtree: true, childList: true });
	}

	function handleNodeMutation() {
		const newWidth = node.scrollWidth;

		if (newWidth !== nodeWidth) {
			nodeWidth = newWidth;

			setGrabScrollWidth(nodeWidth);
		}
	}

	function setGrabScrollWidth(width: number) {
		node.style.setProperty("--grab-scroll-width", `${width}px`);
	}

	function handleMouseDown(event: MouseEvent) {
		state = MouseState.Down;
		grabPosition = {
			left: node.scrollLeft,
			top: node.scrollTop,
			x: event.clientX,
			y: event.clientY,
		};

		setGrabScrollState(state);
	}

	function handleMouseUp(event: MouseEvent) {
		event.preventDefault();
		event.stopPropagation();

		state = MouseState.Up;

		setGrabScrollState(state);
	}

	function handleMouseMove(event: MouseEvent) {
		const delta = Math.max(
			Math.abs(grabPosition.x - event.clientX),
			Math.abs(grabPosition.y - event.clientY),
		);
		const isDrag = delta > options.mouseMoveTolerance;

		if (state === MouseState.Down) {
			state = MouseState.Moving;
		}

		if ([MouseState.Moving, MouseState.Down].indexOf(state) > -1) {
			const dx = event.clientX - grabPosition.x;
			const dy = event.clientY - grabPosition.y;

			switch (options.scrollDirection) {
				case ScrollDirection.XOnly:
					node.scrollLeft = grabPosition.left - dx;
					break;
				case ScrollDirection.YOnly:
					node.scrollTop = grabPosition.top - dy;
					break;
				default: {
					node.scrollLeft = grabPosition.left - dx;
					node.scrollTop = grabPosition.top - dy;
				}
			}
		}

		if (isDrag) {
			setGrabScrollState(state);
		}
	}

	function setGrabScrollState(mouseState: MouseState) {
		if (rafId) {
			cancelAnimationFrame(rafId);
		}

		rafId = requestAnimationFrame(() => {
			node.setAttribute("data-grab-scroll-state", mouseState);
		});
	}

	setGrabScrollWidth(nodeWidth);
	node.setAttribute("data-grab-scroll-direction", options.scrollDirection);

	node.addEventListener("mousedown", handleMouseDown);
	node.addEventListener("mouseup", handleMouseUp);
	node.addEventListener("mouseleave", handleMouseUp);
	node.addEventListener("mousemove", handleMouseMove);

	return {
		destroy() {
			node.removeEventListener("mousedown", handleMouseDown);
			node.removeEventListener("mouseup", handleMouseUp);
			node.removeEventListener("mouseleave", handleMouseUp);
			node.removeEventListener("mousemove", handleMouseMove);
		},
	};
};

export { grabScroll };
