import {
	MUTATE_EVENT_NAME,
	mutationObserver,
} from "$applib/actions/mutation-observer";
import { renderAppointment } from "./shared/utils";

const SELECTOR = "[data-patient-self-book-appointment-container]";

const selfBookAppointmentModalEl = document.querySelector(
	".js-patient-self-book-appointment-modal",
);

function handleMutation(event: Event) {
	const typedEvent = event as CustomEvent<{ mutations: MutationRecord[] }>;
	const { mutations } = typedEvent.detail;

	if (Array.isArray(mutations)) {
		mutations
			.flatMap(({ addedNodes }) => Array.from(addedNodes))
			.filter(
				(node) => typeof (node as HTMLElement).querySelector !== "undefined",
			)
			.map((node) =>
				(node as HTMLElement).querySelector<HTMLButtonElement>(SELECTOR),
			)
			.filter(Boolean)
			.forEach((element) => renderAppointment(element as HTMLButtonElement));
	}
}

if (selfBookAppointmentModalEl) {
	mutationObserver(selfBookAppointmentModalEl, {
		childList: true,
		subtree: true,
	});

	selfBookAppointmentModalEl.addEventListener(
		MUTATE_EVENT_NAME,
		handleMutation,
	);
}
