<script lang="ts">
  import {Icon, IconId} from '$applib/components/icon';

  let klass = '';
  export {klass as class};
  export let type = 'checkbox';

  const iconFalse =
    type === 'checkbox' ? IconId.CheckboxFalse : IconId.RadioFalse;
  const iconTrue = type === 'checkbox' ? IconId.CheckboxTrue : IconId.RadioTrue;
</script>

<i class="custom-check-radio {klass}">
  <Icon
    class="custom-check-radio__icon--false"
    classInner="custom-check-radio__icon__inner"
    id={iconFalse}
  />

  <Icon
    class="custom-check-radio__icon--true"
    classInner="custom-check-radio__icon__inner"
    id={iconTrue}
  />
</i>
