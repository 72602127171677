import { PatientActions } from "./shared/components/create-appointment-patient-actions";
import type { PatientSelectedEvent } from "./shared/components/create-appointment-patient-actions/patient-list";
import { SelectedPatient } from "./shared/components/selected-patient";

function appointmentFormFactory() {
	const form = document.querySelector<HTMLFormElement>(
		".js-create-appointment-form",
	) as HTMLFormElement;

	const inputs = {
		counterInput: form.querySelector(
			"#appointment-counter-input",
		) as HTMLInputElement,
		dateInput: form.querySelector(
			"#appointment-date-input",
		) as HTMLInputElement,
		staffResourceIdInput: form.querySelector(
			"#appointment-staff-resource-input",
		) as HTMLInputElement,
		patientIdInput: form.querySelector(
			"#appointment-patient-id-input",
		) as HTMLInputElement,
		sendEmailNotificationInput: form.querySelector(
			"#appointment-send-notification-email",
		) as HTMLInputElement,
		serviceIdSelect: form.querySelector(
			"#appointment-service-id-select",
		) as HTMLInputElement,
		submitButton: form.querySelector(
			"#appointment-book-appointment-button-submit",
		) as HTMLButtonElement,
		timeInput: form.querySelector(
			"#appointment-time-input",
		) as HTMLInputElement,
	};
	const elements = {
		inputs,
		form,
		error: form.querySelector(".js-form-error") as HTMLElement,
		success: form.querySelector(".js-form-success") as HTMLElement,
		selectedPatient: document.querySelector(
			".js-create-appointment-selected-patient",
		) as HTMLElement,
	};

	// svelte components
	const components = {
		selectedPatient: new SelectedPatient({ target: elements.selectedPatient }),
		patientActions: new PatientActions({
			target: document.querySelector(
				".js-create-appointment-patient-actions",
			) as HTMLElement,
		}),
	};
	const originalSendEmailValue = inputs.sendEmailNotificationInput
		? inputs.sendEmailNotificationInput.checked
		: false;
	let timerId: ReturnType<typeof setTimeout> | void;

	function _init() {
		form.addEventListener("submit", (event) => event.preventDefault());

		components.patientActions.$on("patientselected", setPatientId);
		components.patientActions.$on("patientselected", hideMessage);
		components.patientActions.$on("patientcreated", setPatientId);
		components.patientActions.$on("patientcreated", revealMessage);
		components.patientActions.$on("patientcreated", revealForm);

		components.patientActions.$on("clickfindpatient", revealForm);
		components.patientActions.$on("clickfindpatient", hideMessage);
		components.patientActions.$on("clickcreatepatient", hideForm);

		components.selectedPatient.$on("patientunselected", reset);
		components.selectedPatient.$on("patientunselected", hideMessage);
	}

	function revealForm() {
		elements.form.style.display = "inherit";
	}

	function revealMessage(_event: PatientSelectedEvent) {
		elements.success.textContent = `
We've sent an email to the patient for them to confirm their email address.
Once they do this, they'll be able to access their patient portal.
`;
	}

	function hideMessage() {
		elements.success.textContent = "";
	}

	function hideForm() {
		elements.form.style.display = "none";
	}

	function setFormError(content: string) {
		elements.error.textContent = content;

		_clearFormError();
	}

	function _clearFormError(duration = 5000) {
		if (timerId) {
			timerId = clearTimeout(timerId);
		}

		timerId = setTimeout(() => {
			setFormError("");
		}, duration);
	}

	function reset() {
		const event = new CustomEvent("foo", {
			detail: { message: null },
		});
		setPatientId(event);

		setFormError("");

		inputs.sendEmailNotificationInput.checked = originalSendEmailValue;
	}

	function setPatientId(event: PatientSelectedEvent) {
		const { patientId } = event.detail;

		inputs.patientIdInput.value = patientId ? `${patientId}` : "";

		components.selectedPatient.$set({ patientId });
	}

	function setSubmitButtonProperties(
		attributes: Record<string, string | number | boolean>,
	): void {
		for (const [key, value] of Object.entries(attributes)) {
			// @ts-ignore
			inputs.submitButton[key] = value;
		}
	}

	_init();

	return {
		components,
		elements,
		reset,
		setFormError,
		setPatientId,
		setSubmitButtonAttributes: setSubmitButtonProperties,
	};
}

type AppointmentForm = ReturnType<typeof appointmentFormFactory>;

export { appointmentFormFactory, type AppointmentForm };
