<script lang="ts">
  import {createEventDispatcher} from 'svelte';
  import {fade} from 'svelte/transition';

  import {
    HorizontalTabsContext,
    HorizontalTabContent,
    HorizontalTabItem,
  } from '$applib/components/tabs-horizontal';

  import {PatientSearch} from './patient-search';
  import {CreatePatientForm} from './create-patient';

  enum Tab {
    Search = 'search',
    Create = 'create',
  }

  export let appointmentDate: Date = new Date();
  export let appointmentTime = '00:00';
  export let staffMemberId: string | number = 0;

  const dispatch = createEventDispatcher();

  function dispatchFactory(eventName: string) {
    return () => dispatch(eventName);
  }
</script>

<HorizontalTabsContext let:goToTab>
  <svelte:fragment slot="navigation">
    <HorizontalTabItem
      id={Tab.Search}
      on:click={dispatchFactory('clickfindpatient')}
    >
      Select a patient
    </HorizontalTabItem>
    <HorizontalTabItem
      id={Tab.Create}
      on:click={dispatchFactory('clickcreatepatient')}
    >
      Create a patient
    </HorizontalTabItem>
  </svelte:fragment>

  <div class="u-padding--block-start">
    <HorizontalTabContent id={Tab.Search}>
      <div in:fade>
        <PatientSearch
          on:patientselected
          {appointmentDate}
          {appointmentTime}
          {staffMemberId}
        />
      </div>
    </HorizontalTabContent>

    <HorizontalTabContent id={Tab.Create}>
      <p in:fade>
        <CreatePatientForm
          on:patientcreated
          on:patientcreated={() => goToTab(Tab.Search)}
        />
      </p>
    </HorizontalTabContent>
  </div>
</HorizontalTabsContext>
