import type { EnvConfig } from "$applib/types/config";

const config: EnvConfig = {
	mode:
		import.meta.env.MODE === "production"
			? import.meta.env.MODE
			: "development",
	sentry: {
		dsn: import.meta.env.VITE_SENTRY_DSN || "",
		environment: import.meta.env.VITE_SENTRY_ENVIRONMENT || "",
	},
};

export { config };
