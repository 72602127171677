<!-- TODO: LB - rewrite using htmx -->
<script lang="ts">
  import {useMachine} from '@xstate/svelte';
  import {createEventDispatcher} from 'svelte';

  import {Loader} from '$applib/components/loader';
  import {AlertType} from '$applib/components/alert';

  import {patientDiscussionMachine} from './machine';
  import {EventType} from '$applib/utils/resources/create-resource-machine';

  import {ReportNeeded} from '$applib/types/resources/patient-discussions';
  import {DiscussionType} from './enums';
  import {getDiscussionType} from './utils';
  import {NOTIFY_EVENT_NAME} from '../../shared/events';

  import type {PatientDiscussion} from '$applib/types/resources/patient-discussions';
  import type {PatientPhotos} from '$applib/types/resources/patient-photos';
  import type {
    DiscussionMachineContext,
    DiscussionStateMachine,
  } from './machine';

  import type {NotifyEvent} from '../../shared/events';

  export let patientPhotosId: PatientPhotos['id'];
  export let discussionId: PatientDiscussion['id'] | undefined;
  export let discussionType: DiscussionType | undefined;

  const {snapshot: state, send} = useMachine<DiscussionStateMachine>(
    patientDiscussionMachine,
    {input: {patientPhotosId, discussionId}},
  );

  const dispatch = createEventDispatcher();

  $: context = $state.context;

  $: if ($state.hasTag('success')) {
    handleSuccess(context);
  }
  $: if ($state.hasTag('error')) {
    handleError(context);
  }

  function handleClick() {
    const event =
      discussionType === DiscussionType.NoDiscussion
        ? EventType.Post
        : EventType.Patch;
    const payload = {
      discussionId:
        discussionType === DiscussionType.NoDiscussion
          ? undefined
          : discussionId,
      reportNeeded:
        discussionType === DiscussionType.ReportNotRequired
          ? ReportNeeded.Yes
          : ReportNeeded.No,
      patientPhotosId,
    };

    send({type: event, params: payload});
  }

  function handleSuccess(context: DiscussionMachineContext) {
    const discussion = context.item;
    const eventDetail: NotifyEvent['detail'] = {
      alertType: AlertType.Success,
      errors: [],
      message: 'Discussion updated!',
      patientPhotosId,
      timeout: 5000,
    };

    discussionType = getDiscussionType(discussion?.report_needed);

    dispatch(NOTIFY_EVENT_NAME, eventDetail);
  }

  function handleError(context: DiscussionMachineContext) {
    const eventDetail: NotifyEvent['detail'] = {
      alertType: AlertType.Error,
      errors: Object.values(context.error.messages).flat(),
      message: 'There was a problem updating the discussion:',
      patientPhotosId,
    };

    dispatch(NOTIFY_EVENT_NAME, eventDetail);
  }
</script>

<button
  class="btn btn--small"
  class:btn--outlined--danger={discussionType !=
    DiscussionType.ReportNotRequired}
  class:btn--outlined--primary={discussionType ==
    DiscussionType.ReportNotRequired}
  on:click={handleClick}
  disabled={$state.hasTag('requesting')}
>
  {#if $state.hasTag('requesting')}
    <Loader>Updating...</Loader>
  {:else if discussionType === DiscussionType.ReportNotRequired}
    Update to 'report required'
  {:else}
    Update to 'report not required'
  {/if}
</button>
