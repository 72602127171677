<script lang="ts">
  import {FieldMessageState} from './enums';

  export let state: FieldMessageState = FieldMessageState.Default;
  let klass = '';

  export {klass as class};
</script>

<div
  class="field__msg {klass}"
  class:field__msg--error={state === FieldMessageState.Error}
>
  <slot />
</div>
