export interface Settings {
	availableOptionsHeaderText: string;
	enableSearch: boolean;
	limitReached?: () => boolean;
	maxSelectable: number;
	searchPlaceholder: string;
	selectedOptionsHeaderText: string;
}

interface WrapperDiv extends HTMLDivElement {
	search?: HTMLInputElement;
	selectedOptions?: HTMLDivElement;
	availableOptions?: HTMLDivElement;
}

interface WrappedHTMLSelectElement extends HTMLSelectElement {
	wrapper?: WrapperDiv;
}

/**
 * TODO: LB - simplify by rewriting as xstate machine
 */
function enhanceMultiSelect() {
	let limitDisabled = false; // This will prevent to reset the "disabled" because of the limit at every click

	// Helper function to trigger an event on an element
	function fireEvent(type: Event["type"], element: HTMLElement) {
		const event = new Event(type, { bubbles: false, cancelable: true });

		element.dispatchEvent(event);
	}

	// Toggles the target option on the select
	function toggleOption(
		selectEl: HTMLSelectElement,
		event: Event,
		settings: Settings,
	) {
		const button = event.target as HTMLButtonElement;
		const itemId = button.getAttribute("data-multi-select-value");
		const toggleOption = Array.from(selectEl.options).find(
			(x) => x.value === itemId,
		);

		if (!toggleOption || toggleOption.disabled) {
			return;
		}

		toggleOption.selected = !toggleOption.selected;

		// Check if there is a limit and if it is reached
		const { maxSelectable } = settings;
		const numSelected = selectEl.selectedOptions.length;

		// Reached the limit
		if (numSelected >= maxSelectable) {
			limitDisabled = true;

			// Trigger the function (if there is)
			if (typeof settings.limitReached === "function") {
				settings.limitReached();
			}

			// Disable all non-selected options
			Array.from(selectEl.options).map((option) => {
				if (!option.selected) {
					option.setAttribute("disabled", "true");
				}
			});
		} else if (limitDisabled) {
			// Enable options (only if they weren't disabled on init)
			Array.from(selectEl.options).map((option) => {
				if (
					option.getAttribute("data-multi-select-origin-disabled") === "false"
				) {
					option.removeAttribute("disabled");
				}
			});

			limitDisabled = false;
		}

		fireEvent("change", selectEl);
	}

	// Refreshes an already constructed instance
	function refreshSelect(select: WrappedHTMLSelectElement, settings: Settings) {
		// Clear columns
		if (select.wrapper?.selectedOptions) {
			select.wrapper.selectedOptions.innerHTML = "";
		}
		if (select.wrapper?.availableOptions) {
			select.wrapper.availableOptions.innerHTML = "";
		}

		// Add headers to columns
		if (
			settings.availableOptionsHeaderText &&
			settings.selectedOptionsHeaderText
		) {
			const nonSelectedHeader = document.createElement("div");
			const selectedHeader = document.createElement("div");

			nonSelectedHeader.className = "enhanced-multi-select__header";
			selectedHeader.className = "enhanced-multi-select__header";

			nonSelectedHeader.innerText = settings.availableOptionsHeaderText;
			selectedHeader.innerText = settings.selectedOptionsHeaderText;

			if (select.wrapper?.availableOptions) {
				select.wrapper.availableOptions.appendChild(nonSelectedHeader);
			}
			if (select.wrapper?.selectedOptions) {
				select.wrapper.selectedOptions.appendChild(selectedHeader);
			}
		}

		// Get search value
		const query =
			select.wrapper && select.wrapper.search
				? select.wrapper.search.value
				: null;

		// Current group
		let itemGroup: HTMLDivElement | null = null;
		let currentOptionGroup: ParentNode | null = null;

		// Loop over select options and add to the non-selected and selected columns
		Array.from(select.options).map((option, index) => {
			const value = option.value;
			const label = option.textContent || option.innerText;
			const button = document.createElement("button");

			button.classList.add("enhanced-multi-select__item");
			button.tabIndex = 0;
			button.innerText = label;

			button.setAttribute("role", "button");
			button.setAttribute("data-multi-select-value", value);
			button.setAttribute("data-multi-select-index", `${index}`);

			if (option.disabled) {
				button.setAttribute("disabled", "true");
			}

			// Add button to selected column if option selected
			if (option.selected) {
				button.setAttribute("data-multi-select-item-state", "selected");
				const clone = button.cloneNode(true);

				if (select.wrapper?.selectedOptions) {
					select.wrapper.selectedOptions.appendChild(clone);
				}
			}

			// Create group if entering a new optgroup
			if (
				option.parentNode &&
				option.parentNode.nodeName == "OPTGROUP" &&
				option.parentNode != currentOptionGroup
			) {
				const parentNode = option.parentNode as HTMLOptGroupElement;
				itemGroup = document.createElement("div");
				currentOptionGroup = option.parentNode;

				itemGroup.className = "enhanced-multi-select__item-group";

				if (parentNode.label) {
					const groupLabel = document.createElement("span");

					groupLabel.innerHTML = parentNode.label;
					groupLabel.className = "enhanced-multi-select__item-group__label";

					if (itemGroup) {
						itemGroup.appendChild(groupLabel);
					}
				}

				if (select.wrapper?.availableOptions) {
					select.wrapper.availableOptions.appendChild(itemGroup);
				}
			}

			// Clear group if not inside optgroup
			if (option.parentNode == select) {
				itemGroup = null;
				currentOptionGroup = null;
			}

			// Apply search filtering
			if (
				!query ||
				(query && label.toLowerCase().indexOf(query.toLowerCase()) > -1)
			) {
				// Append to group if one exists, else just append to wrapper
				if (itemGroup !== null) {
					itemGroup.appendChild(button);
				} else if (select.wrapper?.availableOptions) {
					select.wrapper.availableOptions.appendChild(button);
				}
			}
		});
	}

	// Initializes and constructs an instance
	function init(
		selectEl: HTMLSelectElement,
		userSettings: Partial<Settings> = {},
	) {
		// Check if already initialized
		if (selectEl.hasAttribute("data-multi-select-enhanced")) {
			return;
		}

		/**
		 * Set up settings (optional parameter) and its default values
		 *
		 * Default values:
		 * enable_search : true
		 * search_placeholder : "Search..."
		 */
		const defaultSettings: Settings = {
			enableSearch: true,
			maxSelectable: Number.POSITIVE_INFINITY,
			availableOptionsHeaderText: "",
			selectedOptionsHeaderText: "",
			searchPlaceholder: "Search...",
		};
		const settings = { ...defaultSettings, ...userSettings };

		settings.maxSelectable =
			typeof settings.maxSelectable !== "undefined"
				? Number.parseInt(`${settings.maxSelectable}`)
				: -1;

		if (isNaN(settings.maxSelectable)) {
			settings.maxSelectable = Number.POSITIVE_INFINITY;
		}

		// Make sure element is select and multiple is enabled
		if (selectEl.nodeName != "SELECT" || !selectEl.multiple) {
			return;
		}

		// Hide select
		selectEl.style.display = "none";
		selectEl.setAttribute("data-multi-select-enhanced", "true");

		// Start constructing selector
		const wrapper: WrapperDiv = document.createElement("div");
		wrapper.classList.add("enhanced-multi-select");

		// Add search bar
		if (settings.enableSearch) {
			const search = document.createElement("input");

			search.classList.add("enhanced-multi-select__search");

			search.type = "text";
			search.setAttribute("placeholder", settings.searchPlaceholder);

			search.addEventListener("input", () => refreshSelect(selectEl, settings));

			wrapper.appendChild(search);
			wrapper.search = search;
		}

		// Add columns for selected and non-selected
		const availableOptionsColumn = document.createElement("div");
		availableOptionsColumn.classList.add("enhanced-multi-select__column");
		availableOptionsColumn.setAttribute(
			"data-multi-select-column-type",
			"available",
		);

		const selectedColumn = document.createElement("div");
		selectedColumn.classList.add("enhanced-multi-select__column");
		selectedColumn.setAttribute("data-multi-select-column-type", "selected");

		// Add click handler to toggle the selected status
		wrapper.addEventListener("click", (event: MouseEvent) => {
			const target = event.target as HTMLDivElement;

			if (target.getAttribute("data-multi-select-index")) {
				toggleOption(selectEl, event, settings);
			}
		});

		// Add keyboard handler to toggle the selected status
		wrapper.addEventListener("keypress", (event: KeyboardEvent) => {
			const target = event.target as HTMLDivElement;
			const is_action_key = event.code === "Space" || event.code === "Enter";
			const is_option = target.getAttribute("data-multi-select-index");

			if (is_option && is_action_key) {
				// Prevent the default action to stop scrolling when space is pressed
				event.preventDefault();
				toggleOption(selectEl, event, settings);
			}
		});

		wrapper.appendChild(availableOptionsColumn);
		wrapper.appendChild(selectedColumn);

		wrapper.availableOptions = availableOptionsColumn;
		wrapper.selectedOptions = selectedColumn;

		const wrappedSelect: WrappedHTMLSelectElement = selectEl;

		wrappedSelect.wrapper = wrapper;

		// Add wrapper after select element
		if (wrappedSelect.parentNode) {
			wrappedSelect.parentNode.insertBefore(wrapper, wrappedSelect.nextSibling);
		}

		// Save current state
		Array.from(wrappedSelect.options).map((option) =>
			option.setAttribute(
				"data-multi-select-origin-disabled",
				`${option.disabled}`,
			),
		);

		// Initialize selector with values from wrappedSelect element
		refreshSelect(wrappedSelect, settings);

		// Refresh selector when wrappedSelect values change
		wrappedSelect.addEventListener("change", () =>
			refreshSelect(wrappedSelect, settings),
		);
	}

	return { init };
}

export { enhanceMultiSelect };
